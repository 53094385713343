import React, { Suspense, useEffect } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import LoadingPage from '../modules/consulta/components/LoadingPage';
import Logout from './../modules/consulta/pages/auth/Logout';
import { ProtectedRoute } from '../shared/components';

const Home = React.lazy(() => import('./../modules/consulta/pages/home/Home'));
const Login = React.lazy(() => import('./../modules/consulta/pages/auth/Login'));
const DadosAcervo = React.lazy(() => import('./../modules/consulta/pages/home/DadosAcervo'));
const PearsonAuthPage = React.lazy(() => import('./../modules/consulta/pages/auth/PearsonAuthPage'));
const DadosAutoridade = React.lazy(() => import('./../modules/consulta/pages/home/DadosAutoridade'));
const Cesta = React.lazy(() => import('./../modules/consulta/pages/home/Cesta'));
const ErrorPage = React.lazy(() => import('./../modules/consulta/components/ErrorPage'));
const MeuPergamum = React.lazy(() => import('../modules/meupergamum'));
const PergamumRedireciona = React.lazy(() => import('./../modules/consulta/pages/auth/PergamumRedireciona'));
const MSAuth = React.lazy(() => import('./../modules/consulta/pages/auth/MSAuth'));
const GrupoA = React.lazy(() => import('./../modules/consulta/pages/auth/GrupoA'));
const AppLayers = React.lazy(() => import('./../modules/meupergamum/pages/AppLayers'));

const Routers = () => (
    <Suspense fallback={<LoadingPage />}>
        <BrowserRouter basename={process.env.REACT_APP_PREFIX}>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/login" render={props => <Login {...props} />} />
                <Route path="/sair" render={props => <Logout {...props} />} />
                <Route path="/pergamum_redireciona" render={props => <PergamumRedireciona {...props} />} />
                <Route path="/oauth-response" render={props => <MSAuth {...props} />} />
                <Route path="/grupoa" render={props => <GrupoA {...props} />} />
                {/* Rota para fazer a autenticação para ebooks da pearson */}
                <Route
                    path="/pearson"
                    exact
                    render={props => <PearsonAuthPage {...props} />}
                />
                {/* Cesta */}
                <Route
                    path="/cesta"
                    exact
                    render={props => <Cesta {...props} />}
                />
                {/* Módulo Meu Pergamum */}
                <Route path="/meupergamum/app" render={props => <AppLayers {...props} />} />
                <ProtectedRoute path="/meupergamum" component={MeuPergamum} />
                {/* Alterar rotas conforme navega no menu:  */}
                <Route
                    path="/:guia"
                    exact
                    render={props => <Home {...props} />}
                />
                {/* Dados do acervo: */}
                <Route
                    path="/acervo/:codAcervo"
                    exact
                    render={props => <DadosAcervo extras={false} {...props} />}
                />
                <Route
                    path="/acervo/:codAcervo/referencia"
                    exact
                    render={props => (
                        <DadosAcervo
                            extras={false}
                            linkCurrent={'33'}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/acervo/:codAcervo/marc"
                    exact
                    render={props => (
                        <DadosAcervo
                            extras={false}
                            linkCurrent={'36'}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/acervo/:codAcervo/exemplares"
                    exact
                    render={props => (
                        <DadosAcervo
                            extras={false}
                            linkCurrent={'30'}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/acervo/:codAcervo/dublin-core"
                    exact
                    render={props => (
                        <DadosAcervo
                            extras={false}
                            linkCurrent={'115'}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/acervo/:codAcervo/reserva"
                    exact
                    render={props => (
                        <DadosAcervo
                            extras={false}
                            linkCurrent={'18'}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/acervo/:codAcervo/solicitacao-emprestimo"
                    exact
                    render={props => (
                        <DadosAcervo
                            extras={false}
                            linkCurrent={'75'}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/acervo/:codAcervo/colecao-kardex"
                    exact
                    render={props => (
                        <DadosAcervo
                            extras={false}
                            linkCurrent={'32'}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/acervo/:codAcervo/sumarios"
                    exact
                    render={props => (
                        <DadosAcervo
                            extras={false}
                            linkCurrent={'summary'}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/acervo/:codAcervo/sumarios-livros"
                    exact
                    render={props => (
                        <DadosAcervo
                            extras={false}
                            linkCurrent={'sumario_livro'}
                            {...props}
                        />
                    )}
                />
                <Route
                    path="/acervo/:codAcervo/dados-estatisticos"
                    exact
                    render={props => (
                        <DadosAcervo
                            extras={false}
                            linkCurrent={'74'}
                            {...props}
                        />
                    )}
                />
                {/* Dados da Autoridade */}
                <Route
                    path="/autoridade/:codAutoridade"
                    exact
                    render={props => (
                        <DadosAutoridade linkCurrent={'def'} {...props} />
                    )}
                />
                <Route
                    path="/autoridade/:codAutoridade/marc"
                    exact
                    render={props => (
                        <DadosAutoridade linkCurrent={'marc'} {...props} />
                    )}
                />
                <Route
                    path="/acervo/:codAcervo/acervo-fechado"
                    exact
                    render={props => (
                        <DadosAcervo
                            extras={false}
                            linkCurrent={'acervo_fechado'}
                            {...props}
                        />
                    )}
                />
                <Route path="*" render={props => <ErrorPage {...props} />} />
            </Switch>
        </BrowserRouter>
    </Suspense>
);

export default Routers;
