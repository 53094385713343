/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import Scrollbars from 'react-custom-scrollbars';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Alert,
    Button,
    Col,
    CustomInput,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
import { Store } from '../../../../store';
import { AuthAPI, PessoaAPI } from '../../../../shared/http-requests';
import {
    AcessibillityBar,
    ModalConstructor,
    SpinnerConstructor,
    Footer,
} from '../../../../shared/components';
import ForgotPassword from './ForgotPassword';
import { AuthPersist } from '../../../../store/ducks/authPersist';
import { AuthSession } from '../../../../store/ducks/auth';
import MiddlewareLogin from '../../../../routers/MiddlewareLogin';
import { Permissao, APIHelper } from '../../../../shared/helpers';
import * as CodEmpresaConst from '../../../../shared/const/CodEmpresaConst';

const modeLogin = 'LOGIN';
const modeForgotPassword = 'FORGOT_PASSWORD';

const Login = props => {
    const {
        embedded,
        modal,
        openModalRender,
        altRender,
        loginCallback,
        history,
        credentials,
        acessibilidadeRx,
        instituicao,
        labelAcessarAlternativo,
    } = props;

    const redirectUrl =
        queryString.parseUrl(window.location.href).query.redirect || '/';
    const flag = new URLSearchParams(window.location.search).get('redirect');
    const bases = [
        'pearson',
        'cengage',
        'editora_forum',
        'minhabiblioteca',
        'ebrary',
        'rtonline',
        'lectio',
        'gale',
        'gale_book',
        'oxford',
        'proquest',
        'uptodate',
        'lexicomp',
        'solucto',
        'jornalderesenhas'
    ];
    const { t } = useTranslation();
    const [loginData, setLoginData] = useState({
        username: '',
        password: '',
        recaptchaToken: false,
        rememberMe: false,
        pwVisibility: false,
        destinoLogin: instituicao.destino_padrao_login_catalogo || '',
    });
    const [error, setError] = useState(sessionStorage.getItem("oidc_msg_erro") != null ? t(sessionStorage.getItem("oidc_msg_erro")) : false);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [formMode, setFormMode] = useState(modeLogin);
    const [isMsgLoginCollapsed, setMsgLoginCollapsed] = useState(false);
    const [chooseLogin, setChooseLogin] = useState(false);
    const [loginsPessoa, setLoginsPessoa] = useState([]);
    const [usaOIDC, setUsaOIDC] = useState(instituicao.hasOwnProperty("usaOIDC") ? instituicao.usaOIDC : false);
    const [bloquearCampoUsuario, setBloquearCampoUsuario] = useState(false);
	const [mostrarCamposSenhaEComplementos, setMostrarCamposSenhaEComplementos] = useState(
        (instituicao.codEmpresa != CodEmpresaConst.SAO_CAMILO) &&
        (instituicao.hasOwnProperty("usaOIDC") ? !instituicao.usaOIDC : true)
    );
    const [pessoasDuplicadas, setPessoasDuplicadas] = useState([]);
    const [codPessoaDuplicadaSelecionada, setCodPessoaDuplicadaSelecionada] = useState(false);
    const [categoria, setCategoria] = useState("01");
    const recaptchaInstance = useRef();
    const usaRecaptcha = Permissao.tem(1120);
    const recaptchaId = Math.floor(Math.random() * 100);
    const obrigaLoginModal = !Permissao.tem(107); // A permissão desabilita obrigatoriedade de login da modal se já estiver logado
    const possuiMeuPergamum = Permissao.tem(8);
    const permissaoMeuPergamum = Permissao.busca(8);
    const possuiSelecaoDestino = !embedded && !modal && possuiMeuPergamum; // Se não estiver embutido, não for modal e possuir meu pergamum, pergunta qual o destino do login

    sessionStorage.removeItem("oidc_msg_erro"); //Remove pois se existia já foi setado no state de erro

    const getRedirectUrlLogin = () => {
        //Se não possui campo para selecionar destino, redireciona para o redirect normal
        if(!possuiSelecaoDestino)
            return redirectUrl;

        //Redirecionar para Meu Pergamum
        if(loginData.destinoLogin == "M")
            return "/meupergamum";

        //Aqui o redirecionamento é para o catálogo
        let destino = redirectUrl == "/meupergamum" ? "/" : redirectUrl;
        return destino;
    }

    const doLogin = () => {
        if (!loginData.username.trim().length) {
            setError(t(`credential_missing`));
            setLoginData(ld => ({ ...ld, recaptchaToken: false }));
            recaptchaInstance.current.reset();
            return;
        }

        if(mostrarCamposSenhaEComplementos){
            if(instituicao.codEmpresa == CodEmpresaConst.SAO_CAMILO &&
                pessoasDuplicadas &&
                pessoasDuplicadas.length > 0 &&
                !codPessoaDuplicadaSelecionada){
                setError(t('PreenchaCampo', { campo: t("EscolhaLogin") }));
                setLoginData(ld => ({ ...ld, recaptchaToken: false }));
                recaptchaInstance.current.reset();
                return;
            }

            if (!loginData.password.trim().length) {
                setError(t(`credential_missing`));
                setLoginData(ld => ({ ...ld, recaptchaToken: false }));
                recaptchaInstance.current.reset();
                return;
            }

            if (
                !loginData.recaptchaToken &&
                usaRecaptcha &&
                process.env.MIX_APP_RECAPTCHA_SITEKEY
            ) {
                setError(t('fill_captcha'));
                return;
            }
        }

        if(possuiSelecaoDestino){
            if (!loginData.destinoLogin.trim().length) {
                setError(t('PreenchaCampo', { campo: t("DirecionarPara") }));
                setLoginData(ld => ({ ...ld, recaptchaToken: false }));
                recaptchaInstance.current.reset();
                return;
            }
        }

        //Se usa OIDC e não tem @, segue com fluxo de login normal
        if(usaOIDC && !loginData.username.includes("@")){
            setUsaOIDC(false);
            setBloquearCampoUsuario(true);
            setMostrarCamposSenhaEComplementos(true);
            return;
        }

        setLoading(true);
        setError(false);

        //Se é São Camilo e não mostrarCamposSenhaEComplementos, precisa validar quantas pessoas existem para decidir se vai mostrar combo de usuário ou não
        if(instituicao.codEmpresa == CodEmpresaConst.SAO_CAMILO && !mostrarCamposSenhaEComplementos){
            setCodPessoaDuplicadaSelecionada(false);

            PessoaAPI.buscarPessoasDuplicadas(loginData.username)
                .then(response => {
                    setLoading(false);

                    let retorno = response.data;

                    //Possui duplicidade
                    if(retorno.result){
                        setPessoasDuplicadas(retorno.pessoas);
                    } else {
                        setPessoasDuplicadas([]);
                    }

                    setBloquearCampoUsuario(true);
                    setMostrarCamposSenhaEComplementos(true);
                }).catch(err => APIHelper.tratarErro(err));

            return;
        }

        //Chama api de login OIDC
        if(usaOIDC){
            let redirectOIDC = getRedirectUrlLogin();

            //Se não for a página de login, então direciona para o path da página atual após login
            if(embedded || modal)
                redirectOIDC = window.location.pathname + window.location.search;

            AuthAPI.gerarAutenticacaoOIDC(
                loginData.username,
                redirectOIDC
            ).then(response => {
                setError(false);

                localStorage.setItem("oidc_sessao_nonce", response.data.nonce)
                window.open(response.data.link, '_self');
            }).catch(err => tratarErroApiLogin(err));

            return;
        }

        let username = loginData.username;
        //Se é São Camilo e tem pessoa selecionada, mudar username
        if(instituicao.codEmpresa == CodEmpresaConst.SAO_CAMILO && codPessoaDuplicadaSelecionada)
            username = username+"@PERG@"+codPessoaDuplicadaSelecionada;

        //Unisc envia a categoria
        if(instituicao.codEmpresa == CodEmpresaConst.UNISC)
            username = username+"@PERG@"+categoria;

        // ? Busca o token de login
        AuthAPI.login(
            username,
            loginData.password,
            usaRecaptcha ? loginData.recaptchaToken : false,
        )
        .then(response => {
            setError(false);
            return {
                credentials: {
                    token: response.data.access_token,
                    refresh: response.data.refresh_token,
                },
            };
        })
        .then(authToken => {
            // ? Busca os dados do usuário com o token de login retornado
            return AuthAPI.getUserData(authToken).then(response => {
                if (response.data.bloqueio) {
                    return Promise.reject({ response: { status: 430 } });
                }

                const userData = {
                    ...response.data.user,
                    login: loginData.username.trim(),
                };
                localStorage.setItem('msg_shwnd', 1);
                return {
                    ...authToken,
                    user: userData,
                    authPermissions: response.data.authPermissions,
                };
            });
        })
        .then(finalAuth => {
            // ? Chama o reducer correto (localstorage ou sessionstorage)
            if (loginData.rememberMe) {
                Store.dispatch(AuthPersist.storeData(finalAuth));
            } else {
                Store.dispatch(AuthSession.storeData(finalAuth));
            }
            if (loginCallback) {
                //Limpa o campo de senha antes de acionar o callback para que senha não fique exposta na tela
                setLoginData(ld => ({
                    ...ld,
                    password: '',
                }));

                loginCallback(finalAuth.user);
            }
            setLoading(false);
            // ? Se estiver na tela de login, redireciona
            if (!embedded && !modal) {
                // ? Busca logins da pessoa
                PessoaAPI.buscarLoginsPessoa()
                    .then(response => {
                        if (
                            response.data.length &&
                            response.data.length > 1
                        ) {
                            setLoginsPessoa(response.data);
                            return setChooseLogin(true);
                        }
                        if (bases.includes(flag)) {
                            return PessoaAPI.geraUrlAcessoBase(flag)
                                .then(
                                    response =>
                                        window.open(
                                            response.data.url,
                                            '_blank',
                                        ) && history.push(getRedirectUrlLogin()),
                                )
                                .catch(err => APIHelper.tratarErro(err));
                        }
                        const modoMeuPergamum = Number(
                            process.env.REACT_APP_MEU_PERGAMUM_MODE,
                        );
                        if (modoMeuPergamum === 0) {
                            return PessoaAPI.geraUrlAcessoMeuPergamum()
                                .then(
                                    response =>
                                        window.open(
                                            response.data.url,
                                            '_blank',
                                        ) && history.push(getRedirectUrlLogin()),
                                )
                                .catch(err => APIHelper.tratarErro(err));
                        }
                        history.push(getRedirectUrlLogin());
                    })
                    .catch(err => APIHelper.tratarErro(err));
            }
            if (modal) {
                setModalOpen(false);

                if (obrigaLoginModal) {
                    setLoginData(ld => ({
                        ...ld,
                        username: '',
                        password: '',
                        recaptchaToken: false,
                    }));
                    Store.dispatch(AuthPersist.logout());
                    Store.dispatch(AuthSession.logout());
                }
            }
        })
        .catch(err => tratarErroApiLogin(err));
    };

    const tratarErroApiLogin = err => {
        console.error('err.login', err);

        setLoading(false);
        setLoginData(ld => ({
            ...ld,
            password: '',
            recaptchaToken: false,
        }));
        if (recaptchaInstance.current) {
            recaptchaInstance.current.reset();
        }

        let errorAux = 500;
        if (err.response && err.response.status) {
            if (err.response.status === 422)
                // ? Se for erro 422, a mensagem que vem da API deve exibir no alerta.
                errorAux = err.response.data.message;
            else if(err.response.status === 401){
                errorAux = t("UsuarioOuSenhaIncorretaCustom", { usuario: instituicao.denominacao_login ? instituicao.denominacao_login : t('Usuario') });
            }
            else errorAux = err.response.status;
        } else if (err.response?.data?.message === 'ACESSO_BLOQUEADO') {
            errorAux = 430;
        }

        setError(errorAux);
    }

    const handleChangeLoginForm = e => {
        const { value, name } = e.target;
        const loginDataAux = { ...loginData };
        loginDataAux[name] = value;
        setLoginData(loginDataAux);
    };

    const handleSelectCombo = value => {
        let baseRed = 'login';
        if (bases.includes(flag)) baseRed = flag;
        PessoaAPI.trocarPessoa(value, flag)
            .then(
                response =>
                    history.push(getRedirectUrlLogin()),
            )
            .catch(err => APIHelper.tratarErro(err));
    };

    const recaptchaCallback = args => {
        setLoginData(ld => ({ ...ld, recaptchaToken: args }));
    };

    const LoginForm = (
        <Form onSubmit={(e) => {
            e.preventDefault();
            doLogin();
        }}>
            {!loading && !chooseLogin ? (
                <div>
                    <FormGroup>
                        {embedded && altRender && !modal && (
                            <div>{altRender(!!credentials)}</div>
                        )}
                        {error && (
                            <Alert
                                color={error === 500 ? 'danger' : 'warning'}
                                isOpen={error !== false}
                                toggle={() => setError(false)}
                                className="mt-2"
                            >
                                {Number.isNaN(error)
                                    ? t(error)
                                    : t(` ${error} `)}
                            </Alert>
                        )}
                        {instituicao.msg_login && instituicao.msg_login.length && (
                            <div
                                className={
                                    'alert alert-info mt-2 click' +
                                    (isMsgLoginCollapsed
                                        ? ''
                                        : ' text-truncate')
                                }
                                onClick={() =>
                                    setMsgLoginCollapsed(!isMsgLoginCollapsed)
                                }
                                style={{
                                    maxHeight: 100,
                                    overflowY: 'auto',
                                }}
                            >
                                {instituicao.msg_login}
                            </div>
                        )}

                        {/* Customização UNISC */}
                        {instituicao.codEmpresa == CodEmpresaConst.UNISC &&
                            <FormGroup>
                                <div>
                                    <Label className="f900 s12 preto80 l40">
                                        {t("Categoria")}
                                    </Label>
                                    <div>
                                        <FontAwesomeIcon
                                            icon="bookmark"
                                            className="fa-fw cortxt r5 l5 s20 float-left t5 cortxt"
                                        />
                                        <div>
                                            <Input
                                                type="select"
                                                className="b-tema20 cortxt rad10 s14"
                                                value={categoria}
                                                onChange={e => setCategoria(e.target.value)}
                                                style={{ width: 'calc( 100% - 100px)' }}
                                            >
                                                <option value="01">{t('AlunoGraduacao')+" / "+t("Especiais")}</option>
                                                <option value="19">{t('AlunoGraduacaoDigital')}</option>
                                                <option value="03">{t('AlunoPosGraduacao')+" / "+t("Especiais")}</option>
                                                <option value="06">{t('AlunoEducarse')}</option>
                                                <option value="07">{t('AlunosIdiomasUniamaAgricola')}</option>
                                                <option value="21">{t('CursoTecnicoNivelMedio')}</option>
                                                <option value="09">{t('Doutorado')}</option>
                                                <option value="05">{t('Funcionario')}</option>
                                                <option value="12">{t('FuncionarioHospital')}</option>
                                                <option value="Intercamb">{t('Intercambista')}</option>
                                                <option value="MembroComun">{t('MembroComunidadeVoltare')}</option>
                                                <option value="08">{t('Mestrado')}</option>
                                                <option value="04">{t('Professor')}</option>
                                                <option value="10">{t('UsuarioEspecial')}</option>
                                            </Input>
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                        }
                        {/* .Customização UNISC */}

                        <Label for="username" className="f900 s12 preto80 l40">
                            {instituicao.denominacao_login ? instituicao.denominacao_login : t('Usuario')}
                        </Label>
                        {bloquearCampoUsuario ?
                            <div style={{ display: 'flex' }}>
                                <button
                                    type="button"
                                    style={{width: '25px'}}
                                    className="s16 b-none hover htema bktema branco stema pad5 rad5 d-inline l5 r5"
                                    onClick={() => {
                                        setBloquearCampoUsuario(false);
                                        setMostrarCamposSenhaEComplementos(false);
                                        loginData.password = '';

                                        if(instituicao.hasOwnProperty("usaOIDC")) {
                                            setUsaOIDC(instituicao.usaOIDC);
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon icon={'arrow-left'} />
                                </button>
                                <span className='preto70 s16 f500 ml-2'>{loginData.username}</span>
                            </div>
                            :
                            <div style={{ display: 'flex' }}>
                                <FontAwesomeIcon
                                    style={{width: '25px'}}
                                    icon="user"
                                    className="fa-fw cortxt r5 l5 s20 float-left t5 cortxt"
                                />
                                <Input
                                    name="username"
                                    id="username"
                                    className={embedded && 's13'}
                                    onChange={e => handleChangeLoginForm(e)}
                                    value={loginData.username}
                                    style={{ width: 'calc( 100% - 100px)' }}
                                    disabled={loading}
                                />
                            </div>
                        }
                    </FormGroup>

                    {/* Customização de seleção de pessoa */}
                    {mostrarCamposSenhaEComplementos &&
                        instituicao.codEmpresa == CodEmpresaConst.SAO_CAMILO &&
                        pessoasDuplicadas &&
                        pessoasDuplicadas.length > 0 &&
                        <FormGroup>
                            <Label for="selecaoPessoa" className="f900 s12 preto80 l40">
                                {t('EscolhaLogin')}
                            </Label>
                            <div style={{ display: 'flex' }}>
                                <FontAwesomeIcon
                                    icon="user"
                                    className="fa-fw cortxt r5 l5 s20 float-left t5 cortxt"
                                />
                                <Input
                                    name="selecaoPessoa"
                                    id="selecaoPessoa"
                                    type="select"
                                    className="b-tema20 cortxt rad10 s14"
                                    value={codPessoaDuplicadaSelecionada}
                                    onChange={e => setCodPessoaDuplicadaSelecionada(e.target.value)}
                                    style={{ width: 'calc( 100% - 100px)' }}
                                >
                                    <option disabled value={false}>
                                        {t("SelecioneOpcao")}
                                    </option>
                                    {pessoasDuplicadas.map(p =>
                                        <option
                                            key={`pessoa-selecao-${p.codigo}`}
                                            value={p.codigo}
                                        >
                                            {p.nome}
                                        </option>
                                    )}
                                </Input>
                            </div>
                        </FormGroup>
                    }

                    {mostrarCamposSenhaEComplementos &&
                        <FormGroup>
                            <Label for="password" className="f900 s12 preto80 l40">
                                {t('Senha')}
                            </Label>
                            <div style={{ display: 'flex' }}>
                                <FontAwesomeIcon
                                    icon="unlock-alt"
                                    className="fa-fw cortxt r5 l5 s20 float-left t5 cortxt"
                                />
                                <Input
                                    name="password"
                                    id="password"
                                    className={embedded && 's13'}
                                    type={
                                        loginData.pwVisibility ? 'text' : 'password'
                                    }
                                    onChange={e => handleChangeLoginForm(e)}
                                    value={loginData.password}
                                    style={{ width: 'calc( 100% - 100px)' }}
                                    disabled={loading}
                                />
                                <button
                                    type="button"
                                    className="s16 b-none hover htema bktema branco stema pad5 rad5 d-inline ml-2"
                                    onClick={() =>
                                        setLoginData(ld => ({
                                            ...ld,
                                            pwVisibility: !loginData.pwVisibility,
                                        }))
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={
                                            loginData.pwVisibility
                                                ? 'eye'
                                                : 'eye-slash'
                                        }
                                    />
                                </button>
                            </div>
                        </FormGroup>
                    }

                    {/* Se não estiver embutido, não for modal e possuir meu pergamum, pergunta qual o destino do login */}
                    {possuiSelecaoDestino &&
                        <FormGroup>
                            <div>
                                <Label className="f900 s12 preto80 l40">
                                    {t("DirecionarPara")}
                                </Label>
                                <div>
                                    <FontAwesomeIcon
                                        icon="map-signs"
                                        className="fa-fw cortxt r5 l5 s20 float-left t5 cortxt"
                                    />
                                    <div>
                                        <Input
                                            type="select"
                                            className="b-tema20 cortxt rad10 s14"
                                            value={loginData.destinoLogin}
                                            onChange={e => setLoginData(ld => ({
                                                ...ld,
                                                destinoLogin: e.target.value,
                                            }))}
                                            style={{ width: 'calc( 100% - 100px)' }}
                                        >
                                            <option disabled value="">
                                                {t("SelecioneOpcao")}
                                            </option>
                                            <option value="M">
                                                {permissaoMeuPergamum.descricao}
                                            </option>
                                            <option key="destino-c" value="C">
                                                {t("CatalogoOnline")}
                                            </option>
                                        </Input>
                                    </div>
                                </div>
                            </div>
                        </FormGroup>
                    }

                    {/* {mostrarCamposSenhaEComplementos &&
                        <FormGroup>
                            <CustomInput
                                type="switch"
                                label={t('ManterConectado')}
                                className="s13 f500 p-0 text-center"
                                id={`rememberMe-${recaptchaId}`}
                                checked={loginData.rememberMe}
                                onChange={() =>
                                    setLoginData(ld => ({
                                        ...ld,
                                        rememberMe: !loginData.rememberMe,
                                    }))
                                }
                            />
                        </FormGroup>
                    } */}

                    {usaRecaptcha && process.env.MIX_APP_RECAPTCHA_SITEKEY && mostrarCamposSenhaEComplementos && (
                        <div>
                            <FormGroup>
                                <div
                                    style={{
                                        width: 'calc( 100% - 100px)',
                                        display: 'table',
                                        margin: 'auto'
                                    }}
                                >
                                    <ReCAPTCHA
                                        id={`recaptcha__${recaptchaId}`}
                                        ref={recaptchaInstance}
                                        size={
                                            window.matchMedia(
                                                '(max-width: 575.98px)',
                                            ).matches
                                                ? 'compact'
                                                : 'normal'
                                        }
                                        sitekey={
                                            process.env
                                                .MIX_APP_RECAPTCHA_SITEKEY
                                        }
                                        onChange={recaptchaCallback}
                                        className='pegaCaptcha'
                                    />
                                </div>
                            </FormGroup>
                        </div>
                    )}

                </div>
            ) : !chooseLogin ? (
                <div className="text-center animated fadeIn p-3">
                    <SpinnerConstructor
                        visible
                        width={60}
                        height={60}
                        className="s20 cortxt70"
                    />
                    <div className="t20">
                        <span className="preto70 f300 s20">
                            {t('Autenticando')}...
                        </span>
                    </div>
                </div>
            ) : (
                <div>
                    <FormGroup>
                        <div>
                            <Input
                                type="select"
                                name="login_user"
                                className="b-tema20 rad10 s14 cortxt"
                                value="-1"
                                onChange={e =>
                                    handleSelectCombo(e.target.value)
                                }
                            >
                                <option value="-1" disabled>
                                    {t('EscolhaLogin')}
                                </option>
                                {loginsPessoa.map(b => (
                                    <option
                                        key={`bib-orig-${b.codigo}`}
                                        className="preto70"
                                        value={b.codigo}
                                    >
                                        {b.nome}
                                    </option>
                                ))}
                            </Input>
                        </div>
                    </FormGroup>
                </div>
            )}
            <Row className="b10 t5 b-w1 b-dotted-top b-cinza">
                <Col md="3" xs="2" />
                <Col md="6" xs="12" className="text-center">
                    <Button
                        onClick={() => doLogin()}
                        className="bkverde branco b-none sverde hover hpreto pad5 rad5 t10 r10 f770 s13"
                        disabled={loading || chooseLogin}
                    >
                        <FontAwesomeIcon icon="sign-in-alt" className="r5" />
                        {mostrarCamposSenhaEComplementos ? (
                            labelAcessarAlternativo ? labelAcessarAlternativo : t('Acessar')
                        ) : (
                            t('Avancar')
                        )}
                    </Button>
                    {!embedded && !modal ? (
                        <Button
                            className="bkazul branco b-none sazul hover hpreto pad5 rad5 t10 l20 f770 s13"
                            onClick={() => history.push(redirectUrl === "/meupergamum" ? "/" : redirectUrl)} >
                            <FontAwesomeIcon
                                icon="chevron-left"
                                className="r5"
                            />
                            {t('Voltar')}
                        </Button>
                    ) : null}
                </Col>
                <Col md="3" xs="2" />
            </Row>
        </Form>
    );

    const getContent = () => {
        switch (formMode) {
            case modeLogin:
                return LoginForm;
            case modeForgotPassword:
                return (
                    <ForgotPassword
                        changeModeCallback={() => setFormMode(modeLogin)}
                        embedded={embedded}
                        usaRecaptcha={usaRecaptcha}
                    />
                );
            default:
                return null;
        }
    };

    if (embedded) {
        return credentials ? (
            altRender(true)
        ) : (
            <div>
                <Row>
                    <Col md="12" xs="12">
                        {getContent()}
                    </Col>
                    {instituicao &&
                        instituicao.p.__md_aut === '2' &&
                        instituicao.p.__md_crypt === '4' && (
                            <Col
                                md="12"
                                xs="12"
                                className="text-center pt-2 pb-2"
                            >
                                {formMode === modeLogin && (
                                    <span
                                        className="pad5 s13 rad5 hover hpreto f500 link preto60 bkbranco click"
                                        onClick={() =>
                                            setFormMode(modeForgotPassword)
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon="question-circle"
                                            className="r5"
                                        />
                                        {t('EsqueciSenha')}
                                    </span>
                                )}
                                {formMode === modeForgotPassword && (
                                    <span
                                        className="pad5 s13 rad5 hover hpreto f500 link preto60 bkbranco click"
                                        onClick={() => setFormMode(modeLogin)}
                                    >
                                        <FontAwesomeIcon
                                            icon="angle-left"
                                            className="r5"
                                        />
                                        {t('Voltar')}
                                    </span>
                                )}
                            </Col>
                        )}
                </Row>
            </div>
        );
    }
    if (modal) {
        return (
            <div>
                <ModalConstructor
                    closeable
                    open={modalOpen}
                    title={t('Acesso')}
                    size="xs"
                    setOpen={setModalOpen}
                >
                    <Row>
                        <Col md="12" xs="12">
                            {getContent()}
                        </Col>
                        {instituicao &&
                            instituicao.p.__md_aut === '2' &&
                            instituicao.p.__md_crypt === '4' && (
                                <Col
                                    md="12"
                                    xs="12"
                                    className="text-center pt-2 pb-2"
                                >
                                    {formMode === modeLogin && (
                                        <span
                                            className="pad5 s13 rad5 hover hpreto f500 link preto60 bkbranco click"
                                            onClick={() =>
                                                setFormMode(modeForgotPassword)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon="question-circle"
                                                className="r5"
                                            />
                                            {t('EsqueciSenha')}
                                        </span>
                                    )}
                                    {formMode === modeForgotPassword && (
                                        <span
                                            className="pad5 s13 rad5 hover hpreto f500 link preto60 bkbranco click"
                                            onClick={() =>
                                                setFormMode(modeLogin)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon="angle-left"
                                                className="r5"
                                            />
                                            {t('Voltar')}
                                        </span>
                                    )}
                                </Col>
                            )}
                    </Row>
                </ModalConstructor>
                {altRender(!!credentials && !obrigaLoginModal)}
                {(!credentials || obrigaLoginModal) &&
                    (!openModalRender ? (
                        <button
                            type="button"
                            className="pad10 rad5 sombra b-tema b-w1 bkzero cortxt s15 hover hpreto"
                            onClick={() => setModalOpen(!modalOpen)}
                        >
                            <FontAwesomeIcon
                                icon="user-circle"
                                className="r5"
                            />
                            {t('RealizarLogin')}
                        </button>
                    ) : (
                        <div
                            onClick={() => setModalOpen(!modalOpen)}
                            className="d-inline-block"
                        >
                            {openModalRender()}
                        </div>
                    ))}
            </div>
        );
    }
    return (
        <Scrollbars autoHeight universal autoHeightMin="100vh">
            <div
                className={[
                    'app acessibilidade',
                    acessibilidadeRx.cor === 'contraste'
                        ? 'alto-contraste'
                        : instituicao.tema,
                    acessibilidadeRx.zoom
                        ? `zoom-${acessibilidadeRx.zoom}`
                        : null,
                    acessibilidadeRx.negrito ? 'negrito' : null,
                    acessibilidadeRx.cor,
                ].join(' ')}
            >
                {!embedded && !modal ? <AcessibillityBar showUserMenu /> : null}
                <div
                    className="imgMeuPergamum"
                    style={{
                        backgroundImage: `url(/imagens/background)`,
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                    }}
                >
                    <Row className="b-w1 b-solid-bottom b-branco">
                        <Col
                            lg="7"
                            md="7"
                            xs="12"
                            className="pad0 d-none d-md-block h-MeuPergamum"
                        >
                            <Row className="b-w2 b-solid-top b-tema t100">
                                <Col
                                    md="5"
                                    className="text-right bkbranco pad10"
                                >
                                    <Link to="/">
                                        <img
                                            src="/imagens/logo"
                                            style={{ maxHeight: 100 }}
                                            alt="Logotipo"
                                        />
                                    </Link>
                                </Col>
                                <Col
                                    md="7"
                                    className="s30 f100 branco bkdark80 pad20"
                                >
                                    <div className="s13 f900">
                                        {t('SejaBemVindo')}
                                    </div>
                                    {t('CatalogoOnline')}
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            lg="5"
                            md="5"
                            xs="12"
                            className=" bkdark70_0 pad20"
                        >
                            <div
                                className="bktema pad10 rad5 branco stema s16 f700 bkmontanha"
                                style={{
                                    margin: '0px 30px -15px 30px',
                                    position: 'relative',
                                }}
                            >
                                <FontAwesomeIcon
                                    icon="sign-in-alt"
                                    className="l10 r10"
                                />
                                {t('Acesso')}
                            </div>
                            <div className="bkbranco sombra rad10 pad20">
                                {getContent()}
                            </div>
                            <Row className="s14 t20">
                                {instituicao &&
                                    instituicao.p.__md_aut === '2' &&
                                    instituicao.p.__md_crypt === '4' && (
                                        <Col
                                            md="12"
                                            xs="12"
                                            className="text-center"
                                        >
                                            {formMode === modeLogin && (
                                                <span
                                                    className="pad5 rad5 hover hazul f500 link branco bkdark50 click"
                                                    onClick={() =>
                                                        setFormMode(
                                                            modeForgotPassword,
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon="question-circle"
                                                        className="r5"
                                                    />
                                                    {t('EsqueciSenha')}
                                                </span>
                                            )}
                                            {formMode ===
                                                modeForgotPassword && (
                                                <span
                                                    className="pad5 rad5 hover hazul f500 link branco bkdark50 click"
                                                    onClick={() =>
                                                        setFormMode(modeLogin)
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon="angle-left"
                                                        className="r5"
                                                    />
                                                    {t('Voltar')}
                                                </span>
                                            )}
                                        </Col>
                                    )}
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Footer />
                <ToastContainer
                    autoClose={8000}
                    position={toast.POSITION.TOP_CENTER}
                />
            </div>
        </Scrollbars>
    );
};

const mapStateToProps = state => ({
    acessibilidadeRx: state.pageConfig.acessibilidade,
    credentials: state.auth.credentials || state.authPersist.credentials,
});

Login.propTypes = {
    embedded: PropTypes.bool,
    modal: PropTypes.bool,
    openModalRender: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    altRender: PropTypes.func,
    loginCallback: PropTypes.func,
    labelAcessarAlternativo: PropTypes.string
};

export default connect(mapStateToProps)(withRouter(MiddlewareLogin(Login)));
