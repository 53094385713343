import BaseRequest from "./BaseRequest";
import { API_URL } from "../config/config";

const CampusAPI = () => {
    return {
        buscarCampus(cancelToken = null) {
            return BaseRequest.axiosInst.get(`${API_URL}/campus`, {
                headers: BaseRequest.publicHeaders(),
                cancelToken
            });
        }
    };
};

export default CampusAPI();
