import BaseRequest from './BaseRequest';
import { API_URL } from '../config/config';

const PessoaRequest = () => {
    return {
        buscarDepartamentosPessoa() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/pessoas/departamentos`,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        iniciarRecuperacaoSenhaUsuario(codUsuario, recaptchaToken) {
            const params = {};
            if (recaptchaToken) params.recaptcha_token = recaptchaToken;

            return BaseRequest.axiosInst.get(
                `${API_URL}/pessoas/${codUsuario}/recuperar-senha`,
                { params, headers: BaseRequest.publicHeaders() },
            );
        },
        validarCodigoRecuperacaoSenhaUsuario(codUsuario, codigoDigitado) {
            return BaseRequest.axiosInst.post(
                `${API_URL}/pessoas/${codUsuario}/recuperar-senha`,
                {
                    code: codigoDigitado,
                },
                { headers: BaseRequest.publicHeaders() },
            );
        },
        alterarSenhaUsuario(codUsuario, codigoVerificacao, senha) {
            return BaseRequest.axiosInst.put(
                `${API_URL}/pessoas/${codUsuario}/recuperar-senha`,
                {
                    code: codigoVerificacao,
                    password: senha.trim(),
                },
                { headers: BaseRequest.publicHeaders() },
            );
        },
        geraUrlAcessoMeuPergamum() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/pessoas/meu-pergamum/redirect`,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        renovarValidade() {
            return BaseRequest.axiosInst.get(`${API_URL}/pessoas/renovar`, {
                headers: BaseRequest.privateHeaders(),
            });
        },
        geraUrlAcessoBase(base) {
            return BaseRequest.axiosInst.put(
                `${API_URL}/pessoas/meu-pergamum/redirect-base`,
                {
                    base: base.trim(),
                },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarAfastamentosAtivosUsuario() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/pessoas/afastamentos`,
                {
                    headers: BaseRequest.privateHeaders(),
                },
            );
        },
        buscarDadosPessoaisMeuPergamum() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/pessoas/meu-pergamum/dados-pessoais`,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        atualizaDadosPessoaisMeuPergamum(dadosFormulario) {
            return BaseRequest.axiosInst.put(
                `${API_URL}/pessoas/meu-pergamum/dados-pessoais`,
                dadosFormulario,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        geraRedirectPostagemTrabalho() {
            return BaseRequest.axiosInst.post(
                `${API_URL}/pessoas/postagem-trabalho/redirect`,
                {},
                { headers: BaseRequest.privateHeaders() },
            );
        },
        alterarSenhaUsuarioMeuPergamum(senhaAtual, senhaNova) {
            return BaseRequest.axiosInst.put(
                `${API_URL}/pessoas/meu-pergamum/senha`,
                { senhaAtual, senhaNova },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarNomePessoaPorUsuario(usuario) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/pessoas/${usuario}/nome`,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        trocarUsuarioMeuPergamum(usuario) {
            return BaseRequest.axiosInst.post(
                `${API_URL}/pessoas/meu-pergamum/troca-usuario`,
                { usuario },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarLoginsPessoa() {
            return BaseRequest.axiosInst.get(`${API_URL}/pessoas/login`, {
                headers: BaseRequest.privateHeaders(),
            });
        },
        trocarPessoa(codigo, base) {
            return BaseRequest.axiosInst.put(
                `${API_URL}/pessoas/troca-login`,
                {
                    codigo: codigo.trim(),
                    base: base.trim(),
                },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        getTokenRedireciona(redirecionaDados) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/pessoas/token-redireciona`,
                {
                    params: redirecionaDados,
                    headers: BaseRequest.publicHeaders()
                },
            );
        },
        buscarPessoasDuplicadas(usuario) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/pessoas/duplicadas`,
                {
                    params: {usuario},
                    headers: BaseRequest.publicHeaders()
                },
            );
        },
        autenticarUsuarioLayers(usuario) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/pessoas/autenticar/app-layers`,
                {
                    params: usuario,
                    headers: BaseRequest.publicHeaders()
                },
            );
        },
        getEmailPessoaLogada() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/pessoas/email-pessoa-logada`,
                {
                    headers: BaseRequest.privateHeaders()
                },
            );
        },
    };
};

export default PessoaRequest();
