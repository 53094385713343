import { VinculosAPI } from '../http-requests';
import { APIHelper, ToastifyHelper, ArrayHelper } from './';
import i18next from 'i18next';
import { Store } from '../../store';
import {
    changeSearch,
    limparFiltros,
    changeAcervosFiltrados,
} from '../../store/ducks/pesquisa';

const ConsultaHelper = () => {
    return {
        ordenarConsulta(argumento, consulta, direction) {
            let isNumeric = false;
            if (argumento === 'cod_tipo_obra' || argumento === 'cod_acervo')
                isNumeric = true;

            return consulta.sort(
                ArrayHelper.sortByProperty(argumento, direction, isNumeric),
            );
        },
        acessarLinkAcessoRestrito(codAcervo, seqParagrafo) {
            ToastifyHelper.info(i18next.t('AguardeBuscandoLink'));
            let authData =
                Store.getState().authPersist.user || Store.getState().auth.user;
            VinculosAPI.acessarLinkAcessoRestrito(
                codAcervo,
                seqParagrafo,
                authData.login,
            )
                .then(response => {
                    if (response.data.pearson) {
                        localStorage.setItem('pearsonData', response.data.data);
                        window.open(
                            window.location.origin + '/pearson',
                            '_blank',
                        );
                    } else {
                        window.open(response.data, '_blank');
                    }
                })
                .catch(error => APIHelper.tratarErro(error));
        },
        acessarLinkAcessoRestritoPDF(codAcervo, seqParagrafo) {
            const link = {};
            const authData =
                Store.getState().authPersist.user || Store.getState().auth.user;
            VinculosAPI.acessarLinkAcessoRestrito(
                codAcervo,
                seqParagrafo,
                authData.login,
            )
                .then(response => {
                    if (!response.data.pearson) {
                        link['descricao'] = i18next.t('AcessoRestrito');
                        link['url_link'] = response.data;
                    }
                })
                .catch(error => APIHelper.tratarErro(error));
            if (link && link.url_link !== null) {
                return link;
            }
        },
        acessarVinculo(codAcervo, seqParagrafo, url) {
            VinculosAPI.gravarEstatisticaAcessoVinculo(
                codAcervo,
                seqParagrafo,
                url,
            ).catch(error => {
                return;
            });
            window.open(url, '_blank');
        },
        limparConsulta(callback) {
            Store.dispatch(changeSearch(false));
            Store.dispatch(limparFiltros());
            Store.dispatch(changeAcervosFiltrados([]));
            if (callback !== undefined) {
                callback();
            }
        },
        acessarLinkAbnt(normaId) {
            ToastifyHelper.info(i18next.t('AguardeBuscandoLink'));

            VinculosAPI.acessarLinkAbnt(normaId)
                .then(response => {
                    window.open(response.data, '_blank');
                })
                .catch(error => APIHelper.tratarErro(error));
        },
        acessarNormaTarget(normaId) {
            ToastifyHelper.info(i18next.t('AguardeBuscandoLink'));

            VinculosAPI.acessarNormaTarget(normaId)
                .then(response => {
                    window.open(response.data, '_blank');
                })
                .catch(error => APIHelper.tratarErro(error));
        },
    };
};

export default ConsultaHelper();
