/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Collapse, Row, Col, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Store } from '../../store';
import { acessibilidadeData } from '../../store/ducks/pageConfig';
import Checkbox from './Checkbox';
import MenuUsuario from '../../modules/consulta/components/MenuUsuario';
import { ParametrosAPI } from '../http-requests';
import { APIHelper } from '../helpers';

function AcessibillityBar({
    acessibilidadeRx,
    instituicaoRx,
    showUserMenu,
    location,
}) {
    const [isAccessibilityOpen, setAccessibilityOpen] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [showAutoatendimento, setShowAutoatendimento] = useState(false);
    const [codBibAuto, setCodBibAuto] = useState(0);
    const [authAuto, setAuthAuto] = useState('');
    const [idiomaAuto, setIdiomaAuto] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        ParametrosAPI.validaAutoatendimento()
            .then(response => {
                setShowAutoatendimento(response.data.showAuto);
                setCodBibAuto(response.data.codBibAuto);
                setAuthAuto(response.data.authAuto);
                setIdiomaAuto(response.data.idiomaAuto);
            })
            .catch(error => APIHelper.tratarErro(error));
    }, [showAutoatendimento]);

    const toggleZoom = v => {
        if (v === null) v = 0;
        let value = v && v.target ? v.target.value : v;
        value = value * 1;
        Store.dispatch(
            acessibilidadeData({
                ...acessibilidadeRx,
                zoom: value,
            }),
        );
    };

    const toggleNegrito = n => {
        Store.dispatch(
            acessibilidadeData({
                ...acessibilidadeRx,
                negrito: n,
            }),
        );
    };

    const toggleLibras = libras => {
        Store.dispatch(
            acessibilidadeData({
                ...acessibilidadeRx,
                libras,
            }),
        );
    };

    const toggleCores = cor => {
        cor = cor && cor.target && cor.target.value ? cor.target.value : cor;

        if (!cor || !cor.trim().length) {
            cor = null;
        }

        Store.dispatch(
            acessibilidadeData({
                ...acessibilidadeRx,
                cor,
            }),
        );
    };

    return (
        <div className="bkpreto contrasteAlto">
            <Row
                className={
                    'b-w1 b-solid-bottom b-branco ' +
                    (isAccessibilityOpen ? ' bkazul' : '')
                }
                style={{ transition: '1000ms' }}
            >
                <Col xs="4" className="d-none d-md-block">
                    <Button
                        color="link"
                        onClick={() =>
                            setAccessibilityOpen(!isAccessibilityOpen)
                        }
                        className="nolink pad10 rad5 hover b-none branco hazul capitalizar s13 f500"
                    >
                        <FontAwesomeIcon
                            icon={['fas', 'universal-access']}
                            className="s18"
                        />{' '}
                        {t('Acessibilidade')}{' '}
                        <FontAwesomeIcon icon={['fas', 'angle-down']} />
                    </Button>
                </Col>
                <Col xs="3" className="d-block d-md-none">
                    {/* Não mostrar acessibilidade no mobile */}
                    {/* <Button
                        color="link"
                        onClick={() =>
                            setAccessibilityOpen(!isAccessibilityOpen)
                        }
                        className="nolink pad10 rad5 hover b-none branco hazul capitalizar s13 f500"
                    >
                        <FontAwesomeIcon
                            icon={['fas', 'universal-access']}
                            className="s18 r5"
                        />
                        <FontAwesomeIcon icon={['fas', 'angle-down']} />
                    </Button> */}

                    {/* {showUserMenu && (
                        <Button
                            color="link"
                            onClick={() => setMenuOpen(!isMenuOpen)}
                            className="nolink pad10 rad5 hover b-none branco hazul capitalizar s13 f500"
                        >
                            <FontAwesomeIcon
                                icon={['fas', 'bars']}
                                className="s18 r5"
                            />
                            <FontAwesomeIcon icon={['fas', 'angle-down']} />
                        </Button>
                    )} */}
                </Col>
                <Col
                    xs="6"
                    className="d-block d-md-none text-center branco f500 mt-2"
                >
                    {`${
                        location.pathname.includes('meupergamum')
                            ? t('MeuPergamum')
                            : t('CatalogoOnline')
                    } - ${instituicaoRx && instituicaoRx.sigla}`}
                </Col>
                <Col xs="3" className="d-block d-md-none text-right">
                    
                </Col>
                {!isAccessibilityOpen && showAutoatendimento ? (
                    <Col xs="4" className="text-center d-none d-md-block">
                        <Button
                            className="b-azul b-none b-w20 bkazul branco btn capitalizar f900 hover hverde mt-1 p-3 s13 sazul"
                            style={{ position: 'absolute', top: '10px',zIndex: '1000' }}
                            onClick={() => {
                                window.open(
                                    `
                                    ${instituicaoRx.p.__web_url_pw}home_geral/login_auto_atendimento.jsp?empresa=${instituicaoRx.codEmpresa}&biblioteca=${codBibAuto}&idioma=${idiomaAuto}&permissao_teclado_numerico=false&tipoAutenticacao=${authAuto}`,
                                    'ControlWindow',
                                    `width=${screen.width - 8},height=${
                                        screen.height - 75
                                    },top=0px,left=0px,status=yes,scrollbars=yes,resizable=yes`,
                                );
                            }}
                        >
                            <FontAwesomeIcon
                                icon={['fas', 'user-cog']}
                                className="s18"
                            />{' '}
                            {t('Autoatendimento')}
                        </Button>
                    </Col>
                ) : null}
                {!isAccessibilityOpen ? (
                    <Col xs="4" className="text-right d-none d-md-block">
                        {/* <Button to='/auto_atendimento' color='link' className='nolink branco b-none hover2 hverde capitalizar s13 f500 mt-1'>
              <FontAwesomeIcon icon={("fas", "map-marker-alt")} className='s18'/> SP - São Paulo/SP <FontAwesomeIcon icon={("fas", "angle-down")} />
        </Button> */}
                    </Col>
                ) : null}
            </Row>
            <Collapse isOpen={isAccessibilityOpen}>
                <Container className="branco p-md-3">
                    <Row>
                        <Col
                            xs="12"
                            className="t30 f300 s20 b-w1 b-solid-bottom b-branco20"
                        >
                            {t('MsgAcessibilidade')}
                        </Col>
                        <Col
                            xs="12"
                            md="5"
                            className="b-w1 b-solid-bottom b-branco20 s20 pad5 t20"
                        >
                            <FontAwesomeIcon
                                icon={('fas', 'low-vision')}
                                className="s15"
                                fixedWidth
                            />
                            {t('AjustarZoom')}
                            <div className="float-right d-inline-block">
                                <select
                                    onChange={toggleZoom}
                                    value={acessibilidadeRx.zoom}
                                    className="rad10 pad5 bkpreto branco b-preto f500 s18"
                                    style={{ paddingBottom: '2px' }}
                                >
                                    <option value="0">0%</option>
                                    <option value="20">20%</option>
                                    <option value="40">40%</option>
                                    <option value="60">60%</option>
                                    <option value="80">80%</option>
                                    <option value="100">100%</option>
                                </select>
                            </div>
                        </Col>
                        <Col md="2" />
                        <Col
                            xs="12"
                            md="5"
                            className="b-w1 b-solid-bottom b-branco20 s20 pad5 t20"
                        >
                            <FontAwesomeIcon
                                icon={('fas', 'fill-drip')}
                                className="s15"
                                fixedWidth
                            />
                            {t('EsquemaCores')}
                            <div className="d-inline-block float-right">
                                <select
                                    className="rad10 pad5 bkpreto branco b-preto f500 s18"
                                    onChange={toggleCores}
                                    value={acessibilidadeRx.cor}
                                >
                                    <option value=" ">{t('Normal')}</option>
                                    <option value="invertido">
                                        {t('InverterCores')}
                                    </option>
                                    <option value="escala-cinza">
                                        {t('EscalaCinza')}
                                    </option>
                                    <option value="contraste">
                                        {t('AltoContraste')}
                                    </option>
                                </select>
                            </div>
                        </Col>

                        <Col
                            md="5"
                            className="b-w1 b-solid-bottom b-branco20 s20 pad5 t20"
                        >
                            <FontAwesomeIcon
                                icon={('fas', 'bold')}
                                className="s15"
                                fixedWidth
                            />
                            {t('TextosNegrito')}
                            <div className="d-inline-block float-right">
                                <Checkbox
                                    onChange={() =>
                                        toggleNegrito(!acessibilidadeRx.negrito)
                                    }
                                    checked={acessibilidadeRx.negrito}
                                />
                            </div>
                        </Col>
                        <Col md="2" />
                        {/* <Col md="5" className="b-w1 b-solid-bottom b-branco20 s20 pad5 t20">
              <FontAwesomeIcon icon={("fas", "sign-language")} className="s15" fixedWidth={true} />
              {t('Libras')}
              <div className='d-inline-block float-right'>
                <Checkbox onChange={() => toggleLibras(!props.acessibilidadeRx.libras)} checked={props.acessibilidadeRx.libras} />
              </div>
            </Col> */}
                    </Row>
                </Container>
            </Collapse>
            {(location.pathname != "/login") && (
                <MenuUsuario mobile />
            )}
        </div>
    );
}

const mapStateToProps = state => ({
    acessibilidadeRx: state.pageConfig.acessibilidade,
    instituicaoRx: state.instituicao.data,
});

AcessibillityBar.propTypes = {
    showUserMenu: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(withRouter(AcessibillityBar));
