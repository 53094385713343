import BaseRequest from './BaseRequest';
import { Store } from '../../store';
import { toggleLoading } from '../../store/ducks/pageConfig';
import {
    addLog,
    changeAcervosFiltrados,
    changeSearch,
    limparFiltros,
} from '../../store/ducks/pesquisa';
import { APIHelper } from '../helpers';
import { API_URL } from '../config/config';
import diacritics from 'diacritics';

const ConsultaRequest = () => {
    return {
        buscarConsulta(tipoConsulta, parametros, location, callback) {
            Store.dispatch(toggleLoading(true));
            //Limpa os filtros previamente selecionados
            Store.dispatch(limparFiltros());
            Store.dispatch(changeAcervosFiltrados([]));

            //Se for target e NÃO possuir produto selecionado, retorna pesquisa vazia
            if (parametros.base === 'target_gedweb' && !parametros.coluna_um) {
                let consulta = {
                    data: [],
                    total: 0,
                    id: parametros.search_id,
                    base: parametros.base,
                    pesquisa: parametros.search_tab,
                };
                Store.dispatch(changeSearch(consulta));
                Store.dispatch(toggleLoading(false));
                return;
            }

            /* 11/04/2023 Removido pois estava impedindo pesquisa com caracteres não alfanuméricos
            if (parametros && parametros['termo_pesquisa']) {
                parametros['termo_pesquisa'] = diacritics.remove(parametros['termo_pesquisa']);
                parametros['termo_pesquisa'] = parametros['termo_pesquisa'].replace(/[^a-zA-Z0-9 ]/g, " ");
            }
            */

            this.consulta(parametros)
                .then(response => {
                    this.processarResultadoConsulta(
                        response,
                        parametros,
                        location,
                        tipoConsulta,
                    );
                    Store.dispatch(toggleLoading(false));
                })
                .catch(error => {
                    APIHelper.tratarErro(error);
                    Store.dispatch(changeSearch('error'));
                    Store.dispatch(toggleLoading(false));
                })
                .finally(() => {
                    //Executa o callback passado
                    if (callback) {
                        callback();
                    }
                });
        },
        processarResultadoConsulta(
            response,
            parametros,
            location,
            tipoConsulta,
        ) {
            let consulta = {};
            if (response.data) {
                consulta = {
                    data: response.data,
                    total: response.data.length,
                    id: parametros.search_id,
                    base: parametros.base || 'local',
                    pesquisa: parametros.search_tab,
                };
                //Grava a pesquisa no redux
                Store.dispatch(changeSearch(consulta));

                //Grava entrada no histórico
                Store.dispatch(
                    addLog({
                        id: parametros.search_id,
                        campos: [
                            ...Store.getState().pesquisa.opcoesConsulta.map(o =>
                                Object.assign({}, o),
                            ),
                        ],
                        registros: consulta.total,
                        pesquisa: tipoConsulta,
                        url: `${location.pathname}${location.search}`,
                        base: parametros.base || 'local',
                    }),
                );
            } else {
                Store.dispatch(changeSearch('error'));
            }
        },
        consulta(form) {
            return BaseRequest.axiosInst.get(`${API_URL}/consulta`, {
                params: form,
                headers: BaseRequest.privateOrPublicHeaders(),
            });
        },
        ordenacao(cancelToken) {
            return BaseRequest.axiosInst.get(`${API_URL}/consulta/ordenacao`, {
                headers: BaseRequest.publicHeaders(),
                cancelToken,
            });
        },
        buscarFiltros(guiaPesquisa, cancelToken) {
            return BaseRequest.axiosInst.get(`${API_URL}/consulta/filtros`, {
                params: {
                    search_tab: guiaPesquisa,
                },
                headers: BaseRequest.publicHeaders(),
                cancelToken,
            });
        },
        buscarOpcoesArquivo(cancelToken) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/opcoes-arquivo`,
                { headers: BaseRequest.publicHeaders(), cancelToken },
            );
        },
        buscarOpcoesAtosNormativos(cancelToken) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/opcoes-atos-normativos`,
                { headers: BaseRequest.publicHeaders(), cancelToken },
            );
        },
        buscarTitulosAdicionaisAcervos(acervos, cancelToken = null) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/titulos-adicionais`,
                {
                    params: { acervos: acervos },
                    headers: BaseRequest.publicHeaders(),
                    cancelToken,
                },
            );
        },
        buscarAcervosIndexadosAcervos(acervos, cancelToken = null) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/acervos-indexados`,
                {
                    params: { acervos: acervos },
                    headers: BaseRequest.publicHeaders(),
                    cancelToken,
                },
            );
        },
        buscarOpcoesConsultaIndice(cancelToken) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/opcoes-indice`,
                { headers: BaseRequest.publicHeaders(), cancelToken },
            );
        },
        buscarProdutosTarget(termoPesquisa) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/produtos-target`,
                {
                    params: { termoPesquisa: termoPesquisa },
                    headers: BaseRequest.privateOrPublicHeaders(),
                },
            );
        },
        buscarDisciplinas(searchString, page){
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/disciplinas`,
                {
                    params: { search_text: searchString ?? '', page: page ?? 1},
                    headers: BaseRequest.privateOrPublicHeaders(),
                },
            );
        },
        buscarCursos(searchString, page){
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/cursos`,
                {
                    params: { search_text: searchString ?? '', page: page ?? 1},
                    headers: BaseRequest.privateOrPublicHeaders(),
                },
            );
        },
        buscarProfessores(searchString, page){
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/professores`,
                {
                    params: {search_text: searchString ?? '', page: page ?? 1},
                    headers: BaseRequest.privateOrPublicHeaders(),
                },
            );
        },
        buscarCampus(searchString, page){
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/campus`,
                {
                    params: { search_text: searchString ?? '', page: page ?? 1},
                    headers: BaseRequest.privateOrPublicHeaders(),
                },
            );
        },
        buscarBibliotecas(searchString, page, codCampus = null){
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/bibliotecas`,
                {
                    params: { search_text: searchString ?? '', page: page ?? 1, codCampus},
                    headers: BaseRequest.privateOrPublicHeaders(),
                },
            );
        },
        buscarProfessorPorCodigo: (cod_professor) => {
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/professor/${cod_professor}`,
                {
                    headers: BaseRequest.privateOrPublicHeaders(),
                },
            );
        },
        buscarCursoPorCodigo: (cod_departamento) => {
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/curso/${cod_departamento}`,
                {
                    headers: BaseRequest.privateOrPublicHeaders(),
                },
            );
        },
        buscarCampusPorCodigo: (cod_campus) => {
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/campus/${cod_campus}`,
                {
                    headers: BaseRequest.privateOrPublicHeaders(),
                },
            );
        },
        buscarDisciplinaPorCodigo: (cod_disciplina) => {
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/disciplina/${cod_disciplina}`,
                {
                    headers: BaseRequest.privateOrPublicHeaders(),
                },
            );
        },
        buscarBibliotecaPorCodigo: (cod_biblioteca) => {
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/biblioteca/${cod_biblioteca}`,
                {
                    headers: BaseRequest.privateOrPublicHeaders(),
                },
            );
        },
        buscarImagemPadrao(){
            return BaseRequest.axiosInst.get(
                `${API_URL}/consulta/imagem-padrao`,
                {
                    headers: BaseRequest.privateOrPublicHeaders(),
                },
            );
        }
    };
};
export default ConsultaRequest();
