export const LayoutHelper = () => {
    return {
        getRootClasses(instituicao, acessibilidade) {
            return [
                'app acessibilidade',
                acessibilidade.cor === 'contraste'
                    ? 'temaAltoContraste alto-contraste'
                    : instituicao?.tema?.toLowerCase(),
                acessibilidade.zoom ? `zoom-${acessibilidade.zoom}` : null,
                acessibilidade.negrito ? 'negrito' : null,
                acessibilidade.cor,
            ].join(' ');
        },
    };
};
