import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Store } from '../store';
import {
    loadPage,
    insertAtividades,
    setGuiasPesquisa,
} from '../store/ducks/pageConfig';
import { loadPublicCredentials } from '../store/ducks/publicCredentials';
import LoadingPage from '../modules/consulta/components/LoadingPage';
import { BaseAPI, ParametrosAPI } from '../shared/http-requests';
import { APIHelper } from '../shared/helpers';
import { loadInstitutionData } from '../store/ducks/instituicao';

const MiddlewareLogin = ComponentToWrap =>
    connect(mapStateToProps)(
        class extends Component {
            UNSAFE_componentWillMount() {
                //? Inicializa requisição para recuperar todos os componentes vindos da API:
                if (!this.props.pageConfig.status) {
                    (async instance => {
                        if (!instance.props.publicCredentials) {
                            let token = await BaseAPI.getPublicCredentials();

                            Store.dispatch(
                                loadPublicCredentials({
                                    token: token.access_token,
                                }),
                            );
                        }

                        //if (!instance.props.pageConfig.atividades?.length) {
                        await ParametrosAPI.buscarFuncionalidades()
                            .then(response => {
                                Store.dispatch(insertAtividades(response.data));
                            })
                            .catch(console.error);
                        //}

                        if (!instance.props.instituicao) {
                            await ParametrosAPI.buscarInstituicao()
                                .then(response => {
                                    Store.dispatch(
                                        loadInstitutionData(response.data),
                                    );
                                })
                                .catch(console.error);
                        }

                        if (!instance.props.pageConfig.guiasPesquisa?.length) {
                            await ParametrosAPI.buscarDadosSecao1()
                                .then(response =>
                                    Store.dispatch(
                                        setGuiasPesquisa(response.data),
                                    ),
                                )
                                .catch(error => APIHelper.tratarErro(error));
                        }

                        Store.dispatch(loadPage(true));
                    })(this);
                }
            }

            render() {
                if (this.props.pageConfig.status) {
                    return <ComponentToWrap {...this.props} />;
                } else {
                    return <LoadingPage />;
                }
            }
        },
    );

const mapStateToProps = state => ({
    pageConfig: state.pageConfig,
    instituicao: state.instituicao.data,
    publicCredentials: state.publicCredentials.credentials,
});

export default MiddlewareLogin;
