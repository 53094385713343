import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import logopergamum from '../../../../images/logo_pergamum_vertical.png';
import { SpinnerConstructor } from '../../../shared/components';

const LoadingPage = () => {
    return (
        <Container>
            <Row>
                <Col
                    xs="12"
                    md={{ size: 4, offset: 4 }}
                    className="mt-5 p-md-5 p-0"
                >
                    <div className="animated fadeIn mt-3">
                        <img
                            className="img-fluid"
                            src={logopergamum}
                            alt="logo_pergamum"
                        />
                    </div>
                    <div className="p-5 text-center animated fadeIn">
                        <SpinnerConstructor
                            visible
                            showLabel
                            height={80}
                            width={80}
                            className="apergamum"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default LoadingPage;
