/*
 *-----------------------------------------------------------------------------------------------------------
 * ACTIONS
 *-----------------------------------------------------------------------------------------------------------
 */
const ADD_BASKET_ITEM = "ADD_BASKET_ITEM";
const ADD_ALL_BASKET_ITEMS = "ADD_ALL_BASKET_ITEMS";
const SET_BASKET_ITEMS = "SET_BASKET_ITEMS"
const REMOVE_BASKET_ITEM = "REMOVE_BASKET_ITEM";
const CLEAR_BASKET = "CLEAR_BASKET";

export const addItemCesta = payload => ({type: ADD_BASKET_ITEM, payload})

export const addAllItensCesta = payload => ({type: ADD_ALL_BASKET_ITEMS, payload})

export const removeItemCesta = payload => ({type: REMOVE_BASKET_ITEM, payload})

export const limparCesta = payload => ({type : CLEAR_BASKET, payload})

export const setarItensCesta = payload => ({type: SET_BASKET_ITEMS, payload})

/*
 *-----------------------------------------------------------------------------------------------------------
 * INITIAL STATE
 *-----------------------------------------------------------------------------------------------------------
 */
const InitialState = {
    itens : []
}
/*
 *-----------------------------------------------------------------------------------------------------------
 * REDUCERS
 *-----------------------------------------------------------------------------------------------------------
 */
const cesta = (state = InitialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case ADD_BASKET_ITEM:
            return { ...state, itens: [...state.itens, payload] }
        case ADD_ALL_BASKET_ITEMS:
            return {...state, itens : state.itens.concat(payload)};
        case REMOVE_BASKET_ITEM:
            let newItens = state.itens.filter(elem => {
                return elem.cod_acervo !== payload
            });
            return {...state, itens: newItens};
        case CLEAR_BASKET:
            return {...state, itens : []};
        case SET_BASKET_ITEMS:
            return {...state, itens : payload};
        default:
            return state;
    }
}


export default cesta;