import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({
    userSessionRx,
    userLocalRx,
    component,
    path,
    ...rest
}) => {
    const ComponentToRender = component;
    const isAuthenticated = userSessionRx || userLocalRx;

    return (
        <Route
            {...rest}
            path={path}
            render={renderProps => {
                if (!isAuthenticated) {
                    return <Redirect to={`/login?redirect=${path}`} />;
                }
                return <ComponentToRender {...rest} {...renderProps} />;
            }}
        />
    );
};

const mapStateToProps = state => ({
    userSessionRx: state.auth.user,
    userLocalRx: state.authPersist.user,
});

export default connect(mapStateToProps)(ProtectedRoute);
