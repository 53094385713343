const Activity = {
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    USER_DATA: 'USER_DATA',
    AUTH_DATA: 'AUTH_DATA'
}

const InitialState = {
    credentials: false,
    user: false
};

export const AuthSession = {
    storeToken(payload) {
        return { type: Activity.ACCESS_TOKEN, payload }
    },
    storeUserData(payload) {
        return { type: Activity.USER_DATA, payload }
    },
    storeData(payload){
        return {type: Activity.AUTH_DATA, payload}
    },
    logout() {
        return AuthSession.storeData(InitialState);
    }
};

const auth = (state = InitialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case Activity.AUTH_DATA:
            return payload;
        case Activity.ACCESS_TOKEN:
            return { ...state, credentials: payload};
        case Activity.USER_DATA:
            return { ...state, user: payload };
        default:
            return state;
    }
}

export default auth;