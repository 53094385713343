/*
 *-----------------------------------------------------------------------------------------------------------
 * ACTIONS
 *-----------------------------------------------------------------------------------------------------------
 */
const CHANGE_SEARCH = "CHANGE_SEARCH";
const CHANGE_SEARCH_ITEMS = "CHANGE_SEARCH_ITEMS";
const INSERT_OR_CLEAN_ITENS_CHECK = "INSERT_OR_CLEAN_ITENS_CHECK";
const ADD_LOG = "ADD_LOG";
const REMOVE_LOG = "REMOVE_LOG";
const CHANGE_SEARCH_OPTIONS = "CHANGE_SEARCH_OPTIONS";
const LOGOUT = "LOGOUT";
const OPCOES_RESULTADO = "OPCOES_RESULTADO";
const ADD_FILTER = "ADD_FILTER";
const REMOVE_FILTER = "REMOVE_FILTER";
const CLEAR_FILTERS = "CLEAR_FILTERS";
const FILTERED_TITLES = "FILTERED_TITLES";
const CHANGE_PLANO_ENSINO_SEARCH_OPTIONS = "CHANGE_PLANO_ENSINO_SEARCH_OPTIONS";
const OPCOES_NOVAS_AQUISICOES = "OPCOES_NOVAS_AQUISICOES";
const IMAGEM_PADRAO = "IMAGEM_PADRAO";

export const changeSearch = payload => ({ type: CHANGE_SEARCH, payload });

export const changeSearchItems = payload => ({ type: CHANGE_SEARCH_ITEMS, payload});

export const insertItensCheck = payload => ({ type: INSERT_OR_CLEAN_ITENS_CHECK, payload });

export const addLog = payload => ({ type: ADD_LOG, payload })

export const removeLog = payload => ({type: REMOVE_LOG, payload})

export const changeSearchOptions = payload => ({type : CHANGE_SEARCH_OPTIONS, payload});

export const changeOpcoesResultado = payload => ({type: OPCOES_RESULTADO, payload});

export const addFiltro = payload => ({type: ADD_FILTER, payload});

export const removeFiltro = payload => ({type: REMOVE_FILTER, payload});

export const limparFiltros = payload => ({type: CLEAR_FILTERS, payload});

export const changeAcervosFiltrados = payload => ({type: FILTERED_TITLES, payload});

export const changePlanoEnsinoSearchOptions = payload => ({type : CHANGE_PLANO_ENSINO_SEARCH_OPTIONS, payload});


export const changeOpcoesNovasAquisicoes = payload => ({type: OPCOES_NOVAS_AQUISICOES, payload});

export const setImagemPadrao = payload => ({type: IMAGEM_PADRAO, payload});

/*
 *-----------------------------------------------------------------------------------------------------------
 * ESTADO INICIAL
 *-----------------------------------------------------------------------------------------------------------
 */
const INITIAL_STATE = {
    consulta: false,
    opcoesConsulta: [],
    opcoesConsultaPlanoEnsino: {},
    log: [],
    opcoesResultado: {
        paginaAtiva: 1,
        ordenacao: 'C',
        ordenacaoCombo: false,
        quantPag: 20,
        exibicao: 'D',
        carregouExibicaoPadrao: false
    },
    opcoesNovasAquisicoes: {
        exibicao: 'C'
    },
    filtros : [],
    acervosFiltrados: [],
    imagemPadrao: null
};

/*
 *-----------------------------------------------------------------------------------------------------------
 * REDUCERS
 *-----------------------------------------------------------------------------------------------------------
 */
const pesquisa = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case CHANGE_SEARCH:
            return { ...state, consulta: payload }

        case CHANGE_SEARCH_ITEMS:
            let itens = [];
            if(state.consulta.base === 'local'){
                itens = state.consulta.data.map(item => {
                    let itemModificado = payload.find(i => (i.cod_acervo === item.cod_acervo));
                    return itemModificado || item;
                });
            }
            if(state.consulta.base === 'rede_pergamum'){
                itens = state.consulta.data.map(item => {
                    let itemModificado = payload.find(i => (i.acervo === item.acervo));
                    return itemModificado || item;
                });
            }
            if(state.consulta.base === 'abnt')
                itens = state.consulta.data;

            return { ...state, consulta: {...state.consulta, data: itens}};
        case INSERT_OR_CLEAN_ITENS_CHECK:
            return { ...state, itensSelec: payload }

        case ADD_LOG:
            return { ...state, log: [...state.log, payload] }

        case REMOVE_LOG:
            let newLog = state.log.filter(elem => {
                return elem.id !== payload
            });
            return {...state, log: newLog};
        case LOGOUT:
            return { isLoggedIn: false };

        case CHANGE_SEARCH_OPTIONS:
            return {...state, opcoesConsulta: [...payload]};

        case OPCOES_RESULTADO:
            return {...state, opcoesResultado: payload};

        case ADD_FILTER:
            return {...state, filtros: [...state.filtros, payload]};

        case REMOVE_FILTER:
            let newFilters = state.filtros.filter(f =>  (f.tipo !== payload.tipo) || ( f.tipo === payload.tipo && f.codigo !== payload.codigo && f.descricao !== payload.descricao));
            return {...state, filtros: newFilters};

        case CLEAR_FILTERS:
            return {...state, filtros: INITIAL_STATE.filtros};

        case FILTERED_TITLES:
            return {...state, acervosFiltrados: payload};

        case CHANGE_PLANO_ENSINO_SEARCH_OPTIONS:
            return {...state, opcoesConsultaPlanoEnsino: payload}

        case OPCOES_NOVAS_AQUISICOES:
            return  {...state, opcoesNovasAquisicoes: payload};

        case IMAGEM_PADRAO:
            return  {...state, imagemPadrao: payload};

        default:
            return state;
    }
};

export default pesquisa;
