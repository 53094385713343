export class FormHelper {
    static validateEmail(email) {
        var re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static phoneNumber(text) {
        //? Apenas dígitos, hífen, parenteses e espaços
        return text.replace(/[^\d-() ]/g, '').substring(0, 16);
    }
}
