import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Input, Label, Row } from 'reactstrap';
import { StringHelper, ToastifyHelper } from '../../../../shared/helpers';
import { PessoaAPI } from '../../../../shared/http-requests';
import { SpinnerConstructor } from '../../../../shared/components';

const ForgotPassword = ({ usaRecaptcha, changeModeCallback, embedded }) => {
    const currentLang = localStorage.getItem('i18nextLng');
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    const [user, setUser] = useState({ username: '' });
    const [recaptchaToken, setRecaptchaToken] = useState(false);
    const [code, setCode] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showResendBtn, setShowResendBtn] = useState(false);
    const [password, setPassword] = useState(false);
    const recaptchaInstance = useRef();

    const handleChangeUsername = e => {
        const val = e.target.value;
        setUser(u => ({ ...u, username: val.trim() }));
    };

    const recaptchaCallback = args => {
        setRecaptchaToken(args);
    };

    const validarUsuario = () => {
        if (!user.username || !user.username.trim()) {
            setError(1);
            if (recaptchaInstance.current) recaptchaInstance.current.reset();
            return;
        }
        if (!recaptchaToken && usaRecaptcha) {
            setError(2);
            return;
        }
        setError(false);
        setLoading(true);
        PessoaAPI.iniciarRecuperacaoSenhaUsuario(
            user.username.trim(),
            usaRecaptcha ? recaptchaToken : false,
        )
            .then(response => {
                setUser(u => ({
                    ...u,
                    emails: response.data.emails,
                    name: response.data.name,
                }));
                setCode({
                    length: response.data.length,
                    tte: response.data.tte,
                    value: '',
                });
                setStep(2);
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 500)
                        error.response.data.message = t(
                            'OcorreuErroInesperado',
                        );
                    setError(error.response.data);
                }
            })
            .finally(() => {
                setLoading(false);
                setRecaptchaToken(false);
            });
    };

    const handleChangeCode = ({ target: { value } }) => {
        setCode(c => ({ ...c, value }));

        setError(false);
        if (value.length === code.length) {
            setLoading(true);
            PessoaAPI.validarCodigoRecuperacaoSenhaUsuario(
                user.username.trim(),
                value,
            )
                .then(response => {
                    setShowResendBtn(false);
                    setPassword({
                        value: '',
                        confirm: '',
                        rules: response.data.rules,
                        valid: [],
                        valueVisible: false,
                        confirmVisible: false,
                    });
                    setStep(3);
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 500)
                            error.response.data.message = t(
                                'OcorreuErroInesperado',
                            );
                        else if (error.response.status === 422)
                            setShowResendBtn(true);
                        setError(error.response.data);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const reenviarCodigo = () => {
        setError(false);
        setLoading(true);
        PessoaAPI.iniciarRecuperacaoSenhaUsuario(user.username.trim())
            .then(response => {
                setCode({
                    length: response.data.length,
                    tte: response.data.tte,
                    value: '',
                });
                setShowResendBtn(false);
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 500)
                        error.response.data.message = t(
                            'OcorreuErroInesperado',
                        );
                    setError(error.response.data);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleChangePassword = (e, isConfirm) => {
        const {
            target: { value: val },
        } = e;
        if (isConfirm) {
            setPassword(p => ({ ...p, confirm: val }));
        } else {
            // ? Valida as regras de senha
            let rules = [];
            Object.keys(password.rules).forEach(rule => {
                if (password.rules[rule]) {
                    let valid = false;
                    switch (rule) {
                        case 'length':
                            valid =
                                val.length >= password.rules[rule].min &&
                                val.length <= password.rules[rule].max;
                            break;
                        case 'has_numbers':
                            valid = /\d/.test(val);
                            break;
                        case 'only_numbers':
                            valid = /^\d*$/.test(val);
                            break;
                        case 'special_char':
                            valid = /[@!#$%&*()_-]+/.test(val);
                            break;
                        case 'diff_case':
                            valid =
                                val.toLowerCase() !== val &&
                                val.toUpperCase() !== val;
                            break;
                        case 'diff_name':
                            valid = !StringHelper.containsAny(
                                val,
                                user.name.trim().split(' '),
                            );
                            break;
                        default:
                            break;
                    }
                    rules = valid ? [...rules, rule] : rules;
                }
            });
            setPassword(p => ({ ...p, value: val, valid: rules }));
        }
    };

    const gravarNovaSenha = () => {
        if (
            password.confirm.trim().length === 0 ||
            password.value.trim().length === 0 ||
            password.value !== password.confirm
        ) {
            return;
        }

        const shouldValid = Object.keys(password.rules).filter(
            r => password.rules[r] && r !== 'last_pw',
        );
        if (shouldValid.sort().join(',') !== password.valid.sort().join(',')) {
            setError(3);
            return;
        }

        setLoading(true);
        setError(false);

        PessoaAPI.alterarSenhaUsuario(user.username, code.value, password.value)
            .then(() => {
                ToastifyHelper.success(t('SenhaAlteradaSucesso'));
                changeModeCallback();
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 500)
                        error.response.data.message = t(
                            'OcorreuErroInesperado',
                        );
                    setError(error.response.data);
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <Row>
            <Alert
                color="warning"
                className="p-2"
                isOpen={error !== false}
                toggle={() => setError(false)}
                style={{ width: '100%' }}
            >
                {(() => {
                    switch (error) {
                        case 1:
                            return t('DigiteCodigoUsuario');
                        case 2:
                            return t('AuthCAPTCHAError');
                        case 3:
                            return t('SenhaNaoConfereCriterios');
                        default:
                            return error.message;
                    }
                })()}
            </Alert>
            {loading ? (
                <Col md="12" xs="12">
                    <div className="text-center animated fadeIn p-3">
                        <SpinnerConstructor
                            visible
                            width={60}
                            height={60}
                            className="s20 cortxt70"
                        />
                        <div className="t20">
                            <span className="preto70 f300 s20">
                                {t('AguardeUmMomento')}...
                            </span>
                        </div>
                    </div>
                </Col>
            ) : (
                <>
                    {step === 1 && ( // ? PASSO VALIDAR USUÁRIO
                        <Col md="12" xs="12" className="p-2">
                            <div className="pb-2">
                                <span className="preto f500 s14">
                                    <FontAwesomeIcon
                                        icon="question-circle"
                                        className="r5"
                                    />
                                    {t('MsgRecuperarSenhaUsuario')}
                                </span>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <FontAwesomeIcon
                                    icon="user"
                                    className="fa-fw cortxt r5 l5 s20 t5 cortxt"
                                />
                                <Input
                                    value={user.username}
                                    className={embedded && 's13'}
                                    onChange={e => handleChangeUsername(e)}
                                    style={{ width: 'calc( 100% - 100px)' }}
                                />
                            </div>
                            {usaRecaptcha && (
                                <div
                                    className="ml-5 pt-3"
                                    style={{
                                        width: 'calc( 100% - 100px)',
                                        display: 'table',
                                    }}
                                >
                                    <ReCAPTCHA
                                        id={`recaptcha__${Math.floor(
                                            Math.random() * 100,
                                        )}`}
                                        ref={recaptchaInstance}
                                        size={
                                            window.matchMedia(
                                                '(max-width: 575.98px)',
                                            ).matches
                                                ? 'compact'
                                                : 'normal'
                                        }
                                        sitekey={
                                            process.env
                                                .REACT_APP_RECAPTCHA_SITEKEY
                                        }
                                        onChange={recaptchaCallback}
                                    />
                                </div>
                            )}
                            <div className="pt-2 pb-2 text-center">
                                <Button
                                    className="bkverde branco b-none sverde hover hpreto pad5 rad5 t10 r10"
                                    onClick={() => validarUsuario()}
                                >
                                    <FontAwesomeIcon
                                        icon="check"
                                        className="r5"
                                    />
                                    {t('Prosseguir')}
                                </Button>
                            </div>
                        </Col>
                    )}
                    {step === 2 && ( // ? PASSO CÓDIGO DE VERIFICAÇÃO
                        <Col md="12" xs="12" className="p-2">
                            <div className="pb-2">
                                <span className="preto70 f400 s16">
                                    {t('MsgDigitarCodigoVerificacao').replace(
                                        '#{NOME}',
                                        user.name,
                                    )}
                                </span>
                                {user.emails.map((e, i) => (
                                    <div key={`mail=${i}`} className="pad5">
                                        <span className="f500 s14 verde">
                                            {e}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className="pb-2 pt-1">
                                <Input
                                    value={code && code.value}
                                    className="verify-code ml-4"
                                    placeholder={'0'.repeat(code.length)}
                                    maxLength={code.length}
                                    onChange={e => handleChangeCode(e)}
                                />
                            </div>
                            {showResendBtn && (
                                <div className="pt-2 pb-2">
                                    <span
                                        className="pad5 rad5 link hover hazul preto70 s15 click"
                                        onClick={() => reenviarCodigo()}
                                    >
                                        <FontAwesomeIcon
                                            icon="sync"
                                            className="r5"
                                        />
                                        {t('ReenviarCodigoVerificacao')}
                                    </span>
                                </div>
                            )}
                            <div className="pt-2 pb-2">
                                <span className="preto60 s13 font-italic">
                                    <FontAwesomeIcon
                                        icon="exclamation-triangle"
                                        className="laranja r5"
                                    />
                                    {t('CodigoVerificacaoExpiraraEm').replace(
                                        '#{DT}',
                                        moment(new Date(code.tte))
                                            .locale(
                                                currentLang === 'pt'
                                                    ? 'pt-br'
                                                    : currentLang,
                                            )
                                            .format('D MMMM YYYY - hh:mm:ss a'),
                                    )}
                                </span>
                            </div>
                        </Col>
                    )}
                    {step === 3 && ( // ? PASSO NOVA SENHA
                        <Col md="12" xs="12">
                            <div className="pt-2 pb-2">
                                <span className="preto70 f400 s16">
                                    {t('RedefinaSenha')}
                                </span>
                                <Alert color="primary" className="mt-2">
                                    {Object.keys(password.rules).map(r => {
                                        if (password.rules[r]) {
                                            let text = '';

                                            text = t(`RegraSenha(${r})`);

                                            if (r === 'length')
                                                text = text
                                                    .replace(
                                                        '#{MIN}',
                                                        password.rules[r].min,
                                                    )
                                                    .replace(
                                                        '#{MAX}',
                                                        password.rules[r].max,
                                                    );
                                            else if (
                                                r === 'last_pw' &&
                                                password.rules[r]
                                            ) {
                                                if (password.rules[r] > 0) {
                                                    text = text.replace(
                                                        '#{qtd}',
                                                        password.rules[r],
                                                    );
                                                } else {
                                                    return '';
                                                }
                                            }

                                            return (
                                                <div key={`hint-${r}`}>
                                                    {password.valid.includes(
                                                        r,
                                                    ) ? (
                                                        <FontAwesomeIcon
                                                            icon="check-circle"
                                                            className="verde animated zoomIn s18 r5"
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon="circle"
                                                            className="s13 r5"
                                                        />
                                                    )}
                                                    {text}
                                                </div>
                                            );
                                        }
                                        return '';
                                    })}
                                </Alert>
                            </div>
                            <div className="pt-1 pb-2">
                                <Label
                                    for="new_password"
                                    className="preto s14 f400"
                                >
                                    {t('NovaSenha')}:
                                </Label>
                                <div className="d-flex flex-row">
                                    <Input
                                        id="new_password"
                                        type={
                                            password.valueVisible
                                                ? 'text'
                                                : 'password'
                                        }
                                        value={password.value}
                                        onChange={e =>
                                            handleChangePassword(e, false)
                                        }
                                        width="91%"
                                    />
                                    <button
                                        type="button"
                                        className="s20 b-none hover htema cortxt pad5 rad5 d-inline ml-2"
                                        onClick={() =>
                                            setPassword(pw => ({
                                                ...pw,
                                                valueVisible:
                                                    !password.valueVisible,
                                            }))
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                password.valueVisible
                                                    ? 'eye'
                                                    : 'eye-slash'
                                            }
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className="pt-2 pb-2">
                                <Label
                                    for="new_password_confirm"
                                    className="preto s14 f400"
                                >
                                    {t('ConfirmarSenha')}:
                                </Label>
                                <div className="d-flex flex-row">
                                    <Input
                                        id="new_password_confirm"
                                        type={
                                            password.confirmVisible
                                                ? 'text'
                                                : 'password'
                                        }
                                        onChange={e =>
                                            handleChangePassword(e, true)
                                        }
                                        value={password.confirm}
                                        width="91%"
                                    />
                                    <button
                                        type="button"
                                        className="s20 b-none hover htema cortxt pad5 rad5 d-inline ml-2"
                                        onClick={() =>
                                            setPassword(pw => ({
                                                ...pw,
                                                confirmVisible:
                                                    !password.confirmVisible,
                                            }))
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                password.confirmVisible
                                                    ? 'eye'
                                                    : 'eye-slash'
                                            }
                                        />
                                    </button>
                                </div>
                            </div>
                            {password.value !== password.confirm &&
                                password.confirm.length > 0 &&
                                password.value.length > 0 && (
                                    <div className="pad5 vermelho">
                                        <FontAwesomeIcon
                                            icon="exclamation-circle"
                                            className="r5"
                                        />
                                        {t('AsSenhasNaoCoincidem')}
                                    </div>
                                )}
                            <div className="pt-2 pb-2 text-center">
                                <Button
                                    className="bkazul branco b-none sazul hover hpreto pad5 rad5 t10 r10"
                                    onClick={() => gravarNovaSenha()}
                                >
                                    <FontAwesomeIcon
                                        icon="save"
                                        className="r5"
                                    />
                                    {t('Gravar')}
                                </Button>
                            </div>
                        </Col>
                    )}
                </>
            )}
        </Row>
    );
};

export default ForgotPassword;
