/*
 *-----------------------------------------------------------------------------------------------------------
 * ACTIONS
 *-----------------------------------------------------------------------------------------------------------
 */
const LOAD_PAGE = 'LOAD_PAGE';
const INSERT_ATIVIDADES = 'INSERT_ATIVIDADES';
const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
const TOGGLE_LOADING = 'TOGGLE_LOADING';
const LOGOUT = 'LOGOUT';
const ACESSIBILIDADE_DATA = 'ACESSIBILIDADE_DATA';
const GUIAS_PESQUISA = 'GUIAS_PESQUISA';
const TOGGLE_MEU_PERGAMUM_THEME = 'TOGGLE_MEU_PERGAMUM_THEME';
const TOGGLE_MEU_PERGAMUM_MENU = 'TOGGLE_MEU_PERGAMUM_MENU';
const MEU_PERGAMUM_MENU_OPEN = 'MEU_PERGAMUM_MENU_OPEN';
const TOGGLE_MEU_PERGAMUM_MENU_MOBILE = 'TOGGLE_MEU_PERGAMUM_MENU_MOBILE';
const ADD_FORM_REF = 'ADD_FORM_REF';

export const loadPage = payload => ({ type: LOAD_PAGE, payload });

export const insertAtividades = payload => ({
    type: INSERT_ATIVIDADES,
    payload,
});

export const changeLanguage = payload => ({ type: CHANGE_LANGUAGE, payload });

export const toggleLoading = payload => ({ type: TOGGLE_LOADING, payload });

export const acessibilidadeData = payload => ({
    type: ACESSIBILIDADE_DATA,
    payload,
});

export const setGuiasPesquisa = payload => ({ type: GUIAS_PESQUISA, payload });

export const toggleMeuPergamumTheme = payload => ({
    type: TOGGLE_MEU_PERGAMUM_THEME,
    payload,
});

export const toggleMeuPergamumMenu = () => ({
    type: TOGGLE_MEU_PERGAMUM_MENU,
});

export const setMeuPergamumMenuOpen = payload => ({
    type: MEU_PERGAMUM_MENU_OPEN,
    payload,
});

export const toggleMeuPergamumMenuMobile = () => ({
    type: TOGGLE_MEU_PERGAMUM_MENU_MOBILE,
});


export const addFormRef = (payload) => ({
    type: ADD_FORM_REF,
    payload,
});

/*
 *-----------------------------------------------------------------------------------------------------------
 * ESTADO INICIAL
 *-----------------------------------------------------------------------------------------------------------
 */
const INITIAL_STATE = {
    status: false,
    atividades: [],
    acessibilidade: {
        zoom: 0,
        negrito: false,
        cor: '',
        libras: false,
    },
    loading: false,
    guiasPesquisa: [],
    meuPergamum: { theme: 'neo', menuOpen: true, menuMobileOpen: false },
    formRef: null
};

/*
 *-----------------------------------------------------------------------------------------------------------
 * REDUCERS
 *-----------------------------------------------------------------------------------------------------------
 */
const pageConfig = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOAD_PAGE:
            return { ...state, status: payload };
        case INSERT_ATIVIDADES:
            return { ...state, atividades: payload };
        case CHANGE_LANGUAGE:
            return { ...state, lng: payload };
        case TOGGLE_LOADING:
            return { ...state, loading: payload };
        case LOGOUT:
            return { isLoggedIn: false };
        case ACESSIBILIDADE_DATA:
            return { ...state, acessibilidade: payload };
        case GUIAS_PESQUISA:
            return { ...state, guiasPesquisa: payload };
        case TOGGLE_MEU_PERGAMUM_THEME:
            return {
                ...state,
                meuPergamum: { ...state.meuPergamum, theme: payload },
            };
        case TOGGLE_MEU_PERGAMUM_MENU:
            return {
                ...state,
                meuPergamum: {
                    ...state.meuPergamum,
                    menuOpen: !state.meuPergamum.menuOpen,
                },
            };
        case TOGGLE_MEU_PERGAMUM_MENU_MOBILE:
            return {
                ...state,
                meuPergamum: {
                    ...state.meuPergamum,
                    menuMobileOpen: !state.meuPergamum.menuMobileOpen,
                },
            };
        case MEU_PERGAMUM_MENU_OPEN:
            return {
                ...state,
                meuPergamum: {
                    ...state.meuPergamum,
                    menuOpen: payload,
                },
            };
        case ADD_FORM_REF:
            return {
                ...state,
                formRef: payload,
            };
        default:
            return state;
    }
};

export default pageConfig;
