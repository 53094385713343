import BaseRequest from './BaseRequest';
import { API_URL } from '../config/config';

const BASE_URL = `${API_URL}/nada-consta`
const NadaConstaRequest = () => {
    return {
        sendMail(params) {
            return BaseRequest.axiosInst.post(
                `${BASE_URL}/send-mail`,
                 params,
                { headers: {
                    ...BaseRequest.privateHeaders(),
                    'Content-Type': 'multipart/form-data'
                } },
            );
        },
    };
};

export default NadaConstaRequest();
