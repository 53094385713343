const StringHelper = () => {
    return {
        replaceAll(haystack, needles, replacement) {
            for (let i = 0; i < needles.length; i++) {
                let needle = needles[i];
                let pos = haystack.indexOf(needle);
                while (pos > -1) {
                    haystack = haystack.replace(needle, replacement);
                    pos = haystack.indexOf(needle);
                }
            }
            return haystack;
        },
        isBlank(string) {
            return !string || string.trim().length === 0;
        },
        containsAny(source, lookups, minLength = 3) {
            if (source == null || source.trim() === "" || lookups.length === 0)
                return false;

            for (let i = 0; i < lookups.length; i++) {
                let l = lookups[i];
                if (l.trim().length >= minLength) {
                    if (source.toUpperCase().includes(l.trim().toUpperCase()))
                        return true;
                }
            }
            return false;
        }
    };
};

export default StringHelper();
