import React, { useEffect, useState } from 'react';
import i18n from '../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Input, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { loadPage, setGuiasPesquisa } from '../../store/ducks/pageConfig';
import { loadInstitutionData } from '../../store/ducks/instituicao';

const Idiomas = ({ loadInstitutionData, setGuiasPesquisa, ...props }) => {
    const { t } = useTranslation();
    const [languages, setLanguages] = useState([]);
    const [currentLanguage, setCurrentLanguage] = useState(null);

    useEffect(() => {
        const _temp = Object.entries({
            pt: {
                title: 'Português',
                code: 'pt',
                icon: 'ico_br',
            },
            en: {
                title: 'English',
                code: 'en',
                icon: 'ico_en',
            },
            es: {
                title: 'Español',
                code: 'es',
                icon: 'ico_es',
            },
            fr: {
                title: 'Française',
                code: 'fr',
                icon: 'ico_fr',
            },
            it: {
                title: 'Italiano',
                code: 'it',
                icon: 'ico_it',
            },
        })
            .filter((lang, i) => {
                return (
                    Object.keys(i18n.store.data).includes(lang[0]) &&
                    !lang[1].hide
                );
            })
            .map(lang => {
                return lang[1];
            });

        setLanguages(_temp);

        setCurrentLanguage(i18n.language);
    }, []);

    const updateLanguageTo = async lang => {
        //30/01/2023 limpar o storage ao mudar o idioma está deslogando o usuário. Não vi necessidade disso, então comentei pra corrigir o problema.
        //localStorage.clear();
        //sessionStorage.clear();
        //01/06/2023 Descobri que existem itens salvos no storage que mudam de acordo com idioma, então precisa remover especificamente
        sessionStorage.removeItem("info_tabs");
        setGuiasPesquisa(false);

        localStorage.setItem('i18nextLng', lang);
        location.search = `?lng=${lang}`;
    };

    return (
        <div className="row bkcinza2">
            <div className="col-12 p-0">
                <Row className="align-content-center justify-content-center mt-2">
                    <Col md="5" xs="4" className="text-right">
                        <FontAwesomeIcon
                            icon="language"
                            className="s30 preto70"
                            style={{ position: 'relative', top: '5px' }}
                        />
                        <label className="s13 f500 preto70 l10">
                            {t('Idioma')}:{' '}
                        </label>
                    </Col>
                    <Col md="7" xs="8" className="d-md-flex d-none">
                        <div>
                            {languages.map((lang, index) => {
                                return (
                                    <div
                                        className={'d-inline-block'}
                                        key={'lang_btn_' + lang.code}
                                    >
                                        <span
                                            className={'nolink bknone b-none'}
                                            onClick={() =>
                                                updateLanguageTo(lang.code)
                                            }
                                        >
                                            <div
                                                className={classnames(
                                                    'rad50p b-solid b-cinza b-w2 sombra ' +
                                                        lang.icon,
                                                    {
                                                        flag_ativo:
                                                            currentLanguage ===
                                                            lang.code,
                                                    },
                                                )}
                                            />
                                            <label
                                                className={classnames(
                                                    'pad10 preto60 s13 hover1 click f500 link',
                                                    {
                                                        'negrito f900 s14':
                                                            currentLanguage ===
                                                            lang.code,
                                                    },
                                                )}
                                            >
                                                {lang.title}
                                            </label>
                                        </span>
                                        {index < languages.length - 1 && (
                                            <label className="s13 f500 preto50">
                                                |
                                            </label>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </Col>
                    <Col xs="7" className="d-md-none d-inline">
                        <div className="d-md-none d-inline">
                            <Input
                                type="select"
                                className="b-tema20 preto80 s14 mt-2 mb-2"
                                value={currentLanguage || ''}
                                onChange={e => updateLanguageTo(e.target.value)}
                            >
                                {languages.map(lang => (
                                    <option
                                        key={`lang-opt-${lang.code}`}
                                        value={lang.code}
                                    >
                                        {lang.title}
                                    </option>
                                ))}
                            </Input>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
    loadInstitutionData,
    setGuiasPesquisa,
    loadPage,
})(Idiomas);
