import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import rootReducer from './reducers';

//* Para utilizar a extensão do Chrome DevTools:
const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            traceLimit: 25,
        })) ||
    compose;

const middleware = [];

const Store = createStore(
    rootReducer,
    {},
    composeEnhancers(applyMiddleware(...middleware)),
);
const persistor = persistStore(Store);

export { Store, persistor };
