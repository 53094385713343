export const API_URL = `/api`;


/* PESQUISA GERAL */

/**
 * Constante referente ao tipo de busca por 'Codigo de acervo'
 * @type {string}
 */
export const INDICE_BUSCA_POR_COD_ACERVO = 'INDICE_30'
