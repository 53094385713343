import moment from 'moment';

const DataHelper = () => {
    const currentLang = localStorage.getItem('i18nextLng');

    return {
        formataDataLocale(dataStr) {
            return moment(dataStr).locale(currentLang === 'pt' ? 'pt-br' : currentLang).format('L');
        },
        formataDataHoraLocale(dataStr) {
            return moment(dataStr).locale(currentLang === 'pt' ? 'pt-br' : currentLang).format('L LT');
        },
        formataHoraLocale(dataStr) {
            return moment(dataStr).locale(currentLang === 'pt' ? 'pt-br' : currentLang).format('LT');
        }
    };
};

export default DataHelper();
