const NumberHelper = () => {
    return {
        formatCurrency(number, preffix = '', suffix = '', separator = ',') {
            const formatted = Number(number).toFixed(2);
            return `${preffix}${formatted.replace(/\./g, separator)}${suffix}`;
        },
    };
};

export default NumberHelper();
