const Activity = {
    ACCESS_TOKEN_PERSIST: 'ACCESS_TOKEN_PERSIST',
    USER_DATA_PERSIST: 'USER_DATA_PERSIST',
    AUTH_DATA_PERSIST : 'AUTH_DATA_PERSIST'
}

const InitialState = {
    credentials: false,
    user: false
};

export const AuthPersist = {
    storeToken(payload) {
        return { type: Activity.ACCESS_TOKEN_PERSIST, payload }
    },
    storeUserData(payload) {
        return { type: Activity.USER_DATA_PERSIST, payload }
    },
    storeData(payload){
        return {type: Activity.AUTH_DATA_PERSIST, payload}
    },
    logout() {
        return AuthPersist.storeData(InitialState);
    }
};

const authPersist = (state = InitialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case Activity.AUTH_DATA_PERSIST:
            return payload;
        case Activity.ACCESS_TOKEN_PERSIST:
            return { ...state, credentials: payload};
        case Activity.USER_DATA_PERSIST:
            return { ...state, user: payload };
        default:
            return state;
    }
}

export default authPersist;