/*
 *-----------------------------------------------------------------------------------------------------------
 * ACTIONS
 *-----------------------------------------------------------------------------------------------------------
 */
const CHANGE_SUBMENU_SELECTED = "CHANGE_SUBMENU_SELECTED";
const CHANGE_AUTORIDADES = "CHANGE_AUTORIDADES";
const CLEAR_SUBMENU_SELECTED = "CLEAR_SUBMENU_SELECTED";
const CHANGE_DADOS_PROTOCOLO_ARMAZEM = "DADOS_PROTOCOLO_ARMAZEM"

export const changeSubmenuSelected = payload => ({ type: CHANGE_SUBMENU_SELECTED, payload });
export const changeAutoridades = payload => ({ type: CHANGE_AUTORIDADES, payload });
export const clearSubmenuSelected = payload => ({ type: CLEAR_SUBMENU_SELECTED, payload });
export const changeDadosProtocoloArmazem = payload => ({ type: CHANGE_DADOS_PROTOCOLO_ARMAZEM, payload });

/*
 *-----------------------------------------------------------------------------------------------------------
 * ESTADO INICIAL
 *-----------------------------------------------------------------------------------------------------------
 */
const INITIAL_STATE = {
    subMenuSelected: {
        parent: -1,
        submenu: -1,
        name: null
    },
    autoridades: [],
    dadosProtocoloArmazem: null
};

/*
 *-----------------------------------------------------------------------------------------------------------
 * REDUCERS
 *-----------------------------------------------------------------------------------------------------------
 */
const dadosAcervo = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case CHANGE_SUBMENU_SELECTED:
            return { ...state, subMenuSelected: payload }
        case CHANGE_AUTORIDADES:
            return { ...state, autoridades: payload }
        case CLEAR_SUBMENU_SELECTED:
            return {subMenuSelected: { parent: null, submenu: null, name: null } }
        case CHANGE_DADOS_PROTOCOLO_ARMAZEM:
            return { ...state, dadosProtocoloArmazem: payload }
        default:
            return state;
    }
};

export default dadosAcervo;
