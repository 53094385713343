import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Store } from '../../../../store';
import { AuthSession } from '../../../../store/ducks/auth';
import { AuthPersist } from '../../../../store/ducks/authPersist';

const Logout = props => {
    console.log('props', props);

    const redir = () => {
        location.href = process?.env?.APP_URL || '/';
    };

    useEffect(() => {
        if (props && props.userSessionRx) {
            Store.dispatch(AuthSession.logout());
        } else {
            Store.dispatch(AuthPersist.logout());
        }
        redir();
    }, []);

    return <div>...</div>;
};

const mapStateToProps = state => ({
    userSessionRx: state.auth.user,
    userLocalRx: state.authPersist.user,
});

export default connect(mapStateToProps)(Logout);
