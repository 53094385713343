import BaseRequest from "./BaseRequest";
import { API_URL } from "../config/config";

const RedePergamumRequest = () => {
    return {
        buscarDetalhesAcervoThot(codAcervoThot, cancelToken = null) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/rede-pergamum/acervo/${codAcervoThot}`,
                { headers: BaseRequest.publicHeaders(), cancelToken }
            );
        },

        buscarInstituicoesOAI(cancelToken = null) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/rede-pergamum/oai/instituicoes`,
                { headers: BaseRequest.publicHeaders(), cancelToken }
            );
        }
    };
};

export default RedePergamumRequest();
