import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Row,
    Col
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Permissao, APIHelper } from '../../../shared/helpers';
import { Store } from '../../../store';
import { AuthSession } from '../../../store/ducks/auth';
import { AuthPersist } from '../../../store/ducks/authPersist';
import { PessoaAPI } from '../../../shared/http-requests';

const MenuUsuario = ({ userSessionRx, userLocalRx, mobile, history, instituicaoRx }) => {
    const { t } = useTranslation();
    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const user = userSessionRx || userLocalRx;

    const menus = [
        { atividade: 8, icon: 'user', clickAction: () => acessarMeuPergamum() },
    ];

    const toggleUserMenu = () => setUserMenuOpen(!userMenuOpen);

    const logout = () => {
        if (userSessionRx) {
            Store.dispatch(AuthSession.logout());
        } else {
            Store.dispatch(AuthPersist.logout());
        }

        //Se existe nonce oidc na sessão, foi feito login oidc, então pode seguir com logout oidc
        //TODO UNIFICAR, LÓGICA DUPLICADA EM MenuUsuario.jsx, Menu.jsx, UserBadge.jsx
        let nonce = localStorage.getItem("oidc_sessao_nonce");
        if(nonce != null) {
            localStorage.removeItem("oidc_sessao_nonce");
            
            if(instituicaoRx.hasOwnProperty("link_logout_OIDC")){
                if(instituicaoRx.link_logout_OIDC != null){
                    window.open(instituicaoRx.link_logout_OIDC, '_self');
                } else {
                    window.location.reload();
                }
            } else {
                window.location.reload();    
            }
        } else {
            window.location.reload();
        }
    };

    const acessarMeuPergamum = () => {
        const modoMeuPergamum = Number(process.env.REACT_APP_MEU_PERGAMUM_MODE);
        if (modoMeuPergamum === 1) {
            return history.push('/meupergamum');
        }
        return PessoaAPI.geraUrlAcessoMeuPergamum()
            .then(response => window.open(response.data.url, '_blank'))
            .catch(err => APIHelper.tratarErro(err));
    };

    return mobile ? (
        // Mobile
        <div className="d-md-none d-block">
            <Row className='bkbranco py-2'>
                {user ? (
                    <Col xs="12">
                        {user.nome_pessoa && <div className='f700 s14 float-left mt-2'>
                            {`${t('Oi')}, ${user.nome_pessoa.trim().split(" ")[0]}`}
                        </div>}

                        <button className="b-none b-w2 bkbranco hover hrad5 htema p-2 preto70 rad5 s14 float-right" onClick={() => logout()}>
                            <FontAwesomeIcon
                                icon="sign-out-alt"
                                className="r5"
                            />
                            {t('Sair')}
                        </button>

                        {menus
                            .filter(m => Permissao.tem(m.atividade))
                            .map(m => {
                                const atividade = Permissao.busca(m.atividade);

                                return (
                                    <button
                                        key={`menuitem-${m.atividade}`}
                                        className="b-none bktema branco hover hpreto hrad5 mr-3 p-2 rad5 s14 scinza float-right"
                                        onClick={m.clickAction}
                                    >
                                        <FontAwesomeIcon
                                            icon={m.icon}
                                            className="r5"
                                        />
                                        {atividade.descricao}
                                    </button>
                                );
                            })
                        }
                    </Col>
                ) : (
                    <Col xs="12" className='text-center'>
                        <Link
                            to={{
                                pathname: '/login',
                                search: `?redirect=${window.location.pathname + encodeURIComponent(window.location.search)}`,
                            }}
                        >
                            <button className="b-none bkbranco cortxt hover hpreto p-2 rad5 s14">
                                <FontAwesomeIcon
                                    icon="user-lock"
                                    className="r5"
                                />
                                {t('Login')}
                            </button>
                        </Link>
                    </Col>
                )}
            </Row>
        </div>
    ) : ( 
        // Desktop
        <div style={{
                position: "absolute",
                top: "0px",
                right: "0px",
                borderStyle: "none none solid solid",
                borderRadius: "0px 0px 0px 10px"
            }}
            className="py-1 px-4 bklight80 b-w2 b-tema20 preto70"
        >
            {user ? (
                <>
                    {user.nome_pessoa && <span className='f700 mr-3 s14'>
                        {`${t('Oi')}, ${user.nome_pessoa.trim().split(" ")[0]}`}
                    </span>}

                    {menus
                        .filter(m => Permissao.tem(m.atividade))
                        .map(m => {
                            const atividade = Permissao.busca(m.atividade);

                            return (
                                <button
                                    key={`menuitem-${m.atividade}`}
                                    className="b-none bktema branco hover hpreto hrad5 mr-3 p-2 rad5 s14 scinza"
                                    onClick={m.clickAction}
                                >
                                    <FontAwesomeIcon
                                        icon={m.icon}
                                        className="r5"
                                    />
                                    {atividade.descricao}
                                </button>
                            );
                        })
                    }

                    <button className="b-none b-w2 bkbranco hover hrad5 htema p-2 preto70 rad5 s14" onClick={() => logout()}>
                        <FontAwesomeIcon
                            icon="sign-out-alt"
                            className="r5"
                        />
                        {t('Sair')}
                    </button>
                </>
            ) : (
                <Link
                    to={{
                        pathname: '/login',
                        search: `?redirect=${window.location.pathname + encodeURIComponent(window.location.search)}`,
                    }}
                >
                    <button className="b-none bkbranco cortxt hover hpreto p-2 rad5 s14">
                        <FontAwesomeIcon
                            icon="user-lock"
                            className="r5"
                        />
                        {t('Login')}
                    </button>
                </Link>
            )}
        </div>
    );
};

MenuUsuario.defaultProps = {
    mobile: false,
};

MenuUsuario.propTypes = {
    mobile: PropTypes.bool,
};

const mapStateToProps = state => ({
    userSessionRx: state.auth.user,
    userLocalRx: state.authPersist.user,
    instituicaoRx: state.instituicao.data,
});

export default connect(mapStateToProps)(withRouter(MenuUsuario));
