import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Input, Row } from 'reactstrap';
import { APIHelper, ToastifyHelper } from '../helpers';
import { BibliotecaAPI, CampusAPI } from '../http-requests';
import Login from '../../modules/consulta/pages/auth/Login';
import { SpinnerConstructor } from '../components';

const campos = [
    { name: 'autor', label: 'AUTOR', type: 'text', obr: false },
    { name: 'titulo', label: 'TITULO', type: 'text', obr: true },
    { name: 'edicao', label: 'Edicao', type: 'text', obr: false },
    { name: 'editor', label: 'Editor', type: 'text', obr: true },
    { name: 'isbn_issn', label: 'ISBNISSN', type: 'text', obr: false },
    { name: 'periodico', label: 'TituloPeriodico', type: 'text', obr: false },
    {
        name: 'outras_inf',
        label: 'OutrasInformacoes',
        type: 'textarea',
        obr: false,
    },
];
const SugestaoAquisicaoForm = props => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        campus: '-1',
        biblioteca: '-1',
    });
    const [campus, setCampus] = useState(false);
    const [bibliotecas, setBibliotecas] = useState(false);
    const [sending, setSending] = useState(false);

    useEffect(() => {
        let cancelToken = Axios.CancelToken.source();
        CampusAPI.buscarCampus(cancelToken.token)
            .then(response => {
                setCampus(response.data);
                setFormData(fd => ({
                    ...fd,
                    campus: response.data[0].cod_campus,
                }));
            })
            .catch(error => {
                if (!Axios.isCancel(error)) APIHelper.tratarErro(error);
            });
        //? cleanup
        return () => cancelToken.cancel();
    }, []);

    useEffect(() => {
        if (formData.campus !== '-1') {
            let cancelToken = Axios.CancelToken.source();
            BibliotecaAPI.buscarBibliotecas(formData.campus, cancelToken.token)
                .then(response => {
                    setBibliotecas(response.data);
                    if (response.data.length)
                        setFormData(fd => ({
                            ...fd,
                            biblioteca: response.data[0].cod_biblioteca,
                        }));
                    else setFormData(fd => ({ ...fd, biblioteca: '-1' }));
                })
                .catch(error => {
                    if (!Axios.isCancel(error)) APIHelper.tratarErro(error);
                });
            //? cleanup
            return () => cancelToken.cancel();
        }
    }, [formData.campus]);

    const handleFormChange = e => {
        let target = e.target;
        setFormData(fd => {
            let fdAux = { ...fd };
            fdAux[target.name] = target.value;
            return fdAux;
        });
    };

    const enviar = () => {
        if (formData.biblioteca === '-1') {
            ToastifyHelper.warning(
                t('CampoObrigatorioNaoPreenchido', { nome: t('Biblioteca') }),
            );
            return;
        }
        if (!formData.titulo || formData.titulo.trim().length < 1) {
            ToastifyHelper.warning(
                t('CampoObrigatorioNaoPreenchido', {
                    nome: t(campos.filter(c => c.name == 'titulo')[0].label),
                }),
            );
            return;
        }
        if (!formData.editor || formData.editor.trim().length < 1) {
            ToastifyHelper.warning(
                t('CampoObrigatorioNaoPreenchido', {
                    nome: t(campos.filter(c => c.name == 'editor')[0].label),
                }),
            );
            return;
        }

        setSending(true);
        BibliotecaAPI.postarSugestaoAquisicao(formData)
            .then(() => {
                ToastifyHelper.success(t('SugestaoAquisicaoEnviadoSucesso'));
                props.closeModal();
            })
            .catch(e => APIHelper.tratarErro(e))
            .finally(() => setSending(false));
    };

    return (
        <div>
            {props.instituicaoRx.p.__web_aquisicao && (
                <Row className="b-w1 b-cinza2 b-solid-top pb-3 pt-3">
                    <Col
                        md="12"
                        xs="12"
                        className="text-md-right text-center align-self-center"
                    >
                        <a
                            rel="noopener noreferrer"
                            href={props.instituicaoRx.p.__web_url_pw}
                            target="_blank"
                            className="azul s15 f500 link hover hpreto pad5 rad5"
                        >
                            <FontAwesomeIcon icon="link" className="r5" />
                            {t('CliqueAquiSugestaoProfessor')}
                        </a>
                    </Col>
                </Row>
            )}
            {campus && bibliotecas ? (
                <>
                    {
                        <Row className="pb-2 pt-2">
                            <Col
                                md="2"
                                xs="12"
                                className="text-md-right text-center align-self-center"
                            >
                                <span className="vermelho s16 r5">(*)</span>
                                <label className="f400 s13 t5">
                                    {t('SelecionarCampus')}:
                                </label>
                            </Col>
                            <Col
                                md="10"
                                xs="12"
                                className="preto80 align-self-center"
                            >
                                <Input
                                    type="select"
                                    name="campus"
                                    className=" rad10 pad5 cortxt b-tema20 b-solid b-w1 s14"
                                    value={formData.campus}
                                    onChange={e => handleFormChange(e)}
                                    style={{ width: '100%' }}
                                >
                                    <option disabled value="-1">
                                        {t('SelecioneOpcao')}...
                                    </option>
                                    {campus &&
                                        campus.map(c => (
                                            <option
                                                key={`opt-campus-${c.cod_campus}`}
                                                value={c.cod_campus}
                                            >
                                                {c.nome_campus}
                                            </option>
                                        ))}
                                </Input>
                            </Col>
                        </Row>
                    }
                    <Row className="b-w1 b-cinza2 b-solid-top pb-2 pt-2">
                        <Col
                            md="2"
                            xs="12"
                            className="text-md-right text-center align-self-center"
                        >
                            <span className="vermelho s16 r5">(*)</span>
                            <label className="f400 s13 t5">
                                {t('Biblioteca')}:
                            </label>
                        </Col>
                        <Col
                            md="10"
                            xs="12"
                            className="preto80 align-self-center"
                        >
                            <Input
                                type="select"
                                name="biblioteca"
                                className="rad10 pad5 cortxt b-tema20 b-solid b-w1 s14"
                                value={formData.biblioteca}
                                onChange={e => handleFormChange(e)}
                                style={{ width: '100%' }}
                            >
                                <option disabled value="-1">
                                    {t('SelecioneOpcao')}...
                                </option>
                                {bibliotecas &&
                                    bibliotecas.map(b => (
                                        <option
                                            key={`opt-bib-${b.cod_biblioteca}`}
                                            value={b.cod_biblioteca}
                                        >
                                            {b.desc_biblioteca}
                                        </option>
                                    ))}
                            </Input>
                        </Col>
                    </Row>
                    {campos.map(campo => (
                        <Row
                            key={`field-${campo.name}`}
                            className="b-w1 b-cinza2 b-solid-top pl-0 pr-0 pb-2 pt-2"
                        >
                            <Col
                                md="2"
                                xs="12"
                                className="text-md-right text-center align-self-center"
                            >
                                {campo.obr && (
                                    <span className="vermelho s16 r5">(*)</span>
                                )}
                                <label className="f400 s13 t5">
                                    {t(campo.label)}:
                                </label>
                            </Col>
                            <Col
                                md="10"
                                xs="12"
                                className="p-2 preto80 align-self-center"
                            >
                                <Input
                                    value={formData[campo.name] || ''}
                                    type={campo.type}
                                    name={campo.name}
                                    onChange={e => handleFormChange(e)}
                                />
                            </Col>
                        </Row>
                    ))}
                    <Row className="animated fadeIn">
                        <Col md={{ offset: 3, size: 6 }} xs="12">
                            <Login
                                embedded
                                altRender={auth =>
                                    auth ? (
                                        <div className="text-center pb-3 pt-4">
                                            {sending ? (
                                                <div>
                                                    <SpinnerConstructor
                                                        visible
                                                        height={20}
                                                        width={20}
                                                        className="cortxt r10 d-md-inline-block d-block"
                                                    />
                                                    <span className="preto70">
                                                        {t('EnviandoSugestao')}
                                                        ...
                                                    </span>
                                                </div>
                                            ) : (
                                                <Button
                                                    className="bkverde b-none sverde hover htema pad5 branco"
                                                    onClick={() => enviar()}
                                                >
                                                    <FontAwesomeIcon
                                                        icon="paper-plane"
                                                        className="r5"
                                                    />
                                                    {t(
                                                        'EnviarSugestaoAquisicao',
                                                    )}
                                                </Button>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="pt-3 pb-3">
                                            <FontAwesomeIcon
                                                icon="exclamation-circle"
                                                className="laranja s14 r10"
                                            />
                                            <span className="preto70 s14 f500">
                                                {t(
                                                    'RealizeLoginEnviarSugestaoAquisicao',
                                                )}
                                            </span>
                                        </div>
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <div className="p-5 text-center">
                    <SpinnerConstructor
                        visible
                        className="s20 cortxt70"
                        height={100}
                        width={100}
                    />
                </div>
            )}
        </div>
    );
};

export default SugestaoAquisicaoForm;
