/*
 *-----------------------------------------------------------------------------------------------------------
 * ACTIONS
 *-----------------------------------------------------------------------------------------------------------
 */
const INSTITUTION_DATA = "INSTITUTION_DATA";

export const loadInstitutionData = payload => ({type: INSTITUTION_DATA, payload});

const INITIAL_STATE = {
    data : false
}
/*
 *-----------------------------------------------------------------------------------------------------------
 * REDUCERS
 *-----------------------------------------------------------------------------------------------------------
 */
const instituicao = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;
    switch (type) {
        case INSTITUTION_DATA:
            return {...state, data : payload}
        default:
            return state;
    }
}

export default instituicao;