import BaseRequest from './BaseRequest';
import { API_URL } from '../config/config';

const SolicitacaoEmprestimoRequest = () => {
    return {
        buscarInformacoesAcervoSolicitacaoEmprestimo(codAcervo) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/solicitacao-emprestimo/${codAcervo}/informacoes-iniciais`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        gravarSolicitacao(
            codAcervo,
            bibOrigem,
            bibRecebimento,
            volume,
            tomo,
            parte,
            matAdicional,
            telefone,
            email,
            codDepRecebimento,
            pessoaAutorizada,
            domicilio,
            recebimento_textual,
        ) {
            return BaseRequest.axiosInst.post(
                `${API_URL}/solicitacao-emprestimo/${codAcervo}`,
                {
                    biblioteca_origem: bibOrigem || null,
                    biblioteca_recebimento: bibRecebimento || null,
                    volume,
                    tomo,
                    parte,
                    material_adicional: matAdicional,
                    telefone: telefone || null,
                    email: email || null,
                    cod_local_recebimento: codDepRecebimento || null,
                    pessoa_autorizada: pessoaAutorizada || null,
                    domicilio: domicilio || null,
                    recebimento_textual: recebimento_textual || null,
                },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarSolicitacoesEmprestimoUsuario(dataIni, dataFim, status) {
            return BaseRequest.axiosInst.get('/api/solicitacao-emprestimo', {
                headers: BaseRequest.privateHeaders(),
                params: { dataIni, dataFim, status },
            });
        },
    };
};

export default SolicitacaoEmprestimoRequest();
