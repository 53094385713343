import BaseRequest from "./BaseRequest";
import { API_URL } from "../config/config";

const AuthRequest = () => {
    return {
        login(username, password, recaptchaToken) {
            let requestData = {
                grant_type: 'password',
                client_secret: process.env.REACT_APP_PASSWORD_CLIENT_SECRET,
                client_id: process.env.REACT_APP_PASSWORD_CLIENT_ID,
                scope: '',
                username,
                password,
                refresh_token: this.refreshToken()
            };

            if (recaptchaToken) {
                requestData = {
                    ...requestData,
                    recaptcha_token: recaptchaToken
                };
            }

            return BaseRequest.axiosInst.post(
                `/oauth/token`,
                requestData
            );
        },
        getUserData(authToken = null) {
            let headers = authToken
                ? { Authorization: `Bearer ${authToken.credentials.token}` }
                : BaseRequest.publicHeaders();
            return BaseRequest.axiosInst.get(`${API_URL}/pessoas`, {
                headers
            });
        },
        refreshToken() { },
        gerarAutenticacaoOIDC(login, redirect) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/oidc/authorize`,
                { 
                    params: { login, redirect },
                    headers: BaseRequest.publicHeaders()
                }
            );
        },
        validarAutenticacaoOIDC(nonce) {
            return BaseRequest.axiosInst.post(
                `${API_URL}/oidc/validar`,
                { nonce },
                { headers: BaseRequest.publicHeaders() }
            );
        },
    };
};

export default AuthRequest();
