import BaseRequest from './BaseRequest';
import { API_URL } from '../config/config';

const ParametrosRequest = () => {
    return {
        buscarInstituicao() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/parametros/instituicao`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        buscarDadosSecao1() {
            return BaseRequest.axiosInst.get(`${API_URL}/parametros/secao_1`, {
                headers: BaseRequest.publicHeaders(),
            });
        },
        buscaDadosSecao2() {
            return BaseRequest.axiosInst.get(`${API_URL}/parametros/secao_2`, {
                headers: BaseRequest.publicHeaders(),
            });
        },
        buscaFAQ() {
            return BaseRequest.axiosInst.get(`${API_URL}/parametros/faq`, {
                headers: BaseRequest.publicHeaders(),
            });
        },
        buscaInformacoesGerais() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/parametros/informacoes_gerais`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        buscarFuncionalidades() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/parametros/funcionalidades`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        buscarFuncionalidade(codAtividade) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/parametros/atividade/${codAtividade}`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        buscarDadosAtendimento() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/parametros/atendimento`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        buscarDadosAtendimentoCampus(codCampus) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/parametros/atendimento/${codCampus}`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        buscaEventosInstitucionais() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/parametros/eventos_institucionais`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        buscaEventosEventum() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/parametros/eventos_eventum`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        buscaMensagemInformativaPorAtividade(codAtividade) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/parametros/atividade/${codAtividade}/informativo`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        validaAutoatendimento() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/parametros/autoatendimento`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        buscarParametrosAppLayers(){
            return BaseRequest.axiosInst.get(
                `${API_URL}/parametros/app-layers`,
                { headers: BaseRequest.publicHeaders() },
            );
        }
    };
};
export default ParametrosRequest();
