import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/map';
import 'core-js/es/set';
import React from 'react';
import ReactDOM from 'react-dom';
import '../css/app.scss';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import './i18n';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import { PersistGate } from 'redux-persist/integration/react';
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faArrowLeft,
    faArrowRight,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faHourglassStart,
    faHourglassHalf,
    faArrowUp,
    faBars,
    faBan,
    faBell,
    faBold,
    faBookmark,
    faBookOpen,
    faBookReader,
    faBullhorn,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faClock,
    faCog,
    faCogs,
    faDatabase,
    faEraser,
    faExclamationCircle,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faFillDrip,
    faFilter,
    faFlag,
    faHeadset,
    faHistory,
    faImage,
    faInfo,
    faInfoCircle,
    faLanguage,
    faLink,
    faList,
    faListUl,
    faLock,
    faLockOpen,
    faLowVision,
    faMapMarkerAlt,
    faPaperPlane,
    faPlaceOfWorship,
    faPlus,
    faQrcode,
    faQuestion,
    faQuestionCircle,
    faSearch,
    faShare,
    faSave,
    faShoppingBag,
    faShoppingBasket,
    faSignInAlt,
    faSignLanguage,
    faSignOutAlt,
    faSortAmountDown,
    faSortAmountDownAlt,
    faStar,
    faTimes,
    faTimesCircle,
    faToggleOff,
    faToggleOn,
    faTrashAlt,
    faUniversalAccess,
    faUnlockAlt,
    faUser,
    faStreetView,
    faUserCircle,
    faFileAlt,
    faGlobe,
    faArrowsAltH,
    faHandPointUp,
    faHome,
    faSpinner,
    faSitemap,
    faShoppingCart,
    faPercent,
    faCubes,
    faFileSignature,
    faUserFriends,
    faIdCard,
    faRedoAlt,
    faFileInvoice,
    faPrint,
    faExternalLinkAlt,
    faTruck,
    faBook,
    faUserLock,
    faArchive,
    faBuilding,
    faChartBar,
    faChartLine,
    faEnvelope,
    faEnvelopeOpen,
    faUserShield,
    faUserCog,
    faMapSigns
} from '@fortawesome/free-solid-svg-icons';
import {
    faClock as farClock,
    faCommentDots,
    faComments,
    faCircle,
    faDotCircle,
    faFilePdf,
    faFolderOpen,
    faLightbulb,
    faFileWord,
} from '@fortawesome/free-regular-svg-icons';
import {
    faChrome,
    faFirefox,
    faEdge
} from '@fortawesome/free-brands-svg-icons';
import Routers from './routers';
import { persistor, Store } from './store';

const icons = {
    faUser,
    faChevronUp,
    faUniversalAccess,
    faSearch,
    faAngleDown,
    faBars,
    faLowVision,
    faFillDrip,
    faBold,
    faToggleOff,
    faToggleOn,
    faSignLanguage,
    faBookmark,
    faHistory,
    faInfo,
    faBell,
    faExclamationTriangle,
    faQuestion,
    faAngleRight,
    faArrowUp,
    faHeadset,
    faCommentDots,
    faShoppingBag,
    faDatabase,
    faLanguage,
    faClock,
    faBookReader,
    faChevronDown,
    faMapMarkerAlt,
    farClock,
    faEraser,
    faTimesCircle,
    faCircle,
    faPlus,
    faLock,
    faLockOpen,
    faSortAmountDownAlt,
    faSortAmountDown,
    faShoppingBasket,
    faFilter,
    faUserCircle,
    faDotCircle,
    faExclamationCircle,
    faInfoCircle,
    faComments,
    faUnlockAlt,
    faEyeSlash,
    faQuestionCircle,
    faSignInAlt,
    faEye,
    faPlaceOfWorship,
    faArrowRight,
    faChevronRight,
    faChevronLeft,
    faSignOutAlt,
    faCog,
    faCheck,
    faPaperPlane,
    faLink,
    faCheckCircle,
    faAngleLeft,
    faAngleDoubleRight,
    faListUl,
    faBookOpen,
    faList,
    faImage,
    faQrcode,
    faAngleDoubleLeft,
    faTimes,
    faStar,
    faShare,
    faTrashAlt,
    faLightbulb,
    faFilePdf,
    faArrowLeft,
    faBullhorn,
    faFlag,
    faStreetView,
    faFolderOpen,
    faFileAlt,
    faGlobe,
    faArrowsAltH,
    faHandPointUp,
    faHome,
    faSpinner,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faHourglassStart,
    faSitemap,
    faShoppingCart,
    faPercent,
    faCubes,
    faFileSignature,
    faUserFriends,
    faIdCard,
    faBan,
    faRedoAlt,
    faCogs,
    faSave,
    faFileInvoice,
    faPrint,
    faExternalLinkAlt,
    faHourglassHalf,
    faTruck,
    faBook,
    faUserLock,
    faArchive,
    faBuilding,
    faChartBar,
    faChartLine,
    faEnvelope,
    faEnvelopeOpen,
    faUserShield,
    faUserCog,
    faFileWord,
    faChrome,
    faFirefox,
    faEdge,
    faMapSigns
};

library.add(...Object.values(icons));

const App = () => {
    return (
        <Provider store={Store}>
            <PersistGate loading={null} persistor={persistor}>
                <Routers />
            </PersistGate>
        </Provider>
    );
};

ReactDOM.render(<App />, document.getElementById('pergamum'));
