import BaseRequest from './BaseRequest';
import { API_URL } from '../config/config';

const AcervoAPI = () => {
    return {
        buscarReferenciaAcervo(cod_acervo, cancelToken = null) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/acervo/${cod_acervo}/referencia`,
                { headers: BaseRequest.publicHeaders(), cancelToken },
            );
        },
        buscarMarcAcervo(cod_acervo, liberacao = 0, cancelToken = null) {
            const url = `${API_URL}/acervo/${cod_acervo}/marc${
                liberacao ? '?liberacao=1' : ''
            }`;
            return BaseRequest.axiosInst.get(url, {
                headers: BaseRequest.privateOrPublicHeaders(),
                cancelToken,
            });
        },
        buscarDadosAcervo(cod_acervo, cancelToken = null) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/acervo/${cod_acervo}`,
                { headers: BaseRequest.privateOrPublicHeaders(), cancelToken },
            );
        },
        buscaExemplares(cod_acervo, cod_unidade, cancelToken = null) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/acervo/${cod_acervo}/exemplares?cod_unidade=${cod_unidade}`,
                { headers: BaseRequest.publicHeaders(), cancelToken },
            );
        },
        buscarDublinCoreAcervo(cod_acervo, cancelToken = null) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/acervo/${cod_acervo}/dublin-core`,
                { headers: BaseRequest.publicHeaders(), cancelToken },
            );
        },
        buscarLinks(cod_acervo, cancelToken = null) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/acervo/${cod_acervo}/links`,
                { headers: BaseRequest.publicHeaders(), cancelToken },
            );
        },
        gravarAcervosCestaPermanente(acervos) {
            return BaseRequest.axiosInst.post(
                `${API_URL}/acervo/cesta-permanente`,
                { acervos },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        exportarAcervosCesta(
            tipoExportacao,
            dadosExportacao,
            acervos,
            opcoesEmail = null,
        ) {
            return BaseRequest.axiosInst.post(
                `${API_URL}/acervo/exportar-cesta`,
                {
                    tipo: tipoExportacao,
                    dados: dadosExportacao,
                    acervos,
                    opcoesEmail,
                },
                { headers: BaseRequest.publicHeaders() },
            );
        },
        buscarCoresDoAcervo(cod_acervo, cancelToken = null) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/acervo/${cod_acervo}/cor`,
                { headers: BaseRequest.publicHeaders(), cancelToken },
            );
        },
        buscarAcervosCestaPermanenteUsuario(
            tipoBusca,
            dataIni = null,
            dataFim = null,
        ) {
            return BaseRequest.axiosInst.get(
                '/api/acervo/cesta-permanente/colecao',
                {
                    headers: BaseRequest.privateHeaders(),
                    params: { tipoBusca, dataIni, dataFim },
                },
            );
        },
        removerAcervoCestarPermanenteUsuario(codAcervo) {
            return BaseRequest.axiosInst.put(
                '/api/acervo/cesta-permanente',
                { acervo: codAcervo },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarAcervosBasesDadosPessoa() {
            return BaseRequest.axiosInst.get(
                '/api/acervo/meu-pergamum/bases-dados',
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarDisciplinasEBibliotecasPlanoEnsinoUsuario() {
            return BaseRequest.axiosInst.get(
                '/api/acervo/meu-pergamum/plano-ensino/disciplinas-bibliotecas',
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarAcervosBibliografiaPlanoEnsinoUsuario(
            departamento,
            ano,
            semestre,
            periodo,
            disciplina,
            biblioteca,
        ) {
            return BaseRequest.axiosInst.get(
                '/api/acervo/meu-pergamum/plano-ensino/bibliografia',
                {
                    headers: BaseRequest.privateHeaders(),
                    params: {
                        departamento,
                        ano,
                        semestre,
                        periodo,
                        disciplina,
                        biblioteca,
                    },
                },
            );
        },
        buscarPermissaoColecaoKardex(codAcervo, cancelToken) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/kardex/${codAcervo}/permissao`,
                { headers: BaseRequest.publicHeaders(), cancelToken },
            );
        },
        buscarDadosComboboxKardex(codAcervo) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/kardex/${codAcervo}/combobox-data`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        buscarDadosKardex(codAcervo, codPeriod, codBib, codPar, year) {
            const params = { codPeriod, codBib, codPar, year };

            if (year) {
                params.year = year;
            }

            return BaseRequest.axiosInst.get(
                `${API_URL}/kardex/${codAcervo}/data`,
                {
                    headers: BaseRequest.publicHeaders(),
                    params: params,
                },
            );
        },
        buscarDadosItemKardex(codAcervo, seqPar, seqSec, codPar) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/kardex/${codAcervo}/item`,
                {
                    headers: BaseRequest.publicHeaders(),
                    params: { seqPar, seqSec, codPar },
                },
            );
        },
        buscarBibliotecasExemplaresAcervo(codAcervo) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/acervo/${codAcervo}/bibliotecas`,
                {
                    headers: BaseRequest.publicHeaders(),
                },
            );
        },
        buscarTotaisAcervo(codAcervo, dataIni, dataFim, biblioteca) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/acervo/${codAcervo}/totais`,
                {
                    headers: BaseRequest.privateHeaders(),
                    params: { dataIni, dataFim, biblioteca },
                },
            );
        },
        buscarDadosSumario(codAcervo, cancelToken) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/sumario/${codAcervo}/dados`,
                { headers: BaseRequest.publicHeaders(), cancelToken },
            );
        },
        buscarPermissoesMenuDadosAcervo(codAcervo, cancelToken) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/acervo/${codAcervo}/permissoes`,
                { headers: BaseRequest.publicHeaders(), cancelToken },
            );
        },
        buscarDadosEstatisticosAnos(codAcervo){
            return BaseRequest.axiosInst.get(
                `${API_URL}/acervo/${codAcervo}/dados-estatisticos/anos`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        buscarDadosEstatisticosDados(codAcervo,anoSelecionado){
            return BaseRequest.axiosInst.get(
                `${API_URL}/acervo/${codAcervo}/dados-estatisticos/dados/${anoSelecionado}`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        buscarDadosArmazem(codAcervo, cod_biblioteca = null, tomo = null, parte = null, volume = null) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/acervo/${codAcervo}/dados-armazem`,
                {
                    headers: BaseRequest.publicHeaders(),
                    params: {cod_biblioteca, tomo , parte, volume}
                },
            );
        },
        gravarArmazem(cesta, bibliotecaSelecionada){
            return BaseRequest.axiosInst.post(
                `${API_URL}/gravar-armazem`, {cesta},
                {
                    headers: BaseRequest.privateHeaders(),
                    params: {cod_biblioteca: bibliotecaSelecionada}
                },
            );
        }
    };
};

export default AcervoAPI();
