import { Store } from '../../store';

export class Permissao {
    static temTodas(args) {
        return args.reduce((i, a) => {
            return i && Permissao.tem(a);
        }, true);
    }

    static temUma(args) {
        return args.reduce((i, a) => {
            return i || Permissao.tem(a);
        }, false);
    }

    static tem(numero) {
        const state = Store.getState().pageConfig.atividades;
        return (
            state &&
            state.filter(i => Number(i.cod_atividade) === numero).length > 0
        );
    }

    static naoTem(numero) {
        return !this.tem(numero);
    }

    static busca(numero) {
        const state = Store.getState().pageConfig.atividades;
        return state && state.find(i => Number(i.cod_atividade) === numero);
    }

    static temAutenticada(modulo, numero) {
        const state =
            Store.getState().auth.authPermissions ||
            Store.getState().authPersist.authPermissions;
        return (
            state &&
            state.find(
                i =>
                    Number(i.cod_modulo) === modulo &&
                    Number(i.cod_atividade) === numero,
            )
        );
    }

    static descricao(codAtividade, descricaoDefault){
        let valor = this.busca(codAtividade);
        if(!valor)
            return descricaoDefault;
        return valor.descricao;
    }

    static descricaoReduzida(codAtividade, descricaoDefault){
        let valor = this.descricao(codAtividade, descricaoDefault);
        if(!valor)
            return null;
        if(valor.includes("-")){
            valor = valor.substring(valor.indexOf("-")+1).trim();
        }
        return valor;
    }

    static descricaoAutenticada(codModulo, codAtividade, descricaoDefault){
        let valor = this.temAutenticada(codModulo, codAtividade);
        if(!valor)
            return descricaoDefault;
        return valor.descricao;
    }

    static descricaoReduzidaAutenticada(codModulo, codAtividade, descricaoDefault){
        let valor = this.descricaoAutenticada(codModulo, codAtividade, descricaoDefault);
        if(!valor)
            return null;
        if(valor.includes("-")){
            valor = valor.substring(valor.indexOf("-")+1).trim();
        }
        return valor;
    }
}
