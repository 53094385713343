import { remove } from 'diacritics';

export class ArrayHelper {
    /**
     * Filtra os objetos distintos de um array a partir de uma propriedade passada como parâmetro
     * @param {string} prop o nome da propriedade do objeto para usar como argumento de distinção
     * @param {array} array o array de objetos
     */
    static distinctByProperty(prop, array) {
        const result = [];
        const map = new Map();
        for (const item of array) {
            if (!map.has(item[prop])) {
                map.set(item[prop], true); // set any value to Map
                result.push(item);
            }
        }
        return result;
    }

    /**
     * Função auxiliar para passar como argumento em qualquer chamada Array.sort() para ordenar um array de objetos
     * a partir de uma propriedade específica
     * @param {*} key o nome da propriedade do objeto para usar como argumento de ordenação
     * @param {*} order direção da ordenação: D para decrescente e C para crescente
     * @param {*} isNumeric indicação se a propriedade é numerica ou string
     */
    static sortByProperty(key, order, isNumeric) {
        return (a, b) => {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = isNumeric
                ? parseInt(a[key])
                : a[key]
                ? remove(a[key].toUpperCase())
                : null;
            const varB = isNumeric
                ? parseInt(b[key])
                : b[key]
                ? remove(b[key].toUpperCase())
                : null;

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }

            return order === 'D' ? comparison * -1 : comparison;
        };
    }

    /**
     * Agrupa os objetos de um array em um mapa a partir de uma propriedade passada como parâmetro
     * @param {string} prop o nome da propriedade do objeto para usar como argumento de distinção
     * @param {array} array o array de objetos
     */
    static groupByProperty(prop, array) {
        let map = new Map();
        for (const item of array) {
            if (!map.has(item[prop])) {
                let group = array.filter(obj => obj[prop] === item[prop]);
                map.set(item[prop], group); // set any value to Map
            }
        }
        return map;
    }
}
