const URLHelper = () => {
    /**
     ** Método para transformar um form HTML serializado via serialize() em uma
     ** query string com os parâmetros com os valores do form.
     */
    return {
        generateQueryFromForm(form) {
            return Object.keys(form)
                .filter(f => form[f])
                .map(i => {
                    let val = encodeURIComponent(form[i]);
                    return `${i}=${val}`;
                })
                .join("&");
        },
        generateQueryFromFormExcluding(form, excluding) {
            return Object.keys(form)
                .filter(f => form[f])
                .filter(f => !excluding.includes(f))
                .map(i => {
                    let val = encodeURIComponent(form[i]);
                    return `${i}=${val}`;
                })
                .join("&");
        },
    };
};

export default URLHelper();
