import BaseRequest from './BaseRequest';
import { API_URL } from '../config/config';

const ComentariosRequest = () => {
    return {
        buscarComentarios(codAcervo, cancelToken) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/comentarios/acervo/${codAcervo}`,
                { headers: BaseRequest.publicHeaders(), cancelToken },
            );
        },
        enviarComentario(codAcervo, comentario) {
            return BaseRequest.axiosInst.post(
                `${API_URL}/comentarios/acervo/${codAcervo}`,
                comentario,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarQuantidadesComentariosAcervosUsuario() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/comentarios/usuario/acervos`,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarComentariosPorAcervoUsuario(codAcervo) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/comentarios/usuario/acervo/${codAcervo}`,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        excluirComentarioAcervoUsuario(idComentario) {
            return BaseRequest.axiosInst.delete(
                `${API_URL}/comentarios/usuario/${idComentario}`,
                { headers: BaseRequest.privateHeaders() },
            );
        },
    };
};

export default ComentariosRequest();
