import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Idiomas from './Idiomas';
import logoSvg from '../../../images/pergamum.svg';
import logoSvgAlto from '../../../images/pergamumAlto.svg';
import { Permissao } from '../helpers';
import ModalConstructor from './ModalConstructor';
import ComentarioForm from './ComentarioForm';
import SugestaoAquisicaoForm from './SugestaoAquisicaoForm';

class Footer extends React.Component {
    constructor() {
        super();
        this.state = {
            modal: { open: false, content: null, title: null },
        };
        this.permissaoSugestao = Permissao.busca(12);
        this.permissaoComentarios = Permissao.busca(13);
    }

    getModalContent() {
        const {
            modal: { content },
        } = this.state;
        const { instituicaoRx } = this.props;
        switch (content) {
            case 'C':
                return <ComentarioForm />;
            case 'S':
                return <SugestaoAquisicaoForm instituicaoRx={instituicaoRx} />;
            case 'SP':
                return (
                    <Row className="mb-3">
                        <Col>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: instituicaoRx.msg_suporte_consulta,
                                }}
                            />
                        </Col>
                    </Row>
                );
            default:
                return null;
        }
    }

    render() {
        const { modal } = this.state;
        const { instituicaoRx, showLinks, t } = this.props;
        const msgSuporte =
            instituicaoRx.msg_suporte_consulta || t('msg_suporte_consulta');

        return (
            <footer className="b-w1 b-solid-top b-cinza">
                <ModalConstructor
                    closeable
                    closeAction
                    size="xl"
                    title={modal.title}
                    open={modal.open}
                    setOpen={() =>
                        this.setState({
                            modal: {
                                open: false,
                                content: null,
                                title: null,
                            },
                        })
                    }
                >
                    {this.getModalContent()}
                </ModalConstructor>
                {showLinks && (
                    <div className="container text-center pad20 preto80">
                        <div className="d-md-flex d-block justify-content-center">
                            {this.permissaoComentarios && (
                                <Col
                                    md="2"
                                    className="text-md-center text-left b-cinza2 pt-md-0 pt-2"
                                    style={{
                                        borderStyle: 'solid',
                                        borderWidth: '0px 1px 0px 1px',
                                    }}
                                >
                                    <span
                                        onClick={() =>
                                            this.setState({
                                                modal: {
                                                    open: true,
                                                    content: 'C',
                                                    title: this
                                                        .permissaoComentarios
                                                        .descricao,
                                                },
                                            })
                                        }
                                        className="hover click link hpreto pad5 rad5 preto70 text-md-center s12"
                                    >
                                        <FontAwesomeIcon
                                            icon={['far', 'comment-dots']}
                                            className="r5"
                                        />
                                        {this.permissaoComentarios.descricao}
                                    </span>
                                </Col>
                            )}
                            {this.permissaoSugestao && (
                                <Col
                                    md="3"
                                    className="text-md-center text-left b-cinza2 pt-md-0 pt-2"
                                    style={{
                                        borderStyle: 'solid',
                                        borderWidth: '0px 1px 0px 1px',
                                    }}
                                >
                                    <span
                                        onClick={() =>
                                            this.setState({
                                                modal: {
                                                    open: true,
                                                    content: 'S',
                                                    title: this
                                                        .permissaoSugestao
                                                        .descricao,
                                                },
                                            })
                                        }
                                        className="hover click link hpreto pad5 rad5 preto70 text-md-center s12"
                                    >
                                        <FontAwesomeIcon
                                            icon="shopping-bag"
                                            className="r5"
                                        />
                                        {this.permissaoSugestao.descricao}
                                    </span>
                                </Col>
                            )}
                            {Permissao.tem(57) && (
                                <Col
                                    md="2"
                                    className="text-md-center text-left b-cinza2 pt-md-0 pt-2"
                                    style={{
                                        borderStyle: 'solid',
                                        borderWidth: '0px 1px 0px 1px',
                                    }}
                                >
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://www.pergamum.pucpr.br/icap"
                                        className="hover hpreto pad5 rad5 preto70 s12 click link"
                                    >
                                        <FontAwesomeIcon
                                            icon="database"
                                            className="r5"
                                        />
                                        ICAP
                                    </a>
                                </Col>
                            )}
                            {Permissao.tem(87) && (
                                <Col
                                    md="3"
                                    className="text-md-center text-left b-cinza2 pt-md-0 pt-2"
                                    style={{
                                        borderStyle: 'solid',
                                        borderWidth: '0px 1px 0px 1px',
                                    }}
                                >
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://www.pergamum.pucpr.br/redepergamum/consultas/site_CRP/pesquisa.php"
                                        className="hover hpreto pad5 rad5 preto70 s12 click link"
                                    >
                                        <FontAwesomeIcon
                                            icon="database"
                                            className="r5"
                                        />
                                        {t('CatalogoRedePergamum')}
                                    </a>
                                </Col>
                            )}
                        </div>
                    </div>
                )}
                <Idiomas />
                <div className="container">
                    <Row>
                        <Col className="col b-tema10 b-w3 b-solid-top" />
                        <Col className="col b-tema30 b-w3 b-solid-top" />
                        <Col className="col b-tema50 b-w3 b-solid-top" />
                        <Col className="col b-tema80 b-w3 b-solid-top" />
                        <Col className="col b-tema b-w3 b-solid-top" />
                    </Row>
                </div>

                <div className="row preto80 s13 f500 bkbranco align-content-center justify-content-center">
                    {Permissao.tem(88) && (
                        <div className="col-sm-6 col-md-4 col-lg-1 p-md-1 text-center d-none d-md-block b-cinza align-self-center">
                            <a
                                href="https://www.pergamum.pucpr.br"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={logoSvg}
                                    style={{ maxWidth: 140 }}
                                    alt="Logotipo do Pergamum"
                                    className="contrasteNao"
                                />
                                <img
                                    src={logoSvgAlto}
                                    style={{ maxWidth: 140 }}
                                    alt="Logotipo do Pergamum"
                                    className="contrasteSim"
                                />
                            </a>
                        </div>
                    )}

                    <div className="col-sm-6 col-md-4 col-lg p-2 p-md-3 f500 align-self-center text-center text-lg-left">
                        <span
                            className="b-w1 b-solid-left b-cinza r20 d-none d-lg-block float-left"
                            style={{ height: 20 }}
                        />
                        ©{new Date().getFullYear()}.{' '}
                        <a className=" f900 negrito link safira-color text">
                            Pergamum
                        </a>{' '}
                        .{t('TodosDireitosReservados')}.
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 align-self-center f500 text-center">
                        <span className='d-block s10 preto50'>
                            {t('NavegadoresVersoesMinimas')}:
                        </span>
                        <span
                            className='f900 s12 preto70 d-block'
                        >  
                            <span title='Chrome'>
                                <FontAwesomeIcon
                                    icon={['fab', 'chrome']}
                                    className="s15 mr-1 verde"
                                />
                                104.0.0.1
                            </span>
                            <span title='Firefox'>
                                <FontAwesomeIcon
                                    icon={['fab', 'firefox']}
                                    className="s15 mx-1 laranja"
                                />
                                110.0.1
                            </span>
                            <span title='Edge'>
                                <FontAwesomeIcon
                                    icon={['fab', 'edge']}
                                    className="s15 mx-1 azul"
                                />
                                110.0.0.1
                            </span>
                        </span>
                    </div>

                    {instituicaoRx.link_lgpd &&
                        instituicaoRx.link_lgpd.length > 0 && (
                            <div className="col-sm-6 col-md-4 col-lg-3 mt-2 mt-md-0 p-2 p-md-3 align-self-center text-center">
                                <a
                                    href={instituicaoRx.link_lgpd}
                                    target="_blank"
                                    className="nolink"
                                    rel="noreferrer"
                                >
                                    <span className="p-1 rad5 f500 preto70 bknone link b-none hover hpreto s12">
                                        <FontAwesomeIcon
                                            icon="user-shield"
                                            className="s13 mr-1"
                                        />
                                        <span>
                                            {t('PrivacidadeProtecaoDeDados')}
                                        </span>
                                    </span>
                                </a>
                            </div>
                        )}

                    {msgSuporte && msgSuporte.length > 0 && (
                        <div className="col-sm-6 col-md-4 col-lg-1 mt-2 mt-md-0 p-1 align-self-center text-center">
                            <button
                                type="button"
                                className="bktema branco b-none stema hover hverde pad5 rad5 mt-md-1"
                                onClick={() =>
                                    this.setState({
                                        modal: {
                                            open: true,
                                            content: 'SP',
                                            title: t('Suporte'),
                                        },
                                    })
                                }
                            >
                                <FontAwesomeIcon
                                    icon="headset"
                                    className="r5"
                                />
                                {t('Suporte')}
                            </button>
                        </div>
                    )}
                </div>
            </footer>
        );
    }
}

const mapStateToProps = state => ({
    instituicaoRx: state.instituicao.data,
});

export default connect(mapStateToProps)(withTranslation()(Footer));
