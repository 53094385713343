const LOAD_PUBLIC_CREDENTIALS = "LOAD_PUBLIC_CREDENTIALS"

export const loadPublicCredentials = payload => ({type: LOAD_PUBLIC_CREDENTIALS, payload});

const INITIAL_STATE = {
    credentials : false
}

const publicCredentials = (state = INITIAL_STATE, action) => {
    const {type, payload} = action;
    switch (type) {
        case LOAD_PUBLIC_CREDENTIALS:
            return {...state, credentials : payload}
        default:
            return state;
    }
}

export default publicCredentials;