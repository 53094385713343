/* eslint-disable default-param-last */
/* eslint-disable no-case-declarations */
/*
 *-----------------------------------------------------------------------------------------------------------
 * ACTIONS
 *-----------------------------------------------------------------------------------------------------------
 */
const SET_ALERTS = 'SET_ALERTS';
const REMOVE_ALERT = 'REMOVE_ALERT';
const UPDATE_ALERT_READ = 'UPDATE_ALERT_READ';

export const setarAlertas = payload => ({ type: SET_ALERTS, payload });

export const removeAlerta = payload => ({ type: REMOVE_ALERT, payload });

export const atualizaLidoAlerta = payload => ({
    type: UPDATE_ALERT_READ,
    payload,
});

/*
 *-----------------------------------------------------------------------------------------------------------
 * INITIAL STATE
 *-----------------------------------------------------------------------------------------------------------
 */
const InitialState = {
    alertas: null,
};
/*
 *-----------------------------------------------------------------------------------------------------------
 * REDUCERS
 *-----------------------------------------------------------------------------------------------------------
 */
const alertas = (state = InitialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_ALERTS:
            return { ...state, alertas: payload };
        case REMOVE_ALERT:
            const newAlertas = state.alertas.filter(a => {
                return a.id !== payload;
            });
            return { ...state, alertas: newAlertas };
        case UPDATE_ALERT_READ:
            return {
                ...state,
                alertas: state.alertas.map(a => {
                    if (a.id === payload) {
                        a.lido = true;
                    }
                    return a;
                }),
            };
        default:
            return state;
    }
};

export default alertas;
