import BaseRequest from './BaseRequest';

const EmprestimoRequest = () => {
    return {
        buscarTitulosPendentesUsuario(showDetails = 0) {
            return BaseRequest.axiosInst.get(
                `api/emprestimo/titulos-pendentes`,
                {
                    headers: BaseRequest.privateHeaders(),
                    params: { showDetails },
                },
            );
        },
        renovarEmprestimosExemplares(exemplares) {
            return BaseRequest.axiosInst.post(
                'api/emprestimo/renovacao',
                exemplares,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarHistoricoEmprestimoUsuario(dataInicial, dataFinal, flagMulta) {
            return BaseRequest.axiosInst.get('api/emprestimo/historico', {
                params: {
                    dataIni: dataInicial,
                    dataFim: dataFinal,
                    multa: flagMulta,
                },
                headers: BaseRequest.privateHeaders(),
            });
        },
        buscarDebitosAtivosUsuario() {
            return BaseRequest.axiosInst.get(
                'api/emprestimo/debitos-pendentes',
                {
                    headers: BaseRequest.privateHeaders(),
                },
            );
        },
        buscarTokenAtivoEmprestimoUsuario() {
            return BaseRequest.axiosInst.get('api/emprestimo/token', {
                headers: BaseRequest.privateHeaders(),
            });
        },
        gerarNovoTokenEmprestimoUsuario() {
            return BaseRequest.axiosInst.post(
                'api/emprestimo/token',
                {},
                {
                    headers: BaseRequest.privateHeaders(),
                },
            );
        },
    };
};

export default EmprestimoRequest();
