import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './shared/i18n/en';
import pt from './shared/i18n/pt';
import es from './shared/i18n/es';

// the translations
// (tip move them in a JSON file and import them)
const resources = { pt, en, es };

if (!localStorage.getItem('i18nextLng'))
    localStorage.setItem('i18nextLng', process.env.DEFAULT_LANGUAGE || 'pt');

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        ns: ['translation'],
        detection: {
            order: ['localStorage', 'navigator'],
            lookupQuerystring: 'lng',
            lookupLocalStorage: 'i18nextLng',
        },
        fallbackLng: process.env.DEFAULT_LANGUAGE || 'pt',
        resources,
        returnEmptyString: false,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
