import React from "react"
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { useTranslation } from "react-i18next";

const SpinnerConstructor = (props) => {

    const { t } = useTranslation();

    return (
        props.visible ? (
            <div className={props.className} role="status" style={props.style || {}}>
                <Spinner type={props.grow ? 'grow' : 'border'} style={{ height: props.height || 50, width: props.width || 50 }} />
                {props.showLabel &&
                    <div className="t20">
                        <label className="preto70 f300 s20">{t('Carregando')}</label>
                    </div>
                }
            </div>
        ) : (null)
    )
}

SpinnerConstructor.propTypes = {
    grow: PropTypes.bool,
    visible: PropTypes.bool,
    containerClassName: PropTypes.string,
    className: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    showLabel: PropTypes.bool
}

export default SpinnerConstructor