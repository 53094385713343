import BaseRequest from './BaseRequest';

const PerfilInteresseRequest = () => {
    return {
        buscarInformacoesAuxiliaresAreasInteresse() {
            return BaseRequest.axiosInst.get(
                `api/perfil-interesse/areas-interesse/info-auxiliar`,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarAreasAutoresAssuntosDSI(tipoPesquisa, termoPesquisa) {
            return BaseRequest.axiosInst.get(
                `api/perfil-interesse/area-autor-assunto/pesquisa`,
                {
                    headers: BaseRequest.privateHeaders(),
                    params: { tipoPesquisa, termoPesquisa },
                },
            );
        },
        gravarItensAreasAutoresAssuntosDSI(tipoDSI, validade, itens) {
            return BaseRequest.axiosInst.post(
                '/api/perfil-interesse/area-autor-assunto',
                { tipoDSI, validade, itens },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarItensSalvosAreasAutoresAssuntosDSI() {
            return BaseRequest.axiosInst.get(
                'api/perfil-interesse/area-autor-assunto',
                { headers: BaseRequest.privateHeaders() },
            );
        },
        excluirItemSalvoAreaAutorAssuntoDSI(codigo, tipoDSI, codTipoArea) {
            return BaseRequest.axiosInst.delete(
                'api/perfil-interesse/area-autor-assunto',
                {
                    headers: BaseRequest.privateHeaders(),
                    data: { codigo, tipoDSI, codTipoArea },
                },
            );
        },
        atualizarValidadeItemSalvoAreaAutorAssuntoDSI(
            codigo,
            tipoDSI,
            codTipoArea,
            validade,
        ) {
            return BaseRequest.axiosInst.put(
                'api/perfil-interesse/area-autor-assunto/validade',
                { codigo, tipoDSI, codTipoArea, validade },
                {
                    headers: BaseRequest.privateHeaders(),
                },
            );
        },
        buscarPeriodicosDSI(termoPesquisa, bibliotecas) {
            return BaseRequest.axiosInst.get(
                `api/perfil-interesse/periodicos/pesquisa`,
                {
                    headers: BaseRequest.privateHeaders(),
                    params: { termoPesquisa, bibliotecas },
                },
            );
        },
        gravarItensPeriodicosDSI(itens, validade) {
            return BaseRequest.axiosInst.post(
                '/api/perfil-interesse/periodicos',
                { validade, itens },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarItensSalvosPeriodicosDSI() {
            return BaseRequest.axiosInst.get(
                'api/perfil-interesse/periodicos',
                { headers: BaseRequest.privateHeaders() },
            );
        },
        atualizarItemSalvoPeriodicoDSI(
            codigo,
            validade,
            fasciculo,
            artigo,
            sumario,
            bibliotecas,
        ) {
            return BaseRequest.axiosInst.put(
                'api/perfil-interesse/periodicos',
                { codigo, validade, fasciculo, artigo, sumario, bibliotecas },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        excluirItemSalvoPeriodicoDSI(codigo) {
            return BaseRequest.axiosInst.delete(
                'api/perfil-interesse/periodicos',
                { headers: BaseRequest.privateHeaders(), data: { codigo } },
            );
        },
        gravarItensTiposObraDSI(validade, itens) {
            return BaseRequest.axiosInst.post(
                '/api/perfil-interesse/tipo-obra',
                { validade, itens },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarItensSalvosTiposObraDSI() {
            return BaseRequest.axiosInst.get(
                '/api/perfil-interesse/tipo-obra',
                { headers: BaseRequest.privateHeaders() },
            );
        },
        atualizarItemSalvoTipoObraDSI(codigo, validade, bibliotecas, idiomas) {
            return BaseRequest.axiosInst.put(
                'api/perfil-interesse/tipo-obra',
                { codigo, validade, bibliotecas, idiomas },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        excluirItemSalvoTipoObraDSI(codigo) {
            return BaseRequest.axiosInst.delete(
                'api/perfil-interesse/tipo-obra',
                { headers: BaseRequest.privateHeaders(), data: { codigo } },
            );
        },
        buscarTitulosDSIUsuario() {
            return BaseRequest.axiosInst.get('api/perfil-interesse/titulos', {
                headers: BaseRequest.privateHeaders(),
            });
        },
    };
};

export default PerfilInteresseRequest();
