import axios from 'axios';
import { ObjectFlags } from 'typescript';
import { Store } from '../../store';
import interceptor from './interceptor/AuthInterceptor';

const createAxiosInstance = () => {
    let instance = axios.create({
        baseURL: '',
        headers: {
            post: { Accept: 'application/json' },
        },
    });
    instance.interceptors.response.use(undefined, interceptor(instance));
    return instance;
};

const BaseRequest = () => {
    const _axiosInst = createAxiosInstance();

    return {
        axiosInst: _axiosInst,
        async getPublicCredentials() {
            let req = await _axiosInst.post(`/oauth/token`, {
                grant_type: 'client_credentials',
                client_id: params.client_id,
                client_secret: params.client_secret,
                scope: '',
            });
            return await req.data;
        },
        get publicHeaders() {
            let auth = {};
            let publicCredentials =
                Store.getState().publicCredentials.credentials;
            if (publicCredentials)
                auth['Authorization'] = `Bearer ${publicCredentials.token}`;
            return () => ({
                lang: this.language,
                ...auth,
            });
        },
        get language() {
            let language = localStorage.getItem('i18nextLng');
            let lang;
            switch (language) {
                case 'en':
                    lang = 5;
                    //lang = 2;
                    break;
                case 'es':
                    lang = 3;
                    break;
                default:
                    lang = 1;
                    break;
            }
            return lang;
        },
        get privateHeaders() {
            let state = Store.getState();
            let privateCredentials = state.auth.credentials
                ? state.auth.credentials
                : state.authPersist.credentials;

            if (!privateCredentials) return null;
            let auth = { Authorization: `Bearer ${privateCredentials.token}` };
            let ret = {
                lang: this.language,
                ...auth,
            };
            return () => ret;
        },
        get privateOrPublicHeaders() {
            let auth = {};
            let state = Store.getState();
            let privateCredentials = state.auth.credentials
                ? state.auth.credentials
                : state.authPersist.credentials;

            if (!privateCredentials) {
                let publicCredentials = state.publicCredentials.credentials;
                if (publicCredentials)
                    auth = {
                        Authorization: `Bearer ${publicCredentials.token}`,
                    };
            } else {
                auth = { Authorization: `Bearer ${privateCredentials.token}` };
            }
            let ret = {
                lang: this.language,
                ...auth,
            };
            return () => ret;
        },
        async refreshToken() {
            let state = Store.getState();

            let privateCredentials =
                state.auth.credentials || state.authPersist.credentials;

            let req = await _axiosInst.post(`/oauth/token`, {
                grant_type: 'refresh_token',
                client_id: process.env.REACT_APP_PASSWORD_CLIENT_ID,
                client_secret: process.env.REACT_APP_PASSWORD_CLIENT_SECRET,
                scope: '',
                refresh_token: privateCredentials.refresh,
            });
            return await req.data;
        },
        toQueryString(params) {
            const u = new URLSearchParams();
            Object.entries(params).forEach((p, i) => {
                u.append(p[0], p[1]);
            });
            return u.toString();
        },
    };
};

export default BaseRequest();
