import BaseRequest from "./BaseRequest";
import { API_URL } from "../config/config";

const AutoridadeAPI = () => {
    return {
        buscarDetalhesAutoridade(codAutoridade, cancelToken = null) {
            const options = { headers: BaseRequest.publicHeaders(), cancelToken };
            return BaseRequest.axiosInst.get(
                `${API_URL}/autoridade/${codAutoridade}`,
                options
            );
        },
        buscarDetalhesAutoridadeCombo: (autoridades, cancelToken = null) => {
            const options = { headers: BaseRequest.publicHeaders(), params: {autoridades}, cancelToken };
            return BaseRequest.axiosInst.get(
                `${API_URL}/autoridade/combo`,
                options
            );
        }
    };
};

export default AutoridadeAPI();
