import BaseRequest from './BaseRequest';
import { API_URL } from '../config/config';

const BibliotecaAPI = () => {
    return {
        buscarBibliotecas(campus = null, cancelToken = null) {
            return BaseRequest.axiosInst.get(`${API_URL}/unidade-informacao`, {
                params: {
                    campus,
                },
                headers: BaseRequest.publicHeaders(),
                cancelToken,
            });
        },
        postarComentarioGeral(data) {
            return BaseRequest.axiosInst.post(
                `${API_URL}/unidade-informacao/comentario`,
                data,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        postarSugestaoAquisicao(formData) {
            return BaseRequest.axiosInst.post(
                `${API_URL}/unidade-informacao/sugestao-aquisicao`,
                formData,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        gerarGRUDebitoBiblioteca(valor, codBiblioteca) {
            return BaseRequest.axiosInst.post(
                `${API_URL}/unidade-informacao/gru`,
                { valor, codBiblioteca },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        verificaHabilitaDeclaracaoNadaConsta() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/unidade-informacao/nada-consta/verifica`,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarDadosDeclaracaoNadaConstaUsuario() {
            return BaseRequest.axiosInst.post(
                `${API_URL}/unidade-informacao/nada-consta`,
                {},
                { headers: BaseRequest.privateHeaders() },
            );
        },
    };
};

export default BibliotecaAPI();
