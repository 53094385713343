import React from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class ToastifyHelper {
    static info(message, options = {}) {
        toast(
            <div className="p-3 s20">
                <FontAwesomeIcon icon="info-circle" className="branco r10" />
                {message}
            </div>,
            { ...options, type: toast.TYPE.INFO },
        );
    }

    static warning(message, options = {}) {
        toast(
            <div className="p-3 s20">
                <FontAwesomeIcon
                    icon="exclamation-triangle"
                    className="branco r10"
                />
                {message}
            </div>,
            { ...options, type: toast.TYPE.WARNING },
        );
    }

    static success(message, options = {}) {
        toast(
            <div className="p-3 s20">
                <FontAwesomeIcon icon="check-circle" className="branco r10" />
                {message}
            </div>,
            { ...options, type: toast.TYPE.SUCCESS },
        );
    }

    static error(message, options = {}) {
        toast(
            <div className="p-3 s20">
                <FontAwesomeIcon icon="times-circle" className="branco r10" />
                {message}
            </div>,
            { ...options, type: toast.TYPE.ERROR },
        );
    }
}
