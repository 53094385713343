import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ModalConstructor = (props) => {
    let closeProp = props.closeAction ? { closeModal : () => props.setOpen(!props.open)} : {};
    return (
        <div>
            <Modal
                modalClassName={props.instituicaoRx.tema || ''}
                isOpen={props.open}
                toggle={props.closeable ? () => props.setOpen(!props.open) : undefined}
                size={props.size || "md"}
                scrollable
                backdrop={props.closeable ? true : 'static'}
                keyboard={props.closeable}
                centered={!props.closeable}
                onClosed={props.onClosed}
            >
                {props.title &&
                    <ModalHeader
                        style={{padding:"1rem 1rem 10px 1rem"}}
                        className="b-solid-bottom b-branco cortxt negrito"
                        close={props.closeable ?
                            <button className="close s20 branco float-right mr-1 rad5" style={{fontWeight:"normal"}} onClick={() => props.setOpen(!props.open)}><FontAwesomeIcon icon="times" className="fa-fw" /></button> : null}
                        toggle={props.closeable ? () => props.setOpen(!props.open) : undefined}>
                        <div className="f700 branco s20 ">
                            {props.title || ""}
                        </div>
                    </ModalHeader>
                }
                <ModalBody style={{padding:0}}>
                    {props.children && React.cloneElement(props.children, closeProp)}
                </ModalBody>
                {props.footer && (
                    <ModalFooter>
                        {props.footer}
                        {/*<Button color="primary">Do Something</Button>{' '}
                        <Button color="secondary">Cancel</Button>*/}
                    </ModalFooter>
                )}
            </Modal>
        </div>
    )
}

const mapStateToProps = state => ({
    instituicaoRx : state.instituicao.data
})

ModalConstructor.propTypes = {
    closeable: PropTypes.bool,
    closeAction: PropTypes.bool,
    size: PropTypes.string,
    title: PropTypes.string,
    footer: PropTypes.any,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    onClosed: PropTypes.func
}


export default connect(mapStateToProps)(ModalConstructor);
