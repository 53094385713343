import BaseRequest from '../BaseRequest';
import { Store } from '../../../store';
import { loadPublicCredentials } from '../../../store/ducks/publicCredentials';
import { AuthPersist } from '../../../store/ducks/authPersist';
import { AuthSession } from '../../../store/ducks/auth';
import { ToastifyHelper } from '../../helpers';
import i18next from 'i18next';
import Axios from 'axios';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

const interceptor = axiosInstance => error => {
    const _axios = axiosInstance;

    const originalRequest = error.config;
    const store = Store.getState();

    if (Axios.isCancel(error)) return Promise.reject(error);

    console.error('Error', error);

    if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest.url.endsWith('/oauth/token') &&
        !originalRequest._retry
    ) {
        const failedToken =
            originalRequest.headers.Authorization.split(' ').pop();

        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                failedQueue.push({ resolve, reject });
            })
                .then(token => {
                    originalRequest.headers['Authorization'] =
                        'Bearer ' + token;
                    return _axios.request(originalRequest);
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        }

        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise((resolve, reject) => {
            if (failedToken === store.publicCredentials.credentials.token) {
                //? Se for token público que expirou
                BaseRequest.getPublicCredentials()
                    .then(data => {
                        Store.dispatch(
                            loadPublicCredentials({
                                token: data.access_token,
                            }),
                        );
                        originalRequest.headers['Authorization'] =
                            'Bearer ' + data.access_token;
                        processQueue(null, data.access_token);
                        resolve(_axios(originalRequest));
                    })
                    .catch(err => {
                        processQueue(err, null);
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            } else {
                let isPersistedAuth = !!store.authPersist.credentials;
                //? Se for token privado que expirou, usa o refresh_token
                BaseRequest.refreshToken()
                    .then(data => {
                        let newToken = {
                            token: data.access_token,
                            refresh: data.refresh_token,
                        };
                        Store.dispatch(
                            isPersistedAuth
                                ? AuthPersist.storeToken(newToken)
                                : AuthSession.storeToken(newToken),
                        );
                        originalRequest.headers['Authorization'] =
                            'Bearer ' + newToken.token;
                        processQueue(null, newToken.token);
                        resolve(_axios(originalRequest));
                    })
                    .catch(err => {
                        //? Se o refresh_token estiver expirado, pede login novamente
                        ToastifyHelper.warning(i18next.t('SessaoExpirada'));
                        if (isPersistedAuth) {
                            Store.dispatch(AuthPersist.logout());
                        } else {
                            Store.dispatch(AuthSession.logout());
                        }
                        processQueue(err, null);
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            }
        });
    }
    return Promise.reject(error);
};

export default interceptor;
