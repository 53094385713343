import BaseRequest from './BaseRequest';
import { API_URL } from '../config/config';
import { Store } from '../../store';
import UAParser from 'ua-parser-js';

const VinculosRequest = () => {
    return {
        buscarImagensAcervo(codAcervo, limiteImagens = 0, cancelToken = null) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/vinculos/imagem?cod_acervo=${codAcervo}&limite=${limiteImagens}`,
                {
                    headers: BaseRequest.publicHeaders(),
                    cancelToken: cancelToken,
                },
            );
        },
        buscarLinksAcervos(acervos) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/vinculos/links-resultado`,
                {
                    params: { acervos: acervos },
                    headers: BaseRequest.publicHeaders(),
                },
            );
        },
        acessarLinkAcessoRestrito(codAcervo, seqParagrafo, loginUsu) {
            let uaParser = new UAParser().getResult();
            return BaseRequest.axiosInst.post(
                `${API_URL}/vinculos/acesso-restrito/${codAcervo}/s/${seqParagrafo}`,
                {
                    userId: loginUsu,
                    browser: uaParser.browser.name,
                    device: uaParser.device.type || 'desktop',
                    os: `${uaParser.os.name} ${uaParser.os.version}`,
                },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        gravarEstatisticaAcessoVinculo(codAcervo, seqParagrafo, url) {
            let store = Store.getState();
            let user = store.auth.user || store.authPersist.user;
            let uaParser = new UAParser().getResult();

            return BaseRequest.axiosInst.post(
                `${API_URL}/vinculos/estatistica-acesso`,
                {
                    cod_acervo: codAcervo,
                    seq_paragrafo: seqParagrafo,
                    url: url,
                    user_id: user ? user.cod_pessoa : null,
                    browser: uaParser.browser.name,
                    device: uaParser.device.type || 'desktop',
                    os: `${uaParser.os.name} ${uaParser.os.version}`,
                },
                { headers: BaseRequest.publicHeaders() },
            );
        },
        acessarLinkAbnt(normaId) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/vinculos/abnt/${normaId}`,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        acessarNormaTarget(normaId) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/vinculos/target/${normaId}`,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        acessarLinkGrupoA(sessionid) {
            return BaseRequest.axiosInst.post(
                `${API_URL}/vinculos/grupoa`,
                { sessionid },
                { headers: BaseRequest.publicHeaders() },
            );
        }
    };
};

export default VinculosRequest();
