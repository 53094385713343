import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import ModalConstructor from './ModalConstructor';

export const ConfirmationModal = ({
    isOpen,
    setOpen,
    titulo,
    content,
    onConfirmar,
    onCancelar,
    labelConfirmar,
    labelCancelar,
}) => {
    const { t } = useTranslation();

    return (
        <ModalConstructor
            closeable
            open={isOpen}
            setOpen={setOpen}
            title={titulo || t('Confirmacao')}
            onClosed={() => onCancelar()}
        >
            <div className="p-4">
                {content}
                <div className="txt-center mt-5">
                    <Button
                        type="button"
                        onClick={() => onConfirmar()}
                        className="branco mx-3 bkverde pad5 rad5 hover hpreto click sverde b-none f500 d-inline-block"
                    >
                        <FontAwesomeIcon icon="check" className="fa-fw mr-1" />
                        {labelConfirmar || t('Confirmar')}
                    </Button>
                    <Button
                        type="button"
                        onClick={() => onCancelar()}
                        className="branco mx-2 bkvermelho pad5 rad5 hover hpreto click svermelho b-none f500 d-inline-block"
                    >
                        <FontAwesomeIcon icon="times" className="fa-fw mr-1" />
                        {labelCancelar || t('Cancelar')}
                    </Button>
                </div>
            </div>
        </ModalConstructor>
    );
};
