import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Input, Row } from 'reactstrap';
import { APIHelper, ToastifyHelper } from '../helpers';
import { BibliotecaAPI, CampusAPI } from '../http-requests';
import Login from '../../modules/consulta/pages/auth/Login';
import { SpinnerConstructor } from '../components';

const ComentarioForm = props => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        campus: '-1',
        biblioteca: '-1',
        comentario: '',
    });
    const [campus, setCampus] = useState(false);
    const [bibliotecas, setBibliotecas] = useState(false);
    const [sending, setSending] = useState(false);

    const [msg, setMsg] = useState('');

    useEffect(() => {
        let cancelToken = Axios.CancelToken.source();

        CampusAPI.buscarCampus(cancelToken.token)
            .then(response => {
                setCampus(response.data);
                setFormData(fd => ({
                    ...fd,
                    campus: response.data[0].cod_campus,
                }));
                setMsg('');
            })
            .catch(error => {
                if (!Axios.isCancel(error)) APIHelper.tratarErro(error);
            });

        //? cleanup
        return () => cancelToken.cancel();
    }, []);

    useEffect(() => {
        if (formData.campus !== '-1') {
            let cancelToken = Axios.CancelToken.source();

            BibliotecaAPI.buscarBibliotecas(formData.campus, cancelToken.token)
                .then(response => {
                    setBibliotecas(response.data);
                    if (response.data.length)
                        setFormData(fd => ({
                            ...fd,
                            biblioteca: response.data[0].cod_biblioteca,
                        }));
                    else setFormData(fd => ({ ...fd, biblioteca: '-1' }));
                })
                .catch(error => {
                    if (!Axios.isCancel(error)) APIHelper.tratarErro(error);
                });

            //? cleanup
            return () => cancelToken.cancel();
        }
    }, [formData.campus]);

    const handleFormChange = e => {
        let target = e.target;
        setFormData(fd => {
            let fdAux = { ...fd };
            fdAux[target.name] = target.value;
            return fdAux;
        });
    };

    const enviar = () => {
        if (
            formData.biblioteca === '-1' ||
            !formData.comentario ||
            formData.comentario.trim().length < 1
        )
            return;

        setSending(true);
        setMsg('');
        BibliotecaAPI.postarComentarioGeral({
            content: formData.comentario.trim(),
            b: formData.biblioteca,
        })
            .then(() => {
                ToastifyHelper.success(t('ComentarioGeralEnviadoSucesso'));
                props.closeModal();
            })
            .catch(e => {
                if (e.response.status == 422)
                    setMsg(t('limite_comentarios_excedido'));
                else APIHelper.tratarErro(e);
            })
            .finally(() => setSending(false));
    };

    return (
        <div>
            {campus ? (
                <>
                    <Row className="b-w1 b-cinza2 b-solid-top pb-3 pt-4">
                        <Col md="2" xs="12">
                            <label className="f400 r10 t5">
                                {t('SelecionarCampus')}:
                            </label>
                        </Col>
                        <Col md="10" xs="12" className="preto80">
                            <Input
                                type="select"
                                name="campus"
                                className=" rad10 pad5 cortxt b-tema20 b-solid b-w1 s14"
                                value={formData.campus}
                                onChange={e => handleFormChange(e)}
                                style={{ width: '100%' }}
                            >
                                <option disabled value="-1">
                                    {t('SelecioneOpcao')}...
                                </option>
                                {campus &&
                                    campus.map(c => (
                                        <option
                                            key={`opt-campus-${c.cod_campus}`}
                                            value={c.cod_campus}
                                        >
                                            {c.nome_campus}
                                        </option>
                                    ))}
                            </Input>
                        </Col>
                    </Row>
                    <Row className="b-w1 b-cinza2 b-solid-top pb-3 pt-4">
                        <Col md="2" xs="12">
                            <label className="f400 r10 t5">
                                {t('Biblioteca')}:
                            </label>
                        </Col>
                        <Col md="10" xs="12" className="preto80">
                            <Input
                                type="select"
                                name="biblioteca"
                                className="rad10 pad5 cortxt b-tema20 b-solid b-w1 s14"
                                value={formData.biblioteca}
                                onChange={e => handleFormChange(e)}
                                style={{ width: '100%' }}
                            >
                                <option disabled value="-1">
                                    {t('SelecioneOpcao')}...
                                </option>
                                {bibliotecas &&
                                    bibliotecas.map(b => (
                                        <option
                                            key={`opt-bib-${b.cod_biblioteca}`}
                                            value={b.cod_biblioteca}
                                        >
                                            {b.desc_biblioteca}
                                        </option>
                                    ))}
                            </Input>
                        </Col>
                    </Row>
                    <Row className="b-w1 b-cinza2 b-solid-top pl-0 pr-0 pb-2 pt-4">
                        <Col md="12" xs="12" className="p-2 preto80">
                            <Input
                                type="textarea"
                                name="comentario"
                                rows="5"
                                onChange={e => handleFormChange(e)}
                                placeholder={t('PlaceholderComentario')}
                            />
                        </Col>
                    </Row>
                    <Row className="animated fadeIn">
                        <Col md={{ offset: 3, size: 6 }} xs="12">
                            <Login
                                embedded
                                altRender={auth =>
                                    auth ? (
                                        <div className="text-center pb-3 pt-4">
                                            {sending ? (
                                                <div>
                                                    <SpinnerConstructor
                                                        visible
                                                        height={20}
                                                        width={20}
                                                        className="cortxt r10 d-md-inline-block d-block"
                                                    />
                                                    <span className="preto70">
                                                        {t(
                                                            'EnviandoComentario',
                                                        )}
                                                        ...
                                                    </span>
                                                </div>
                                            ) : (
                                                <Button
                                                    className="bkverde b-none sverde hover htema pad5 branco"
                                                    onClick={() => enviar()}
                                                    disabled={
                                                        !(
                                                            formData.comentario
                                                                .length &&
                                                            formData.biblioteca !==
                                                                '-1'
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon="paper-plane"
                                                        className="r5"
                                                    />
                                                    {t('EnviarComentario')}
                                                </Button>
                                            )}
                                            {msg && (
                                                <span className="text-warning">
                                                    {msg}
                                                </span>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="pt-3 pb-3">
                                            <FontAwesomeIcon
                                                icon="exclamation-circle"
                                                className="laranja s14 r10"
                                            />
                                            <span className="preto70 s14 f500">
                                                {t(
                                                    'RealizeLoginEnviarComentario',
                                                )}
                                            </span>
                                        </div>
                                    )
                                }
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <div className="p-5 text-center">
                    <SpinnerConstructor
                        visible
                        className="s20 cortxt70"
                        height={100}
                        width={100}
                    />
                </div>
            )}
        </div>
    );
};

export default ComentarioForm;
