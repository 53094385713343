import BaseRequest from './BaseRequest';
import { API_URL } from '../config/config';

const ReservaRequest = () => {
    return {
        buscarInformacoesIniciaisReserva(codAcervo) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/reserva/${codAcervo}/informacoes-iniciais`,
                { headers: BaseRequest.publicHeaders() },
            );
        },
        gravarReserva(
            codAcervo,
            biblioteca,
            volume,
            tomo,
            parte,
            matAdicional,
            localizacao,
        ) {
            return BaseRequest.axiosInst.post(
                `${API_URL}/reserva`,
                {
                    cod_acervo: codAcervo,
                    biblioteca,
                    volume,
                    tomo,
                    parte,
                    material_adicional: matAdicional,
                    localizacao,
                },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        validarFilaReserva(
            codAcervo,
            biblioteca,
            volume,
            tomo,
            parte,
            matAdicional,
            localizacao,
        ) {
            return BaseRequest.axiosInst.post(
                `${API_URL}/reserva/validar-fila`,
                {
                    cod_acervo: codAcervo,
                    biblioteca,
                    volume,
                    tomo,
                    parte,
                    material_adicional: matAdicional,
                    localizacao,
                },
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarReservasUsuario() {
            return BaseRequest.axiosInst.get('api/reserva', {
                headers: BaseRequest.privateHeaders(),
            });
        },
        cancelarReservasUsuario(acervos) {
            return BaseRequest.axiosInst.delete(`api/reserva`, {
                data: acervos,
                headers: BaseRequest.privateHeaders(),
            });
        },
        buscarHistoricoReservasUsuario(dataInicial, dataFinal) {
            return BaseRequest.axiosInst.get('api/reserva/historico', {
                params: { dataIni: dataInicial, dataFim: dataFinal },
                headers: BaseRequest.privateHeaders(),
            });
        },
    };
};

export default ReservaRequest();
