import BaseRequest from './BaseRequest';
import { API_URL } from '../config/config';

const AquisicaoRequest = () => {
    return {
        buscarSituacoesPedidoSugestaoAquisicao() {
            return BaseRequest.axiosInst.get(
                `${API_URL}/aquisicao/sugestoes/situacao`,
                {
                    headers: BaseRequest.publicHeaders(),
                },
            );
        },
        buscarSugestoesAquisicaoUsuario(dataIni, dataFim, situacao) {
            return BaseRequest.axiosInst.get(`${API_URL}/aquisicao/sugestoes`, {
                headers: BaseRequest.privateHeaders(),
                params: { dataIni, dataFim, situacao },
            });
        },
        buscarEstatisticasAquisicao(biblioteca, dataIni, dataFim) {
            return BaseRequest.axiosInst.get(
                `${API_URL}/aquisicao/estatisticas`,
                {
                    headers: BaseRequest.privateHeaders(),
                    params: { dataIni, dataFim, biblioteca },
                },
            );
        },
    };
};

export default AquisicaoRequest();
