import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Checkbox(props) {
    let checkedIcon = props.checkedIcon || ['fas', 'toggle-on'],
        emptyIcon = props.emptyIcon || ['fas', 'toggle-off'];

    return (
        <label
            className={
                'd-inline-block ' +
                (props.checked
                    ? 'branco s30 rad5 r5'
                    : 'branco50 click s30 rad5 r5')
            }
        >
            <input
                type="checkbox"
                style={{ display: 'none' }}
                onChange={props.onChange}
                checked={props.checked}
            />
            <FontAwesomeIcon
                icon={props.checked ? checkedIcon : emptyIcon}
                fixedWidth={true}
            />
        </label>
    );
}

export default Checkbox;
