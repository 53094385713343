import { ToastifyHelper } from './';
import i18next from 'i18next';

export class APIHelper {
    static tratarErro(erro) {
        if (!erro.response) {
            //Erro de conexao com o servidor
            if (erro.message === 'Network Error')
                ToastifyHelper.error(
                    i18next.t('NaoFoiPossivelComunicarServidor'),
                );
        } else {
            //Erro de processamento
            if (erro.response.status !== 500) {
                //Se for status 422, busca a mensagem de erro do arquivo de internacionalização
                let msg =
                    erro.response.status === 422
                        ? i18next.t(erro.response.data.message)
                        : erro.response.data.message;
                ToastifyHelper.warning(msg);
            } else if(erro.response.data.message=='Ocorreu um erro inesperado ao consultar o Catálogo da Rede Pergamum!'){
                ToastifyHelper.warning('Serviço Rede Pergamum não disponível!');
            }else if (erro.response.data.message.indexOf("minhabibliotecaerro") !== -1) {
                ToastifyHelper.error(i18next.t('MinhaBibliotecaEmail'));
            }else{
                ToastifyHelper.error(i18next.t('OcorreuErroInesperado'));
            }
        }
    }
}
