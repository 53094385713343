import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import sessionStorage from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import {
    authReducer,
    authPersistReducer,
    cestaReducer,
    instituicaoReducer,
    pageConfigReducer,
    pesquisaReducer,
    publicCredentialsReducer,
    alertasReducer,
    dadosAcervoReducer,
} from '../ducks';

const encryptor = createEncryptor({
    secretKey: process.env.REACT_APP_REDUX_PERSIST_ENC_KEY,
    onError: error => console.error('Encryption error: ' + error),
});

const pageConfigPersistConfig = {
    key: 'page__config',
    storage: storage,
    whitelist: ['atividades', 'guiasPesquisa', 'meuPergamum'],
    transforms: [encryptor],
    stateReconciler: autoMergeLevel2,
};

const institutionPersistConfig = {
    key: 'institution',
    storage: sessionStorage,
    transforms: [encryptor],
};

const publicCredentialsPersistConfig = {
    key: 'public__credentials',
    storage,
    transforms: [encryptor],
};

const authSessionPersistConfig = {
    key: 'auth__session',
    storage: sessionStorage,
    transforms: [encryptor],
    stateReconciler: autoMergeLevel2,
};

const authPersistConfig = {
    key: 'auth__',
    storage,
    transforms: [encryptor],
    stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
    pageConfig: persistReducer(pageConfigPersistConfig, pageConfigReducer),
    instituicao: persistReducer(institutionPersistConfig, instituicaoReducer),
    publicCredentials: persistReducer(
        publicCredentialsPersistConfig,
        publicCredentialsReducer,
    ),
    auth: persistReducer(authSessionPersistConfig, authReducer),
    authPersist: persistReducer(authPersistConfig, authPersistReducer),
    cesta: cestaReducer,
    pesquisa: pesquisaReducer,
    alertas: alertasReducer,
    dadosAcervo: dadosAcervoReducer
});

export default rootReducer;
