import BaseRequest from './BaseRequest';

const SolicitacoesRequest = () => {
    return {
        gravarSolicitacaoComutacaoBibliografica(
            tipoSolicitacao,
            dadosFormulario,
        ) {
            return BaseRequest.axiosInst.post(
                `api/solicitacoes/comutacao-bibliografica/${tipoSolicitacao}`,
                dadosFormulario,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarSolicitacoesUsuarioPorTipo(tipoSolicitacao, status) {
            return BaseRequest.axiosInst.get(
                `api/solicitacoes/${tipoSolicitacao}`,
                { headers: BaseRequest.privateHeaders(), params: { status } },
            );
        },
        gravarSolicitacaoLevantamentoBibliografico(dadosFormulario) {
            return BaseRequest.axiosInst.post(
                'api/solicitacoes/levantamento-bibliografico',
                dadosFormulario,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        gravarSolicitacaoFichaCatalografica(dadosFormulario) {
            return BaseRequest.axiosInst.post(
                'api/solicitacoes/ficha-catalografica',
                dadosFormulario,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        gravarSolicitacaoPesquisaEspecializada(dadosFormulario) {
            return BaseRequest.axiosInst.post(
                'api/solicitacoes/pesquisa-especializada',
                dadosFormulario,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        buscarAlertasSolicitacoesUsuario(dataIni, dataFim) {
            return BaseRequest.axiosInst.get('api/solicitacoes/alertas', {
                headers: BaseRequest.privateHeaders(),
                params: { dataFim, dataIni },
            });
        },
        marcarLidoAlertaSolicitacao(idAlerta) {
            return BaseRequest.axiosInst.put(
                `api/solicitacoes/alertas/${idAlerta}`,
                {},
                {
                    headers: BaseRequest.privateHeaders(),
                },
            );
        },
        excluirAlertaSolicitacaoUsuario(idAlerta) {
            return BaseRequest.axiosInst.delete(
                `api/solicitacoes/alertas/${idAlerta}`,
                {
                    headers: BaseRequest.privateHeaders(),
                },
            );
        },
        buscarDadosEmprestimoInterBibliotecas() {
            return BaseRequest.axiosInst.get(
                `api/solicitacoes/emprestimo-inter-bibliotecas/dados`,
                { headers: BaseRequest.privateHeaders() },
            );
        },
        gravarEmprestimoInterBibliotecas(dadosFormulario) {
            return BaseRequest.axiosInst.post(
                'api/solicitacoes/emprestimo-inter-bibliotecas',
                dadosFormulario,
                { headers: BaseRequest.privateHeaders() },
            );
        },
    };
};

export default SolicitacoesRequest();
