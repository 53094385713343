import { StringHelper, ArrayHelper } from './';
import i18next from 'i18next';
import { Store } from '../../store';
import { changeAcervosFiltrados } from '../../store/ducks/pesquisa';

/**
 * Mapeamento codigo/filtro
 * @type {{ANO: string, CURSO: string, MATERIAL_COMPLEMENTAR: string, SEMESTRE: string, PERIODO: string, DISCIPLINA: string, PROFESSOR: string, MATERIAL_BASICO: string}}
 */
const FILTROS = {
    CURSO: 'U',
    DISCIPLINA: 'D',
    ANO: 'N',
    SEMESTRE: 'M',
    PERIODO: 'O',
    PROFESSOR: 'F',
    MATERIAL_BASICO: 'L',
    MATERIAL_COMPLEMENTAR: 'G'
};



export class FiltrosHelper {
    static montarFiltros(guiaPesquisa, filtros, consulta, acervosFiltrados) {
        let consultaFiltrada = [];

        if (acervosFiltrados.length > 0) {
            if (guiaPesquisa === 'rede_pergamum_oai') {
                consultaFiltrada = consulta.filter(acervo =>
                    acervosFiltrados.some(a => a === acervo.id),
                );
            } else {
                consultaFiltrada = consulta.filter(acervo =>
                    acervosFiltrados.some(a => a === acervo.cod_acervo),
                );
            }
        } else consultaFiltrada = consulta;

        switch (guiaPesquisa) {
            case 'pesquisa_geral':
            case 'e_books':
            case 'arquivo':
            case 'atos_normativos':
            case 'autoridades':
            case 'pesquisa_avancada':
            case 'acessibilidade':
            case 'novas_aquisicoes':
            case 'plano_ensino':
                FiltrosHelper._montarFiltrosPesquisaGeral(
                    filtros,
                    consulta,
                    consultaFiltrada
                );
                break;
            case 'rede_pergamum_oai':
                FiltrosHelper._montarFiltrosPesquisaOAIGeral(
                    filtros,
                    consulta,
                );
                break;
            default:
                break;
        }
    }

    static filtrarResultados(guiaPesquisa, filtrosSelecionados, consulta) {
        let acervosFiltrados = [];
        if (filtrosSelecionados.length > 0) {
            switch (guiaPesquisa) {
                case 'pesquisa_geral':
                case 'e_books':
                case 'arquivo':
                case 'atos_normativos':
                case 'autoridades':
                case 'pesquisa_avancada':
                case 'acessibilidade':
                case 'plano_ensino':
                    acervosFiltrados = FiltrosHelper.filtrarPesquisaGeralANDCondition(
                        filtrosSelecionados,
                        consulta,
                    );
                    break;
                case 'novas_aquisicoes':
                    //Filtra com os filtros adicionais de novas aquisiçoes caso tenha selecionado
                    if (
                        filtrosSelecionados.some(fs => fs.tipo.includes('NA_'))
                    ) {
                        consulta = FiltrosHelper._filtrarNovasAquisicoes(
                            filtrosSelecionados.filter(fs =>
                                fs.tipo.includes('NA_'),
                            ),
                            consulta,
                        );
                    }
                    acervosFiltrados = FiltrosHelper.filtrarPesquisaGeralANDCondition(
                        filtrosSelecionados,
                        consulta,
                    );
                    break;
                case 'rede_pergamum_oai':
                    acervosFiltrados = FiltrosHelper._filtrarOAIGeral(
                        filtrosSelecionados,
                        consulta,
                    );
                    break;
                default:
                    break;
            }
        }
        Store.dispatch(changeAcervosFiltrados(acervosFiltrados));
    }

    static _montarFiltrosPesquisaGeral(filtros, consulta, acervosFiltrados) {
        filtros.forEach(f => (f.contagem = []));
        //Varre os acervos do resultado, montando os totais dos filtros
        consulta.forEach(acervo => {
            filtros.forEach(filtro => {
                const exists = acervosFiltrados.some(af => af.cod_acervo  == acervo.cod_acervo);
                switch (filtro.codigo) {
                    //? Os filtros abaixo são filtrados da mesma maneira pois vêm com o mesmo padrão de mapeamento junto com o resultado da consulta
                    case 'B':
                    case 'P':
                    case 'A':
                    case 'S':
                    case 'C':
                    case 'E':
                    case FILTROS.CURSO:
                    case FILTROS.PROFESSOR:
                    case FILTROS.ANO:
                    case FILTROS.PERIODO:
                    case FILTROS.DISCIPLINA:
                    case FILTROS.MATERIAL_BASICO:
                    case FILTROS.MATERIAL_COMPLEMENTAR:
                    case FILTROS.SEMESTRE:
                        FiltrosHelper._montarFiltroDadosAdicionaisGeral(
                            filtro.codigo,
                            filtro.contagem,
                            acervo,
                            exists
                        );
                        break;
                    case 'T':
                        FiltrosHelper._montarFiltroTipoObraGeral(
                            filtro.contagem,
                            acervo,
                            exists
                        );
                        break;
                    case 'Y':
                        FiltrosHelper._montarFiltroAnoPublicacaoGeral(
                            filtro.contagem,
                            acervo,
                            exists
                        );
                        break;
                    case 'X':
                        FiltrosHelper._montarFiltroSomenteComGeral(
                            filtro.contagem,
                            acervo,
                            exists
                        );
                        break;
                    case 'I':
                        FiltrosHelper._montarFiltroIdiomaGeral(
                            filtro.contagem,
                            acervo,
                            exists
                        );
                        break;
                    case 'R':
                        FiltrosHelper._montarFiltroRepositoriosArquivo(
                            filtro.contagem,
                            acervo,
                            exists
                        );
                        break;
                    case 'K':
                        FiltrosHelper._montarFiltroClassificacaoArquivo(
                            filtro.contagem,
                            acervo,
                            exists
                        );
                        break;
                    default:
                        break;
                }
            });
        });

        //Ordena a contagem dos itens dos filtros
        filtros.forEach(filtro => {
            switch (filtro.codigo) {
                case 'B':
                 //   filtro.contagem.sort(
                   //     ArrayHelper.sortByProperty('codigo', 'C', true),
                   // );
                   // break;
                case 'T':
                case 'X':
                case 'P':
                case 'A':
                case 'S':
                case 'C':
                case 'E':
                case 'R':
                case 'K':
                    filtro.contagem.sort(
                        ArrayHelper.sortByProperty('descricao', 'C', false),
                    );
                    break;
                case 'Y':
                    filtro.contagem.sort(
                        ArrayHelper.sortByProperty('codigo', 'D', true),
                    );
                    break;
                default:
                    break;
            }
        });
    }

    static _montarFiltrosPesquisaOAIGeral(filtros, consulta) {
        filtros.forEach(f => (f.contagem = []));
        //Varre os acervos do resultado, montando os totais dos filtros
        consulta.forEach(acervo => {
            filtros.forEach(filtro => {
                switch (filtro.codigo) {
                    case 'Z':
                        FiltrosHelper._montarFiltroInstituicaoOAI(
                            filtro.contagem,
                            acervo,
                        );
                        break;
                    case 'T':
                        FiltrosHelper._montarFiltroTipoObraOAI(
                            filtro.contagem,
                            acervo,
                        );
                        break;
                    case 'Y':
                        FiltrosHelper._montarFiltroAnoPublicacaoGeral(
                            filtro.contagem,
                            acervo,
                        );
                        break;
                    case 'I':
                        FiltrosHelper._montarFiltroIdiomaGeral(
                            filtro.contagem,
                            acervo,
                        );
                        break;
                    default:
                        break;
                }
            });
        });

        //Ordena a contagem dos itens dos filtros
        filtros.forEach(filtro => {
            switch (filtro.codigo) {
                case 'Z':
                case 'I':
                case 'T':
                    filtro.contagem.sort(
                        ArrayHelper.sortByProperty('descricao', 'C', false),
                    );
                    break;
                case 'Y':
                    filtro.contagem.sort(
                        ArrayHelper.sortByProperty('codigo', 'D', true),
                    );
                    break;
                default:
                    break;
            }
        });
    }

    static _montarFiltroDadosAdicionaisGeral(tipoFiltro, contagem, acervo, exists) {
        if (acervo.dados_adicionais && acervo.dados_adicionais.hasOwnProperty(tipoFiltro)) {
            acervo.dados_adicionais[tipoFiltro].forEach(dado => {
                let dadoContagem;

                if (['A', 'E', 'S'].includes(tipoFiltro))
                    //? filtro série, autor e assunto contabiliza com código e descrição
                    dadoContagem = contagem.find(
                        d =>
                            d.codigo === dado.codigo &&
                            d.descricao === dado.descricao,
                    );
                else
                    dadoContagem = contagem.find(d => d.codigo === dado.codigo);

                if(exists){
                    if (!dadoContagem){
                        contagem.push({
                            codigo: dado.codigo,
                            descricao: dado.descricao,
                            total: 1,
                        });
                    }
                    else dadoContagem.total++;
                }else{
                    if(dadoContagem) return;

                    contagem.push({
                        codigo: dado.codigo,
                        descricao: dado.descricao,
                        total: 0,
                    });

                }
            });
        }
    }

    static _montarFiltroTipoObraGeral(tiposObra, acervo, exists) {
        if (!acervo.cod_tipo_obra) return;
        const tObra = tiposObra.find(f => f.codigo === acervo.cod_tipo_obra);

        if(exists){
            if (!tObra){
                tiposObra.push({
                    codigo: acervo.cod_tipo_obra,
                    descricao: StringHelper.replaceAll(
                        acervo.desc_tipo_obra,
                        ['(', ')'],
                        '',
                    ).trim(),
                    total: 1,
                    totalSemFiltro: 1
                });
                return;
            }
            tObra.total++;
            tObra.totalSemFiltro++;

        }else{
            if(tObra)  {
                tObra.mostrarQtdOriginal = true;
                tObra.totalSemFiltro++;
                return
            }

            tiposObra.push({
                codigo: acervo.cod_tipo_obra,
                descricao: StringHelper.replaceAll(acervo.desc_tipo_obra, ['(', ')'], '',).trim(),
                total: 0,
                totalSemFiltro: 1,
                mostrarQtdOriginal: true
            });
        }

    }

    static _montarFiltroAnoPublicacaoGeral(anos, acervo, exists) {
        const anoAux = acervo.ano_publicacao || null;
        const ano = anos.find(a => a.codigo === anoAux);
        
         try{
            if (ano && ano.descricao === null) {
                 ano.descricao = i18next.t('SemData');
                 ano.codigo = null;
             }
         } catch(error) {
             console.error(error);
         }

        if(exists){
            if (!ano){
                anos.push({ codigo: anoAux, descricao: anoAux, total: 1, totalSemFiltro: 1 });
                return;
            }
            ano.total++;
            ano.totalSemFiltro++;
        }else{
            if(ano){
                ano.totalSemFiltro++;
                return;
            }
            anos.push({ codigo: anoAux, descricao: anoAux, total: 0, totalSemFiltro: 1, mostrarQtdOriginal: true})
        }


    }

    static _montarFiltroSomenteComGeral(opcoes, acervo, exists) {
        let possuiCapa = !!acervo.link_capa || false;
        let ehOnline = acervo.acesso_remoto === 'cr';
        let possuiAcessibilidade = acervo.possui_acessibilidade === 'S';

        if (possuiCapa) {
            let filtroCapa = opcoes.find(o => o.codigo === 'C');
            if(exists){
                if (!filtroCapa){
                    opcoes.push({
                        codigo: 'C',
                        descricao: i18next.t('Capa'),
                        total: 1,
                        totalSemFiltro: 1
                    });
                }else{
                    filtroCapa.total++;
                    filtroCapa.totalSemFiltro++;
                }
            }else{
                if(!filtroCapa){
                    opcoes.push({
                        codigo: 'C',
                        descricao: i18next.t('Capa'),
                        total: 0,
                        totalSemFiltro: 1
                    });
                }else{
                    filtroCapa.totalSemFiltro++;
                }
            }
        }

        if (ehOnline) {
            let filtroOnline = opcoes.find(o => o.codigo === 'O');

            if(exists){
                if (!filtroOnline)
                    opcoes.push({
                        codigo: 'O',
                        descricao: i18next.t('Online'),
                        total: 1,
                        totalSemFiltro: 1
                    });
                else{
                    filtroOnline.total++;
                    filtroOnline.totalSemFiltro++;
                }
            }else{
                if(!filtroOnline){
                    opcoes.push({
                        codigo: 'O',
                        descricao: i18next.t('Online'),
                        total: 0,
                        totalSemFiltro: 1
                    });
                }else{
                    filtroOnline.totalSemFiltro++;
                }
            }
        }

        if (possuiAcessibilidade) {
            let filtroAcessibilidade = opcoes.find(o => o.codigo === 'A');
            if(exists){
                if (!filtroAcessibilidade)
                    opcoes.push({
                        codigo: 'A',
                        descricao: i18next.t('Acessibilidade'),
                        total: 1,
                        totalSemFiltro: 1
                    });
                else{
                    filtroAcessibilidade.total++;
                    filtroAcessibilidade.totalSemFiltro++;
                }
            }else{
                if(filtroAcessibilidade){
                    filtroAcessibilidade.totalSemFiltro++;
                    return;
                }

                opcoes.push({
                    codigo: 'A',
                    descricao: i18next.t('Acessibilidade'),
                    total: 0,
                    totalSemFiltro: 1
                });
            }
        }
    }

    static _montarFiltroIdiomaGeral(idiomas, acervo, exists) {
        let possuiIdioma = acervo.idioma && acervo.idioma_desc;

        if (possuiIdioma) {
            let filtroIdioma = idiomas.find(i => i.codigo === acervo.idioma);
            if(exists){
                if (!filtroIdioma) {
                    idiomas.push({
                        codigo: acervo.idioma,
                        descricao: acervo.idioma_desc,
                        total: 1,
                        totalSemFiltro:  1
                    });
                } else{
                    filtroIdioma.total++;
                    filtroIdioma.totalSemFiltro++;
                }
            }else{
                if(filtroIdioma){
                    filtroIdioma.totalSemFiltro++;
                    return;
                }

                idiomas.push({
                    codigo: acervo.idioma,
                    descricao: acervo.idioma_desc,
                    total: 0,
                    totalSemFiltro: 1
                });
            }

        }
    }

    static _montarFiltroRepositoriosArquivo(repositorios, acervo, exists) {
        let possuiRepositorio =
            acervo.cod_tipo_area_conhec && acervo.desc_tipo_area_conhec;

        if (possuiRepositorio) {
            let filtroRepositorio = repositorios.find(
                r => r.codigo === acervo.cod_tipo_area_conhec,
            );

            if(exists){
                if (!filtroRepositorio)
                    repositorios.push({
                        codigo: acervo.cod_tipo_area_conhec,
                        descricao: acervo.desc_tipo_area_conhec,
                        total: 1,
                        totalSemFiltro: 1
                    });
                else{
                    filtroRepositorio.total++;
                    filtroRepositorio.totalSemFiltro++;
                }
            }else{
                if(filtroRepositorio){
                    filtroRepositorio.totalSemFiltro++;
                    return;
                }

                repositorios.push({
                    codigo: acervo.cod_tipo_area_conhec,
                    descricao: acervo.desc_tipo_area_conhec,
                    total: 0,
                    totalSemFiltro: 1
                });
            }

        }
    }

    static _montarFiltroClassificacaoArquivo(classificacoes, acervo, exists) {
        let possuiClassificacao = acervo.cod_area_conhecimento && acervo.rota;

        if (possuiClassificacao) {
            let filtroClassificacao = classificacoes.find(
                c =>
                    c.codigo ===
                    `${acervo.cod_area_conhecimento};${acervo.rota}`,
            );

            if(exists){
                if (!filtroClassificacao)
                    classificacoes.push({
                        codigo: `${acervo.cod_area_conhecimento};${acervo.rota}`,
                        descricao: `${acervo.cod_area_conhecimento} - ${acervo.rota}`,
                        total: 1,
                        totalSemFiltro: 1
                    });
                else{
                    filtroClassificacao.total++;
                    filtroClassificacao.totalSemFiltro++;
                }
            }else{
                if(filtroClassificacao) return;
                classificacoes.push({
                    codigo: `${acervo.cod_area_conhecimento};${acervo.rota}`,
                    descricao: `${acervo.cod_area_conhecimento} - ${acervo.rota}`,
                    total: 0,
                    totalSemFiltro: 1
                });
            }
        }
    }


    static _montarFiltroInstituicaoOAI(instituicoes, acervo) {
        let instituicao = instituicoes.find(
            f => f.codigo === acervo.instituicao.cod_empresa,
        );
        if (!instituicao)
            instituicoes.push({
                codigo: acervo.instituicao.cod_empresa,
                descricao: acervo.instituicao.desc_empresa,
                total: 1,
            });
        else instituicao.total++;
    }

    static _montarFiltroTipoObraOAI(tiposObra, acervo) {
        if (!acervo.tipo_obra) return;
        let tObra = tiposObra.find(f => f.codigo === acervo.tipo_obra);
        if (!tObra)
            tiposObra.push({
                codigo: acervo.tipo_obra,
                descricao: acervo.tipo_obra,
                total: 1,
            });
        else tObra.total++;
    }




    static compareArrays(arr1, arr2) {
        return Array.isArray(arr1) && Array.isArray(arr2) && arr1.length === arr2.length && arr1.every((val, index) => val === arr2[index]);
    }


    /**
     * SOMENTE ACERVOS QUE POSSUEM OS MESMOS FILTROS SELECIONADOS (AND)
     * @param filtrosSelecionados
     * @param acervos
     * @returns {*}
     */
    static filtrarPesquisaGeralANDCondition(filtrosSelecionados, consulta){
        const acervos = JSON.parse(JSON.stringify([...consulta]));

        /**
         * Step 1
         * Filtra somente acervos contidos nos filtros selecionados
         */
        const acervosContidosNosFiltrosSelecionados = acervos.filter( (acervo) => {
            const filtrados =  filtrosSelecionados.filter( (fs) => {
                const { tipo, codigo, descricao} = fs;
                let isTrue = false;

                switch (tipo) {
                    case 'B':
                    case 'C':
                    case 'P':
                        isTrue =   acervo.dados_adicionais[tipo].some(dado => dado.codigo === codigo);
                        break;
                    case 'T':
                        isTrue =  acervo.cod_tipo_obra === codigo;
                        break;
                    case 'Y':
                        isTrue =   acervo.ano_publicacao === codigo;
                        break;
                    case 'I':
                        isTrue =   acervo.idioma === codigo;
                        break;
                    case 'X':
                        if (codigo === 'O')
                            isTrue =  acervo.acesso_remoto === 'cr';
                        else if (codigo === 'C')
                            isTrue =  acervo.link_capa != null && acervo.link_capa.trim() !== '';
                        else
                            isTrue =  acervo.possui_acessibilidade === 'S';
                        break;
                    case 'A':
                    case 'E':
                    case 'S':
                        isTrue  = acervo.dados_adicionais[tipo].some(dado => dado.codigo === codigo && dado.descricao === descricao);
                        break;
                    case 'NA_A':
                        isTrue = true
                        break;
                    case 'R':
                        isTrue =  acervo.cod_tipo_area_conhec && acervo.cod_tipo_area_conhec === codigo;
                        break;
                    case 'K':
                        isTrue =  acervo.cod_area_conhecimento && acervo.rota && `${acervo.cod_area_conhecimento};${acervo.rota}` === codigo;
                        break;
                    default:
                        console.error("i dont have type to filter!")
                        return false;
                }
                if(isTrue){
                    /**
                     * Atribui tipo para a verificação
                     */
                    if(acervo?.tipo_contido){
                        if(!acervo?.tipo_contido.includes(tipo)){
                            acervo.tipo_contido.push(tipo)
                        }
                    }else{
                        acervo.tipo_contido = [tipo];
                    }
                }

                return isTrue;
            })
            return filtrados.length > 0;
        })

        /**
         * Step 2
         * @type {any[]}
         */
        const tiposFiltrados = [...new Set(filtrosSelecionados.map(fs => fs.tipo))].sort();
        const filtrados = acervosContidosNosFiltrosSelecionados.filter((a) => {
            const tiposNoAcervo =  a.tipo_contido.sort();
            return FiltrosHelper.compareArrays(tiposFiltrados, tiposNoAcervo);
        })

        return filtrados.map(r => r.cod_acervo)
    }


    static _filtrarPesquisaGeral(filtrosSelecionados, consulta){
        const acervos = [...consulta]

        const filtrados = acervos.filter((acervo) => {
            return filtrosSelecionados.some((fs) => {
                const { tipo, codigo } = fs
                switch (tipo) {
                    case 'B':
                    case 'C':
                    case 'P':
                        return  acervo.dados_adicionais[tipo].some(dado => dado.codigo === codigo);
                    case 'T':
                        return  acervo.cod_tipo_obra === codigo;
                    case 'Y':
                        return  acervo.ano_publicacao === codigo;
                    case 'I':
                        return  acervo.idioma === codigo;
                    case 'X':
                        if (codigo === 'O')
                            return acervo.acesso_remoto === 'cr';
                        else if (codigo === 'C')
                            return acervo.link_capa != null && acervo.link_capa.trim() !== '';
                        else
                            return acervo.possui_acessibilidade === 'S';
                    case 'A':
                    case 'E':
                    case 'S':
                        return acervo.dados_adicionais[tipo].some(dado => dado.codigo === fs.codigo && dado.descricao === fs.descricao);
                    case 'R':
                        return acervo.cod_tipo_area_conhec && acervo.cod_tipo_area_conhec === codigo;
                    case 'K':
                        return acervo.cod_area_conhecimento && acervo.rota && `${acervo.cod_area_conhecimento};${acervo.rota}` === codigo;
                    default:
                        return false;
                }
            })
        })

        return filtrados.map(f => f.cod_acervo);
    }

    static _filtrarNovasAquisicoes(filtrosSelecionados, consulta){
        const acervos = [...consulta]

        const filtrados = acervos.filter((acervo) => {
            return filtrosSelecionados.some((fs) => {
                const { tipo, codigo, campus, descricao } = fs;
        
                switch (tipo) {
                    case 'NA_C':
                        return acervo.cursos_disc.some(cd => cd.codigo === codigo && cd.campus === campus);
                    case 'NA_A':
                        return acervo.areas_conhecimento.some(ac => ac.descricao === descricao && ac.codigo === codigo);
                    default:
                        return false;
                }
            });
        });
        
        return filtrados;
    }

    static _filtrarOAIGeral(filtrosSelecionados, consulta) {
        const acervos = [...consulta]

        const filtrados = acervos.filter((acervo) => {
            return filtrosSelecionados.some((fs) => {
                const { tipo, codigo } = fs

                switch (tipo) {
                    case 'T':
                        return acervo.tipo_obra === codigo;
                    case 'Y':
                        return acervo.ano_publicacao === codigo;
                    case 'I':
                        return acervo.idioma === codigo;
                    case 'Z':
                        return acervo.instituicao.cod_empresa === codigo;
                    default:
                        return false;
                }
            })
        })

        return filtrados.map(f => f.cod_acervo);
    }
}
